// container
$product-view__max-width\@large                             : 1120px !default;
$product-view__padding                                      : $spacer--medium 0 0 0 !default;
$product-view__padding\@medium                              : $spacer--extra-large 0 0 0 !default;
$product-view__padding\@xl                                  : $spacer--extra-large !default;

// wrapper / flex & grid
$product-view__wrapper-grid-template-columns\@medium        : 50% 50% !default;
$product-view__wrapper-grid-template-rows\@medium           : auto auto auto !default;
$product-view__wrapper-margin                               : 0 auto !default;
$product-view__wrapper-max-width                            : 1120px !default;
$product-view__column-gap\@medium                           : $spacer--medium !default;
$product-view__column-gap\@large                            : $spacer--extra-large !default;
$product-view__column-gap\@xl                               : #{$spacer--extra-large * 3} !default;
$product-view__gallery-order                                : 1 !default;
$product-view__gallery-grid-column-start\@medium            : 1 !default;
$product-view__gallery-grid-row-start\@medium               : 1 !default;
$product-view__gallery-grid-row-end\@medium                 : 3 !default;
$product-view__desc-order                                   : 2 !default;
$product-view__details-order                                : 3 !default;
$product-view__details-desc-grid-column-start\@medium       : 2 !default;
$product-view__details-grid-row-start\@medium               : 2 !default;
$product-view__details-desc-width\@medium                   : calc(100% - #{$product-view__column-gap\@medium}) !default;
$product-view__details-desc-width\@large                    : calc(100% - #{$product-view__column-gap\@large}) !default;
$product-view__details-desc-width\@xl                       : calc(100% - #{$product-view__column-gap\@xl}) !default;

// gallery
$product-view__gallery-margin                               : 0 0 $spacer--medium !default;

//badges
$product-view__badges-z-index                               : $z-index-low !default;
$product-view__badges-top                                   : $spacer--medium !default;
$product-view__badges-top\@medium                           : $spacer--semi-medium !default;
$product-view__badges-top\@large                            : $spacer--semi-medium !default;
$product-view__badges-left                                  : 0 !default;
$product-view__badges-left\@medium                          : 0 !default;
$product-view__badges-left\@large                           : 82px !default;
$product-view__badges-left\@xl                              : 114px !default;

// title
$product-view__title-margin                                 : 0 0 $spacer--semi-medium !default;
$product-view__title-margin\@medium                         : 0 !default;
$product-view__title-text-transform                         : initial !default;
$product-view__title-font-size                              : $font-size-large !default;
$product-view__title-font-size\@medium                      : $font-size-extra-large !default;
$product-view__title-font-size\@large                       : $font-size-super-extra-large !default;

// sku
$product-view__sku-display                                  : none !default;
$product-view__sku-color                                    : $gray-dark !default;
$product-view__sku-font-weight                              : $font-weight-normal !default;

// stock
$product-view__stock-min-height                             : 22px !default;
$product-view__stock-padding                                : 0 !default;
$product-view__stock-font-weight                            : $font-weight-bold !default;
$product-view__stock-text-transform                         : uppercase !default;
$product-view__stock-white-space                            : nowrap !default;
$product-view__stock-font-size                              : $font-size-small !default;
$product-view__stock-font-size\@medium                      : $font-size-base !default;
$product-view__stock-color                                  : inherit !default;
$product-view__stock-min-width                              : 60px !default;
$product-view__stock-color-hover                            : $color-primary !default;
$product-view__stock-text-decoration                        : none !default;
$product-view__stock-text-decoration-hover                  : underline !default;

// rating
$product-view__rating-padding                               : 0 0 $spacer !default;
$product-view__rating-border                                : $border-base !default;
$product-view__rating-border-width                          : 0 0 $border-width-base !default;

// price
$product-view__price-display                                : none !default;
$product-view__price-display\@display-mq                    : block !default;
$product-view__price-display-mq                             : $screen-m !default;
$product-view__price-padding                                : $spacer--medium 0 !default;

$product-view__price-display--between                       : flex !default;
$product-view__price-flex-direction--between                : column !default;
$product-view__price-line-height--between                   : 1.2 !default;
$product-view__price-text-display--between                  : inline-flex !default;
$product-view__price-text-min-width--between                : 50px !default;

// short description
$product-view__short-description-heading-font-family        : $font-family-base !default;
$product-view__short-description-heading-font-size          : $font-size-base !default;
$product-view__short-description-heading-font-weight        : $font-weight-bold !default;
$product-view__short-description-heading-color              : $color-secondary !default;
$product-view__short-description-heading-text-transform     : none !default;

// options
$product-view__select-option-height                         : 56px !default;
$product-view__options-margin                               : 0 0 $spacer--semi-medium !default;

// buttons
$product-view__buttons-mq-display                           : $screen-m !default;

// button
$product-view__button-width                                 : 48% !default;
$product-view__button-width\@medium                         : auto !default;
$product-view__button-margin\@medium                        : 0 0 0 $spacer !default;
$product-view__button-min-size                              : 56px !default;
$product-view__button-bg                                    : $gray-lighter !default;

$product-view__button-wishlist-filled-bg-color-hover        : $color-primary !default;
$product-view__button-wishlist-filled-bg-image-after-hover  : linear-gradient(to right, $white 50%, transparent 50%) !default;
$product-view__button-wishlist-width                        : 100% !default;
$product-view__button-wishlist-width\@medium                : auto !default;
$product-view__button-wishlist-margin\@medium               : 0 0 0 $spacer !default;
$product-view__button-wishlist-min-width                    : 56px !default;
$product-view__button-wishlist-min-height                   : 56px !default;
$product-view__button-wishlist-background-color             : $gray-lighter !default;

$product-view__dropdown-wishlist-width                      : 100% !default;
$product-view__dropdown-wishlist-margin                     : $spacer 0 0 !default;
$product-view__dropdown-wishlist-margin\@medium             : $spacer--medium 0 0 !default;

$product-view__dropdown-wishlist-button-width               : 100% !default;
$product-view__dropdown-wishlist-button-after-left          : calc(50% - 63px) !default;
$product-view__dropdown-wishlist-content-min-width\@medium  : 360px !default;
$product-view__dropdown-wishlist-content-min-width\@large   : 200% !default;

$product-view__requistion-dropdown-order                    : 5 !default;
$product-view__requistion-dropdown-width\@medium            : 55% !default;
$product-view__requistion-dropdown-width\@large             : 50% !default;
$product-view__requistion-dropdown-content-right            : 0 !default;

$product-view__multiwishlist-dropdown-order                 : 4 !default;
$product-view__multiwishlist-dropdown-width\@medium         : 44% !default;
$product-view__multiwishlist-dropdown-width\@large          : 50% !default;
$product-view__multiwishlist-dropdown-item-padding\@large   : 0 $spacer 0 0 !default;

$product-view__update-wishlist-width                        : 100% !default;
$product-view__update-wishlist-height                       : 56px !default;
$product-view__update-wishlist-margin                       : $spacer 0 0 !default;
$product-view__update-wishlist-margin\@medium               : $spacer--medium 0 0 !default;
$product-view__update-wishlist-order                        : 2 !default;

$product-view__extra-actions-order                          : 3 !default;
$product-view__extra-actions-width                          : 100% !default;
$product-view__extra-actions-width\@medium                  : auto !default;

// add to cart button
$product-view__add-to-cart                                  : none !default;
$product-view__order                                        : 1 !default;
$product-view__flex-grow                                    : 1 !default;
$product-view__add-to-cart-height                           : 56px !default;
$product-view__add-to-cart-padding                          : 0 $spacer--medium !default;
$product-view__add-to-cart\@buttons-mq-display              : inline-flex !default;

// update requisition list button
$product-view__update-requisition-list-height               : 56px !default;
$product-view__update-requisition-list-margin               : 0 0 $spacer--medium !default;
$product-view__update-requisition-list-width                : 100% !default;
$product-view__update-requisition-list-button-width         : 100% !default;

// qty
$product-view__qty-margin                                   : 0 0 $spacer--large !default;

// stock status
$product-view__stock-status-margin                          : $spacer--medium 0 0 0 !default;
$product-view__stock-status-margin\@medium                  : 0 !default;

// brief
$product-view__brief-margin                                 : $spacer--large 0 !default;
$product-view__brief-margin\@medium                         : $spacer--medium 0 $spacer--semi-medium !default;
$product-view__brief-title-text-transform                   : capitalize !default;
$product-view__brief-title-font-weight                      : $font-weight-bold !default;
$product-view__brief-title-font-size                        : $font-size-base !default;
$product-view__brief-subtitle-font-size                     : $font-size-small !default;
$product-view__brief-item-margin                            : 0 0 $spacer !default;

// form
$product-view__form-padding                                 : $spacer--medium 0 !default;
$product-view__form-border                                  : $border-base !default;
$product-view__form-border-width                            : 0 0 $border-width-base !default;

// summary
$product-view__summary-padding                              : $spacer--medium 0 0 !default;
$product-view__summary-margin                               : 0 0 $spacer--large !default;
$product-view__summary-margin\@medium                       : 0 !default;
$product-view__summary-width                                : 100% !default;
$product-view__summary-title-font-family                    : $font-family-base !default;
$product-view__summary-title-font-size                      : $font-size-medium !default;
$product-view__summary-title-font-weight                    : $font-weight-bold !default;
$product-view__summary-title-text-transform                 : uppercase !default;
$product-view__summary-title-margin                         : 0 0 $spacer--medium !default;
$product-view__summary-list-font-size                       : $font-size-medium !default;
$product-view__summary-list-item-title-font-weight          : $font-weight-medium !default;
$product-view__summary-list-item-title-text-transform       : uppercase !default;
$product-view__summary-list-item-value-color                : $gray-dark !default;
$product-view__summary-price-margin                         : 0 0 $spacer--medium !default;
$product-view__summary-price-font-size                      : $font-size-extra-large !default;
$product-view__summary-price-font-weight                    : $font-weight-bold !default;
$product-view__summary-unit-padding                         : 0 0 0 $spacer--extra-small !default;
$product-view__summary-unit-font-size                       : $font-size-extra-large !default;

// custamization
$product-view__customization-border                         : $border-base !default;
$product-view__customization-border-width                   : 0 0 1px !default;
$product-view__customization-padding                        : 0 0 $spacer--medium !default;
$product-view__customization-margin                         : 0 0 $spacer !default;

// tabs
$product-view__tabs-margin                                  : 0 auto !default;
$product-view__tabs-margin\@medium                          : 64px auto 0 !default;
$product-view__tabs-padding\@medium                         : 0 $spacer--semi-medium !default;
$product-view__tabs-padding\@large                          : 0 !default;
$product-view__tabs-title-height                            : 56px !default;
$product-view__tabs-title-padding                           : 0 0 0 $spacer !default;
$product-view__tabs-title-padding\@medium                   : 0 0 0 $spacer--semi-medium !default;
$product-view__tabs-title-padding\@large                    : 0 !default;
$product-view__tabs-title-text-align                        : left !default;
$product-view__tabs-title-text-align\@large                 : center !default;

$product-view__tabs-sticky-bg                               : $white !default;
$product-view__tabs-sticky-border                           : $border-base !default;
$product-view__tabs-sticky-border-width                     : 0 0 $border-width-base 0 !default;
$product-view__tabs-sticky-shadow                           : $shadow !default;
$product-view__tabs-sticky-show-mq                          : $screen-m !default;
$product-view__tabs-sticky-max-width                        : $product-view__max-width\@large !default;
$product-view__tabs-sticky-margin                           : 0 auto !default;
$product-view__tabs-sticky-z-index                          : $z-index-high !default;
$product-view__tabs-sticky-label-height                     : 48px !default;
$product-view__tabs-sticky-label-border-width               : 0 !default;
$product-view__tabs-sticky-label-decoration                 : none !default;

// product slider
$product-view__slider-margin                                : $spacer--extra-large 0 !default;

// grouped table
$product-view__grouped-table-margin                         : 0 0 $spacer--medium !default;
$product-view__grouped-table-border-width                   : 0 !default;
$product-view__grouped-cell-border-right                    : 0 !default;
$product-view__grouped-th-padding                           : $spacer 0 !default;
$product-view__grouped-th-font-size                         : $font-size-base !default;
$product-view__grouped-th-font-weight                       : $font-weight-base !default;
$product-view__grouped-th-bg-color                          : $white !default;
$product-view__grouped-th-align                             : right !default;
$product-view__grouped-th-align\@medium                     : left !default;
$product-view__grouped-td-padding                           : $spacer--medium 0 !default;
$product-view__grouped-td-vertical-align                    : top !default;
$product-view__grouped-td-qty-padding                       : 0 0 0 $spacer--medium !default;
$product-view__grouped-td-name-font-weight                  : $font-weight-medium !default;
$product-view__grouped-td-name-margin\@medium               : 0 0 $spacer 0 !default;
$product-view__grouped-qty-field-max-width                  : 112px !default;
$product-view__grouped-qty-field-border-color               : $gray !default;
$product-view__grouped-qty-field-align                      : center !default;

// sticky EE version
$product-view__add-to-cart-ee-wrapper-padding               : $spacer--small $spacer $spacer !default;
$product-view__add-to-cart-ee-wrapper-padding\@medium       : $spacer--semi-medium $spacer !default;
$product-view__add-to-cart-ee-wrapper-padding\@large        : $spacer--semi-medium $spacer--semi-large !default;

$product-view__add-to-cart-ee-action-bottom                 : $spacer !default;
$product-view__add-to-cart-ee-action-right                  : $spacer !default;

$product-view__add-to-cart-ee-price-box-padding             : $spacer--small 0 0 !default;
$product-view__add-to-cart-ee-price-box-padding\@large      : $spacer--extra-small 0 0 !default;

// recaptcha
$product-view__grecaptcha-badge-bottom                      : 112px !default;
$product-view__grecaptcha-badge-bottom\@medium              : 0 !default;
