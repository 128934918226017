$logo__max-width           : 100%;
$logo__max-width\@medium   : 320px;
$logo__max-width\@large    : 260px;
$logo__image-height        : 32px;
$logo__image-height\@medium: 49px;

// new variables
$logo__image-height\@large : 58px;
$logo__image-height\@xl    : 58px;

