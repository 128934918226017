.newsletter {
    padding: $newsletter__padding;

  @include mq($max-screen: $screen-l - 1){
    flex-wrap: wrap;
    padding: $newsletter__padding\tablet;
  }

    &__form-container{
        display: flex;
        justify-content: flex-end;

        flex-wrap: wrap;
        flex-basis: 50%;

      @include mq($screen-l){
        padding: $newsletter__form-container-padding;
      }

      @include mq($max-screen: $screen-l - 1){
        padding-right: 15px;
        padding-left: 15px;
      }

      @include mq($max-screen: $screen-m - 1){
        padding-right: 0;
        padding-left: 0;
      }
        .checkbox__label{
          @include mq($max-screen: $screen-l - 1){
            font-size: $newsletter__checkbox-label-font-size\tablet;

            div[data-appearance*="contained"] > div{
              margin: 0 !important;
            }

          }

            &:after{
                background-color: white;
            }
        }
    }

    &__heading {
        margin: 0;
        @include mq($screen-m) {
        }
    }

    &__form-content{
        width: $newsletter__form-content-width;
        max-width: $newsletter__form-content-max-width;
        @include mq($max-screen: $screen-m - 1){
          max-width: $newsletter__form-content-max-width\mobile;
        }
    }

    &__title {
        font-family: $newsletter__title-font-family;
        font-size: $newsletter__title-font-size;
        font-weight: $newsletter__title-font-weight;
        color: $newsletter__title-color;
        line-height: $newsletter__title-line-height;
        margin: $newsletter__title-margin;

      @include mq($max-screen: $screen-l - 1){
        font-size: $newsletter__title-font-size\tablet;
        line-height: $newsletter__title-line-height\tablet;
      }
    }

    &__subtitle {

        font-family: $newsletter__subtitle-font-family;
        font-size: $newsletter__subtitle-font-size;
        font-weight: $newsletter__subtitle-font-weight;
        color: $newsletter__subtitle-color;
        line-height: $newsletter__subtitle-line-height;
        margin: $newsletter__subtitle-margin;

      @include mq($max-screen: $screen-l - 1){
        font-size: $newsletter__subtitle-font-size\tablet;
        line-height: $newsletter__subtitle-line-height\tablet;
        margin: $newsletter__subtitle-margin\tablet;
      }

    }

    &__controls {
        justify-content: space-between;

      @include mq($max-screen: $screen-l - 1){
        margin-top: 0;
      }


        @include mq($screen-xl) {
            max-width: none;
        }
    }

    &__form {
        .button{
            width: $newsletter-button-width;
          height: 50px;

          @include mq($max-screen: $screen-l - 1){
            width: $newsletter-button-width\tablet;
            font-size: $newsletter-button-font-size\tablet;
            height: 40px;
          }
        }
    }

    &__input{
        width: $newsletter__input-width;
        flex-grow: inherit;

      @include mq($max-screen: $screen-l - 1){
        width: $newsletter__input-width\tablet;

        input{
          padding-left: 10px;
          &::-webkit-input-placeholder {
            font-size: 14px;
          }
          &:-ms-input-placeholder {
            font-size: 14px;
          }
          &::placeholder {
            font-size: 14px;
          }
        }
      }
    }

    &__field {

    }

    &__agreements{
        margin-left: 0;
        max-width: inherit;
    }

    &__checkbox {
        .checkbox__label,
        a {
          color: $newsletter__checkbox-label-link-color;
          font-family: $newsletter__checkbox-label-font-family;
        }
    }

    &__catalog{
        display: flex;
        align-items: center;
        flex-basis: 50%;
      > div{
        width: 100%;
      }

      @include mq($max-screen: $screen-l - 1){
        padding-right: 15px;
      }

      @include mq($max-screen: $screen-m - 1){
        padding: 10px 0 0;
      }

        &-container{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            width: $newsletter__catalog-content-width;
            height: $newsletter__catalog-content-min-height;
            background-image: $newsletter__catalog-content-background-image;
            background-size: $newsletter__catalog-content-background-size;
            background-repeat: $newsletter__catalog-content-background-repeat;
            text-align: $newsletter__catalog-content-text-align;
            padding: $newsletter__catalog-content-padding;

          @include mq($max-screen: $screen-l - 1){
           height: $newsletter__catalog-content-min-height\tablet;
          }

        }

        &-title{
            width: $newsletter__catalog-title-width;
            font-family: $newsletter__catalog-title-font-family;
            font-size: $newsletter__catalog-title-font-size;
            font-weight: $newsletter__catalog-title-weight;
            color: $newsletter__catalog-title-color;
            line-height: $newsletter__catalog-title-line-height;
            margin: $newsletter__catalog-title-margin;

          @include mq($max-screen: $screen-l - 1){
            font-size: $newsletter__catalog-title-font-size\tablet;
            line-height: $newsletter__catalog-title-line-height\tablet;
          }

        }

        &-links{
          display: flex;
          justify-content: center;
            align-items: center;
        }

        &-link{
          display: flex;
          align-items: center;
          font-family: $newsletter__catalog-link-font-family;
          font-size: $newsletter__catalog-link-font-size;
          font-weight: $newsletter__catalog-link-font-weight;
          color: $newsletter__catalog-link-color;
          line-height: $newsletter__catalog-link-line-height;
          text-transform: $newsletter__catalog-link-text-transform;


          &:hover{
            color: $newsletter__catalog-link-color-hover;
          }

          &:nth-child(2){
            &:before{
              content: "";
              display: block;
              vertical-align: middle;
              width: $newsletter__catalog-link-before-width;
              height: $newsletter__catalog-link-before-height;
              background-image: $newsletter__catalog-link-before-background-image;
              background-size: $newsletter__catalog-link-before-background-size;
              background-repeat: $newsletter__catalog-link-before-background-repeat;
              margin: $newsletter__catalog-link-before-margin;
            }
          }

          @include mq($max-screen: $screen-l - 1){
            font-size: $newsletter__catalog-link-font-size\tablet;
            line-height: $newsletter__catalog-link-line-height\tablet;

            &:nth-child(2){
              &:before{
                margin: $newsletter__catalog-link-before-margin\tablet;
              }
            }
          }
        }

    }
}
