@import "signup-form-variables";

.homepage-signup-form {
  margin: $homepage-signup-form-margin;
  padding: $homepage-signup-form-padding;
  position: relative;

  @include mq($max-screen: $screen-l - 1){
    margin: $homepage-signup-form-margin\tablet;
    padding: $homepage-signup-form-padding\tablet;
  }

  @include mq($max-screen: $screen-m - 1){
    margin: $homepage-signup-form-margin\mobile;
  }


  &:before,
  &:after{
    content: "";
    display: block;
    position: absolute;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    background: #D8D8D8;
  }
  &:before{
    top: 0;
  }
  &:after{
    bottom: 0;
  }

  &-left{
    align-self: center !important;
    justify-content: flex-end !important;
    @include mq($max-screen: $screen-m - 1){
      padding: 0 !important;
    }
  }

  &-left-content{
    max-width: $homepage-signup-form-left-content-max-width;
    text-align: $homepage-signup-form-left-content-text-align;
    margin: $homepage-signup-form-left-content-margin;

    @include mq($max-screen: $screen-l - 1){
      max-width: $homepage-signup-form-left-content-max-width\tablet;
      margin: $homepage-signup-form-left-content-margin\tablet;
    }

  }

  &-title{
    font-family: $homepage-signup-form-title-font-family;
    font-size: $homepage-signup-form-title-font-size;
    color:$homepage-signup-form-title-font-color;
    font-weight: $homepage-signup-form-title-font-weight;
    line-height: $homepage-signup-form-title-line-height;

    @include mq($max-screen: $screen-l - 1){
      font-size: $homepage-signup-form-title-font-size\tablet;
      line-height: $homepage-signup-form-title-line-height\tablet;
    }


    &:after{
      content: "";
      display: block;
      width: $homepage-signup-form-title-after-width;
      height: $homepage-signup-form-title-after-height;
      margin: $homepage-signup-form-title-after-margin;
      background-image: $homepage-signup-form-title-after-background-image;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  &-paragraph{
    font-family: $homepage-signup-form-paragraph-font-family;
    font-size: $homepage-signup-form-paragraph-font-size;
    color: $homepage-signup-form-paragraph-font-color;
    font-weight: $homepage-signup-form-paragraph-font-weight;
    line-height: $homepage-signup-form-paragraph-line-height;
    margin: $homepage-signup-form-paragraph-margin;

    @include mq($max-screen: $screen-l - 1){
      font-size: $homepage-signup-form-paragraph-font-size\tablet;
      line-height: $homepage-signup-form-paragraph-line-height\tablet;
    }
  }

  .input{
    &__label{
      display: none;
    }
  }

  &-right{
    align-self: center !important;
    @include mq($max-screen: $screen-m - 1){
      padding: 20px 0 0 !important;
    }
  }

  .form-create-account{
    max-width: 465px;
    @include mq($max-screen: $screen-l - 1){
      max-width:inherit;
      .button{
        width: 100%;
        font-size: $homepage-signup-form-button-font-size\tablet;
      }
    }

    .fieldset-wrapper{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .input{
        &-first-name,
        &-last-name{
          width: calc(50% - 8px);
        }
        &-email{
          width: 100%;
        }
      }
    }
  }
}