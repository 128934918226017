@import "mackenzie-slider-variables";

.slider {
    &__slides {
        .slick-track {
            // left: -1px; // Safari fix to remove part of previous slide being visible
        }
    }

    &__navigation {
        .slider__next {
            //  right: 1px; // Safari fix to remove part of previous slide being visible
        }
    }

    &__dots{
        display: flex;
        justify-content: center;
        position: relative;
        padding: 15px;




        .slick-active{
            &:before{
                display: none !important;
            }
            button{
                background: $slider__dots-button-background-active;
                border-color: $slider__dots-button-border-color-active;
            }
        }
        button{
            border: $slider__dots-button-border;
            @include mq($max-screen: $screen-l - 1){
                width: $slider__dots-button-width\tablet;
                height: $slider__dots-button-height\tablet;
                margin: $slider__dots-button-margin\tablet;
            }
        }

        li:last-child{
            button{
                margin-right: 0;
            }
        }
    }
}
.section-home-slider{
    max-width: $max-content-width;
    margin: 0 auto !important;
    .slider{
        &__dots{
            display: block !important;
            position: absolute;
            padding: $slider__dots-padding;
            bottom: $slider__dots-bottom-position;
            width: $slider__dots-width;
            height: $slider__dots-height;
            background-image: $slider__dots-background-image;
            background-repeat: no-repeat;
            background-size: cover;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
