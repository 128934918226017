@import "header-top-message-variables";

.header-top-message{
  max-width: calc(100% - 557px);
  overflow-x: auto;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @include mq($max-screen: $screen-l - 1) {
    display: none;
  }
  div[data-content-type="row"]{
    > div{
      padding: 0 !important;
      margin: 0 !important;
    }
  }
  p{
    font-family: $header-top-message__font-family;
    color: $header-top-message__color;
    font-size: $header-top-message__font-size;
    margin-bottom: 0;
    white-space: nowrap;
  }

  .ps__rail-x,
  .ps__thumb-x,
  .ps__rail-x:hover > .ps__thumb-x,
  .ps__rail-x:focus > .ps__thumb-x,
  .ps__rail-x.ps--clicking .ps__thumb-x{
    height: 3px;
  }
}