// Colors
$blue-light                 : #f2f7f9;
$blue-dark                  : #1b4866;

// Semantic color scheme
$color-accent-primary       : #476D8D;
$color-accent-primary-dark  : $blue-dark;
$color-accent-secondary     : #64A8AB;

// Typography
$font-family-sans-serif     : 'Avenir', sans-serif;
$font-family-serif          : 'Adobe Garamond Pro', serif;

$font-family-base           : $font-family-sans-serif;
$font-family-secondary      : $font-family-serif;
$font-size-base             : 14px;

//color2
$button-blue: #476D8D;
$button-green: #64A8AB;
