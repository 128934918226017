@import "our-customers-variables";

.our-customers{
  &-come-first{
    padding: $our-customers-come-first-padding;
    margin: $our-customers-come-first-margin;
    position: relative;

    @include mq($max-screen: $screen-l - 1){
      padding: $our-customers-come-first-padding\tabelt;
      margin: $our-customers-come-first-margin\tabelt;
    }

    @include mq($max-screen: $screen-m - 1){
      .title-swirl{
        max-width: calc(100% - 50px);
      }
    }


    &:before{
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      background-size: cover;
      background-position: center center;
    }

    &.lazyloaded{
      &:before{
        background-image: $our-customers-come-background-image;
      }
    }

  }

  &-list{
    list-style: none;
    padding: 0;
    position: static;
    margin: $our-customers-list-margin;

    .slick-arrow {
      z-index: 10;
    }

    /*.slick-track {
      max-width: 100%;
    }
    .slick-slide {
      min-width: 100%;
    }*/

    @include mq($max-screen: $screen-l - 1){
      margin: $our-customers-list-margin\tablet;
    }

  }

  &-paragragh{
    font-family: $our-customer-paragragh-font-family;
    font-size: $our-customer-paragragh-font-size;
    color: $our-customer-paragragh-font-color;
    font-weight: $our-customer-paragragh-font-weight;
    line-height: $our-customer-paragragh-line-height;
    text-align: $our-customer-title-text-align;
    max-width: $our-customer-title-max-width;
    margin: $our-customer-title-margin;

    @include mq($max-screen: $screen-l - 1){
      font-size: $our-customer-paragragh-font-size\tablet;
      line-height: $our-customer-paragragh-line-height\tablet;
      max-width: $our-customer-title-max-width\tablet;
      font-weight: $our-customer-paragragh-font-weight\tablet;
    }

    @include mq($max-screen: $screen-m - 1){
      max-width: $our-customer-title-max-width\mobile;
    }


    strong{
      display: block;
      margin-top: 21px;
      @include mq($max-screen: $screen-l - 1){
        margin-top: 10px;
      }
    }
  }
}