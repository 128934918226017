.add-to-cart {
    .button--add-to {
        .button__text {
            display: block;
        }

        .button__icon {
            display: none;
        }
    }
}
