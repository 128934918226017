@import "scottish-smoked-salmon-variables";


.scottish-smoked-salmon{
  box-shadow: $scottish-smoked-salmon-box-shadow;
  padding: $scottish-smoked-salmon-padding;
  margin: $scottish-smoked-salmon-margin;

  @include mq($max-screen: $screen-l - 1){
    padding: $scottish-smoked-salmon-padding\tablet;
    margin: $scottish-smoked-salmon-margin\tablet;
    .pagebuilder-column-group{
      flex-wrap: wrap;
    }
    .pagebuilder-column-line {
      flex-wrap: wrap;
    }
  }


  &-content{
    max-width: $scottish-smoked-salmon-content-max-width;
    margin: $scottish-smoked-salmon-content-margin;

    @include mq($max-screen: $screen-l - 1){
      max-width: $scottish-smoked-salmon-content-max-width\tablet;
    }

  }

  &-left,
  &-right{
    flex-direction: inherit !important;
    align-self: inherit !important;
    align-items: center;
    padding: 0 !important;
    text-align: center;
    @include mq($max-screen: $screen-l - 1){
      width: 100% !important;
    }
  }

  &-left{
    border: $scottish-smoked-salmon-content-left-border;
    min-height: $scottish-smoked-salmon-content-left-min-height;
    padding: $scottish-smoked-salmon-content-left-padding;
    justify-content: center !important;
    position: relative;

    &:before{
      content: "";
      display: $scottish-smoked-salmon-content-left-before-display;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 3px);
      height: 100%;
      z-index: -1;
      background: url("../images/home/border-dotted.png") no-repeat;
      background-size: 100% 100%;

    }


    @include mq($screen-l){
      border-right: none !important;
    }

    @include mq($max-screen: $screen-l - 1){
      border-bottom: none !important;
      min-height: $scottish-smoked-salmon-content-left-min-height\tablet;
      padding: $scottish-smoked-salmon-content-left-padding\tablet;

      &:before{
        width: 100%;
        height: calc(100% + 2px);
      }
    }


    @include mq($max-screen: $screen-m - 1){
      &:before{
        background: url("../images/home/border-dotted-tablet.png") no-repeat;
        background-size: 100% 100%;
      }
    }


    .button:hover{
      color: white;
    }

  }

  &-right{
    position: relative;
    overflow: hidden;
    img{
      position: absolute;
      top: 0;
      left: 0;
      height: 100% !important;
      width: auto;

      @include mq($screen-l){
        max-width: inherit !important;
      }

      @include mq($max-screen: $screen-l - 1){
        position: relative;
        height: auto !important;
      }
    }
    .pagebuilder-video-container {
      position: static !important;
    }
  }

  &-small-title{
    font-family: $scottish-smoked-salmon-small-title-font-family;
    font-size: $scottish-smoked-salmon-small-title-font-size;
    color: $scottish-smoked-salmon-small-title-color;
    line-height: $scottish-smoked-salmon-small-title-line-height;
    text-transform: $scottish-smoked-salmon-small-title-text-transform;
    margin: $scottish-smoked-salmon-small-title-margin;
    font-weight: $scottish-smoked-salmon-small-title-font-weight;

    &:after{
      content: "";
      display: block;
      width: 100%;
      max-width: $scottish-smoked-salmon-small-title-after-max-width;
      height: $scottish-smoked-salmon-small-title-after-height;
      border-top: $scottish-smoked-salmon-small-title-after-border-top;
      margin: $scottish-smoked-salmon-small-title-after-margin;
      background: $scottish-smoked-salmon-small-title-after-background;
    }

    @include mq($max-screen: $screen-l - 1){
      font-size: $scottish-smoked-salmon-small-title-font-size\tablet;
      line-height: $scottish-smoked-salmon-small-title-line-height\tablet;

      &:after{
        max-width: $scottish-smoked-salmon-small-title-after-max-width\tablet;
        margin: $scottish-smoked-salmon-small-title-after-margin\tablet;
      }

    }

  }

  &-bigger-title{
    font-family: $scottish-smoked-salmon-bigger-title-font-family;
    font-size: $scottish-smoked-salmon-bigger-title-font-size;
    font-weight: $scottish-smoked-salmon-bigger-title-font-weight;
    color: $scottish-smoked-salmon-bigger-title-color;
    line-height: $scottish-smoked-salmon-bigger-title-line-height;
    margin: $scottish-smoked-salmon-bigger-title-margin;

    @include mq($max-screen: $screen-l - 1){
      font-size: $scottish-smoked-salmon-bigger-title-font-size\tablet;
      line-height: $scottish-smoked-salmon-bigger-title-line-height\tablet;
    }

    span{
      display: block;
      font-family: $scottish-smoked-salmon-bigger-title-span-font-family;
      font-size: $scottish-smoked-salmon-bigger-title-span-font-size;
      line-height: $scottish-smoked-salmon-bigger-title-span-line-height;
      margin: $scottish-smoked-salmon-bigger-title-span-margin;

      @include mq($max-screen: $screen-l - 1){
        font-size: $scottish-smoked-salmon-bigger-title-span-font-size\tablet;
        line-height: $scottish-smoked-salmon-bigger-title-span-line-height\tablet;
      }

    }

    &:after{
      content: "";
      display: block;
      width: $scottish-smoked-salmon-bigger-title-after-width;
      height: $scottish-smoked-salmon-bigger-title-after-height;
      margin: $scottish-smoked-salmon-bigger-title-after-margin;
      background-image: $scottish-smoked-salmon-bigger-title-after-background-image;
      background-size: 100% 100%;
    }
  }

  &-paragraph{
    font-family: $scottish-smoked-salmon-paragragh-font-family;
    font-size: $scottish-smoked-salmon-paragragh-font-size;
    color: $scottish-smoked-salmon-paragragh-font-color;
    font-weight: $scottish-smoked-salmon-paragragh-font-weight;
    line-height: $scottish-smoked-salmon-paragragh-line-height;
    margin: $scottish-smoked-salmon-paragragh-margin;

    @include mq($max-screen: $screen-l - 1){
      font-size: $scottish-smoked-salmon-paragragh-font-size\tablet;
      line-height: $scottish-smoked-salmon-paragragh-line-height\tablet;
    }

  }

  .button{
    max-width: $scottish-smoked-salmon-button-max-width;
    margin: $scottish-smoked-salmon-button-margin;
    @include mq($max-screen: $screen-l - 1){
      font-size: 16px;
    }
  }

  &-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: auto;
      max-width: 45px;
    }
  }

}
