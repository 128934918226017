.cart-discount {
    &__title {
        padding: 8px 0;
        border: $cart-discount__button-border;
        border-width: $cart-discount__button-border-width;
        font-size: 16px;
        color: $cart-discount__button-text-color;
        font-weight: bold;
       // font-family: $font-family-sans-serif;
        @include mq($max-screen: $screen-m - 1){
            color: #000;
        }
    }

    &__label {
        .discount-text {
            svg path {
                fill: $color-accent-primary;
            }
        }
    }

    &__content {
        padding: 0;
        #giftcard-form{
            position: relative;

            .cart-discount__controls {
                margin-bottom: 0;

                .mage-error {
                    position: absolute;
                }
            }

            .action.check {
                border: none;
                background: none;
                cursor: pointer;
                color: $color-accent-primary;
                font-size: 16px;
                font-weight: 500;
                font-family: $font-family-base;
                width: 100%;
            }
        }
    }

    &__controls {
        display: flex;
        align-items: flex-start;
        margin-bottom: $cart-discount__controls-margin-bottom;
        padding: $cart-discount__controls-padding;
        justify-content: space-between;
        position: relative;

        @include mq($screen-m){
            padding-left: 12px;
        }

    }

    @include mq($max-screen: $screen-m){
        .giftcard.cart-discount__content .cart-discount__controls {
            padding-top: 0;
        }
    }

    &__content-input {
        width: 100%;
        margin: $cart-discount__input-margin;
        @include mq($screen-l, $screen-xl ) {
            .input__field{
                padding: 0 16px;
                &::placeholder{
                    font-size: 16px;
                }
            }
        }

        .input__field {
            border-radius: 4px;
            border: 1px solid #121212;
            background: #fff;
            padding: 13px 12px;
            height: auto;
        }
    }

    &__content-button{
        background-color: transparent;
        min-height: 32px;
        max-height: 32px;
        padding: 0 16px;
        font-size: 14px;
        line-height: 14px;
        border-radius: 4px;
        border: 1px solid $color-accent-primary;
        text-transform: capitalize;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-transition: .15s;
        max-width: 64px;
        position: absolute;
        right: 12px;
        bottom: 19px;

        @include mq($max-screen: $screen-m){
            bottom: 32px;
        }

        &.action.check{
            font-size: 14px;
            color: $green;
            background-color: transparent;
            width: 100%;
            min-height: auto;
            height: auto;
            text-transform: lowercase;
            display: block;
            text-align: right;
            position: absolute;
            bottom: 0;

            @include mq($screen-m){
                max-width: calc(100% - 140px);
            }

            @include mq($screen-l,$max-screen: $screen-xl ) {
                max-width: calc(100% - 80px);
            }

            @include mq($screen-m,$max-screen: $screen-l - 1 ) {
                max-width: calc(70% + 6px);
            }

            @include mq($max-screen: $screen-m - 1){
            //    color: #292F38;
                text-align: center;
                padding: 0;
                font-weight: 400;
                font-size: 16px;
            }

        }

    }
}

.checkout-cart-index{
    .cart-discount{
        &__content-button{
            &.action.check{
                @include mq($max-screen: $screen-m - 1){
                    color: #292F38;
                }
            }
        }
    }
    .cart-list-item__attributes {
        color: red;
        font-weight: 400;
    }
}

/**
Hide message link of promo free gift item amasty
 */
.ampromo-items-add {
    display: none !important;
    margin-bottom: 12px;
    padding: 7px;
    border: 1px solid #ededed;
    text-align: center;
}
.checkout-cart-index {
    @include mq($min-screen: $screen-m){
        .ampromo-items-add {
            margin-top: 10px;
            width: 66.66666667%;
            text-align: left;
            padding: 7px 48px;
        }
        .page.messages {
            .message {
                justify-content: flex-start;
            }
        }
        .heading--page {
            position: relative !important;
            margin: 0;
        }
    }
}
