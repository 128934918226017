@import "minicart-custom-variables";

//mini-cart-custom
body.show-minicart {
    overflow: hidden;
}

#modal-minicart {
    @include mq($max-screen: $screen-m - 1) {
        height: 100%;
        max-height: 100%;
        justify-content: flex-start;
    }

    &.modal--secondary {
        .modal__container {
            overflow-y: auto;

            @include mq($max-screen: $screen-m - 1) {
                padding: 0 24px 32px;
                border-radius: 0 0 16px 16px;
                height: auto;

                @keyframes animatetop {
                    from {
                        top: -300px;
                        opacity: 0;
                    }
                    to {
                        top: inherit;
                        opacity: 1;
                    }
                }

                animation: animatetop 0.4s;
                overflow-x: hidden;
            }

            @include mq($screen-m - 1) {
                padding: 0 32px 32px;
                max-width: 640px;
            }

            .modal__heading {
                position: sticky;
                top: 0;
                z-index: 1;
                background: $minicart-background-color;
                border-bottom: 1px solid #E6E6E6;
                padding: 32px;
                margin: 0 -32px;

                .modal__close-button {
                    right: 32px;
                    top: 24px;
                    padding: 0;
                    min-width: unset;
                    min-height: unset;

                    .icon {
                        width: 32px;
                        height: 32px;
                        fill: #A9A9A9;
                    }
                }

                .success-message {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 4px;
                    font-weight: 500;
                    line-height: 100%;
                    color: $success-message-color;

                    @include mq($max-screen: $screen-m - 1) {
                        font-size: 24px;
                    }

                    @include mq($screen-m - 1) {
                        font-size: 36px;
                    }

                    svg path {
                        fill: $color-accent-primary;
                    }
                }

                .cart-title {
                    font-weight: 500;

                    @include mq($screen-m - 1) {
                        font-size: 28px;
                    }
                }
            }
        }

        .modal__content {
            max-height: unset;
            height: auto;
        }
    }

    .minicart-content {
        max-height: inherit;

        .subtitle.empty {
            margin-top: 20px;
        }
    }

    .minicart-content__middle {
        max-height: 450px;
    }

    .modal__container {
        overflow-y: auto;

        .ship-by-datemessage {
            color: red;
            font-weight: 400;
            text-transform: uppercase;
        }
    }

    .minicart-product__image {
        .lazyload-wrapper {
            padding: 0 !important;
        }
    }
}

.minicart-content__middle {
    padding: 8px 0;
}

.minicart-content__actions {
    button.minicart-content__action-button {
        border-radius: 4px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
        text-transform: none;
        padding: 20px 32px;
        background-color: $button-add-all-color;
        width: 100%;
        font-weight: 500;

        @include mq($max-screen: $screen-m - 1) {
            font-size: 18px;
        }

        @include mq($screen-m - 1) {
            font-size: 24px;
        }
    }

    .minicart-content__edit {
        color: $button-add-all-color;
    }
}

.minicart-product__actions {
    .action.delete {
        min-width: unset;
        min-height: unset;

        svg {
            fill: #666666;
        }
    }
}

.minicart-content__bottom {
    .order-summary__list-item,
    .discount-option {
        width: 100%;
    }

    .discount-option-title {
        display: none;
    }

    .discount-option-content {
        .discount-option-inner {
            .field {
                > label {
                    display: none;
                }
            }
        }
    }

    .form-discount {
        display: flex;
        justify-content: space-between;

        .discount-option-inner {
            width: calc(100% - 195px);

            .control {
                position: relative;
            }

            input[type="text"] {
                height: 40px;
                border: 2px solid $input-discount-border-color;
                font-size: 16px;
                color: #000;
                padding-left: 16px;
                width: 100%;
                font-family: $font-family-base;

                &:focus,
                &:valid {
                    ~ label {
                        display: none;
                    }
                }

                ~ label {
                    position: absolute;
                    pointer-events: none;
                    font-size: 16px;
                    color: #B4B4B4;
                    top: 9px;
                    left: 15px;
                }
            }
        }

        .actions-toolbar {
            width: 180px;

            .action {
                border: 0;
                background-color: $button-discount-bg-color;
                color: #FFF;
                text-transform: uppercase;
                font-size: 16px;
                font-family: $font-family-base;
                border-radius: 0;
                width: 100%;
                height: 40px;
                font-weight: 700;

                &:hover {
                    background-color: $button-discount-bg-color-hover;
                }

                &.action-cancel {
                    &:before {
                        content: "REMOVE";
                    }

                    svg {
                        display: none;
                    }
                }
            }
        }
    }

    .minicart-content__summary {
        &.coupon-minicart {
            margin-top: 15px;
            margin-bottom: 0;
            padding-bottom: 0;

            > .label {
                margin-bottom: 0;
                text-transform: uppercase;
                font-size: 16px;
                color: #4F5148;
                line-height: 16px;
            }

            .price {
                font-size: 16px;
                font-weight: 700;
                color: #4F5148;
            }

            + .minicart-content__summary {
                margin-top: 10px;
                margin-bottom: 15px;
            }
        }

        &:not(.coupon-minicart) {
            margin-top: 25px;
            padding-bottom: 0;
            margin-bottom: 20px;
        }
    }
}

#minicart-addons {
    overflow: auto;
}

#popup-upsell-cart-inside, #popup-upsell-cart {
    margin-bottom: 30px;

    .section.upsell {
        margin-bottom: 0;

        @include mq($max-screen: $screen-m - 1) {
            .heading {
                font-size: 20px;
            }
        }
    }

    .section__heading {
        h2 {
            color: $color-accent-primary;
            max-width: 100%;
            font-size: 36px;
            padding: 16px 0;

            &:before,
            &:after {
                width: 60px;
                height: 13px;

                @include mq($max-screen: $screen-m - 1) {
                    width: 37px !important;
                    height: 8px;
                }
            }
        }
    }

    .product-grid-item__price {
        .price-label {
            display: none;
        }

        .price .price__value {
            text-align: left;
        }

        .price-container .price-wrapper {
            font-size: 24px !important;
            font-weight: 500 !important;

            .price {
                font-weight: 500;
                font-size: 24px;
            }

            @include mq($max-screen: $screen-m - 1) {
                font-size: 16px !important;

                .price {
                    font-size: 16px;
                }
            }
        }
    }

    .section__content--products {
        display: flex;
        flex-wrap: wrap;
        margin: 25px -9px 0;
        @include mq($max-screen: $screen-m - 1) {
            margin-top: 5px;
        }

        .slider__item {
            display: block;
            width: 33.333%;
            padding: 5px 9px;
        }

        .product-grid-item--slider-item {
            .product-grid-item__details {
                padding-left: 0;
                padding-right: 0;
                @include mq($screen-m) {
                    position: relative;
                    padding-bottom: 35px;
                }
            }

            .product-grid-item__details-bottom {
                margin-top: auto;
                display: flex;
                flex-wrap: inherit;
                justify-content: space-between;

                @include mq($screen-m) {
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                }

                .product-grid-item__price {
                    text-align: left;
                    width: auto;
                    margin: 0;
                }

                .product-grid-item__actions {
                    margin-left: 10px;
                    margin-right: 0;

                    .product-grid-item__primary-action .button--add-to {
                        min-width: inherit;
                        font-size: 16px;
                        padding: 0;
                        height: auto;
                        width: 110px;
                        min-height: 36px;
                        font-weight: 700;

                        &.added-from-minicart {
                            color: $button-color-added;
                            background: #FFF;
                            border: 2px solid $button-color-added;
                        }
                    }
                }

                .minimal-price .price,
                .price__value {
                    font-size: 16px;
                    line-height: 18px;
                }
            }
        }

        .product-grid-item__rating {
            height: auto;
            margin-top: 0;
            margin-bottom: 0;
            @include mq($screen-m) {
                min-height: 25px;
                padding-top: 10px;
            }

            .yotpo .text-m {
                display: none;
            }
        }

        .product-grid-item__name {
            font-size: 16px;
            line-height: 22px;
            min-height: 44px;
            align-items: baseline;
            margin-top: 10px;

            .product-grid-item__name-link {
                line-height: 22px;
            }
        }
    }
}

#popup-upsell-cart-inside, #popup-upsell-cart {
    .add-all-products {
        display: block;
        text-align: center;
        width: fit-content;
        margin: auto;

        .button-add-all-cart {
            display: flex;
            justify-content: center;
            align-items: center;
            background: none;
            padding: 20px 0 30px;
            border: none;
            gap: 8px;
            width: 100%;
            max-width: 310px;
            color: $button-add-all-color;
            font-weight: 400;
            text-transform: none;
            line-height: 100%;

            @include mq($max-screen: $screen-m - 1) {
                font-size: 16px;
            }

            @include mq($screen-m - 1) {
                font-size: 24px;
                text-decoration-line: underline;
            }

            svg  {
                width: 16px;
                height: 16px;

                path {
                    fill: $color-accent-primary;
                }
            }
        }
    }
}

@include mq($screen-m) {
    #modal-minicart {
        .minicart-product__data {
            position: relative;
            padding-right: 40px;
        }
    }
    .minicart-content__bottom {
        padding-top: 0;
        border: none;
    }
}

@include mq($max-screen: 440px) {
    #popup-upsell-cart-inside .section.upsell .heading {
        max-width: 220px;
        position: static;
    }
}

@include mq($max-screen: $screen-m - 1) {
    .minicart-content__bottom {
        .form-discount {
            .discount-option-inner {
                width: calc(100% - 120px);
            }

            .actions-toolbar {
                width: 110px;
            }
        }
    }
    .minicart-content__middle {
        margin-bottom: 0;
    }
    .minicart-product__data {
        padding-right: 0;

        .minicart-product__price {
            max-width: 54px;
        }
    }
    .minicart-product__bottom {
        position: relative;

    }
    .minicart-product__actions {
        top: inherit;
        left: 200px;
        right: inherit;

        .action.delete {
            background-color: transparent !important;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
    .minicart-content__bottom {
        position: static;
        box-shadow: none;
        border: none;
        padding: 0 0 20px 0;
    }

    #popup-upsell-cart-inside {
        padding-bottom: 30px;

        .section__heading {

            h2 {
                color: $green;
                max-width: 360px;
            }
        }

        .section__content--products {
            .slider__item {
                margin-top: 15px;
                width: 100%;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .product-grid-item:not(:root:root) {
            flex-direction: inherit;
            justify-content: space-between;
            display: flex;
        }

        .section__content--products {
            .product-grid-item--slider-item {
                .product-grid-item__link {
                    flex: 0 0 90px;
                    padding-top: 90px;

                    .product-grid-item__image-wrapper {
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                .product-grid-item__details {
                    width: calc(100% - 100px);
                    flex: inherit;
                    display: block;
                }

                .product-grid-item__details-bottom {
                    justify-content: unset;

                    .product-grid-item__actions {
                        width: auto;

                        .product-grid-item__primary-action .button--add-to {
                            width: 115px;
                        }
                    }
                }
            }

            .product-grid-item__name {
                min-height: inherit;
                text-align: left;
                display: block;
            }
        }
    }
}
