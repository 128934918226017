@import "cart-list-item-variables";

.cart-list-item {
    padding: $cart-list-item__padding;
    border-bottom: $cart-list-item__border-bottom;
    border-top: $cart-list-item__border-bottom;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include mq($max-screen: $screen-m - 1){
        padding: 16px 0;
    }

    &__left {
        display: flex;
        flex: auto;
        align-items: center;

        .cart-list-center-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-self: normal;
            margin-left: 16px;

            .cart-list-item__content {
                padding: 0;

                .link, h3 {
                    margin: 0;
                }

                h3 {
                    color: #121212;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 100%;
                    font-family: $font-family-base;

                    @include mq($max-screen: $screen-m - 1){
                        font-size: 16px;
                    }
                }

                .callout-text {
                    color: #666;
                }
            }

            .cart-list-item-actions {
                display: flex;

                .cart-list-item__qty {
                    padding: 0;
                    flex-basis: unset;

                    .select-qty-wrapper {
                        position: relative;

                        &:after {
                            content: '';
                            position: absolute;
                            right: 9px;
                            top: 9px;
                            pointer-events: none;
                            @include mq($max-screen: $screen-m - 1) {
                                width: 14px;
                                height: 14px;
                                right: 4px;
                                background-image: url('../images/icons/keyboard_arrow_down_mobile.svg');
                            }
                            @include mq($screen-m - 1) {
                                width: 18px;
                                height: 18px;
                                background-image: url('../images/icons/keyboard_arrow_down.svg');
                            }
                        }

                    }

                    .cart-list-item__input {
                        -moz-appearance:none;
                        -webkit-appearance:none;
                        appearance:none;
                        margin: 0;
                        border-radius: 4px;
                        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
                        border: 0.5px solid #A9A9A9;
                        font-weight: 500;
                        padding: 0 12px;
                        text-align: left;

                        @include mq($max-screen: $screen-m - 1) {
                            font-size: 14px;
                            width: 75px;
                            height: 35px;
                        }
                        @include mq($screen-m - 1) {
                            font-size: 18px;
                            width: 90px;
                            height: 40px;
                        }
                    }
                }

                .gift-options-cart-item {
                    display: none;
                }
            }
        }
    }

    &__image {
        width: $cart-list-item__image-width\@x-large;
        min-width: $cart-list-item__image-width\@x-large;

        @include mq($max-screen: $screen-m) {
            width: 80px;
            min-width: 80px;
        }
    }

    &__content {
        flex-basis: $cart-list-item__content-flex-basis;
        padding: $cart-list-item__content-padding;

        @include mq($screen-m) {
            flex-basis: $cart-list-item__content-flex-basis\@medium;
        }

        @include mq($screen-xl) {
            flex-basis: $cart-list-item__content-flex-basis\@x-large;
            padding: $cart-list-item__content-padding\@x-large;
        }

        @include mq($max-screen: $screen-m - 1) {
            padding: 0;
            flex-basis: 100%;
        }

    }

    &__name {
        display: block;
        font-family: $cart-list-item__name-font-family;
        font-size: $cart-list-item__name-font-size;
        font-weight: $cart-list-item__name-font-weight;
        line-height: $cart-list-item__name-line-height;
        text-transform: $cart-list-item__name-text-transform;
        @include mq($max-screen: $screen-m - 1) {
            font-weight: bold;
            letter-spacing: -0.02rem;
            + .margin-bottom-xs{
                margin-bottom: 5px;
            }
        }
    }

    &__attribute {
        display: block;
        width: 100%;
        color: $cart-list-item__attribute-color;
    }

    &__label,
    &__price {
        color: $cart-list-item__label-color;
        line-height: $cart-list-item__label-line-height;

        @include mq($max-screen: $screen-m - 1){
            line-height: 22px;
            letter-spacing: -0.02rem;
            color: #292F38;
            font-weight: 500;
        }

    }

    &__price {
        @include mq($screen-m) {
            font-size: $cart-list-item__price-font-size\@medium;
        }
    }

    &__label {
        @include mq($screen-m) {
            @include visually-hidden();
        }
    }

    &__details {
        @include mq($screen-m) {
            display: flex;
            flex-basis: 100%;
            align-items: flex-start;
        }

        @include mq($max-screen: $screen-m - 1){
            position: relative;
        }

    }

    &__wrapper {
        @include mq($screen-m) {
            flex-basis: 40%;
        }

        @include mq($screen-xl) {
            flex-basis: 35%;
        }

        @include mq($max-screen: $screen-m - 1){
            &:first-child{
                .cart-list-item__label{
                    scroll-margin-right: 8px;
                }
            }
        }
    }

    &__qty {
        margin: $cart-list-item__qty-margin;

        @include mq($screen-m) {
            flex-basis: 20%;
        }

        @include mq($screen-xl) {
            flex-basis: 30%;
            padding: $cart-list-item__qty-padding\@x-large;
        }

        @include mq($max-screen: $screen-m - 1){
            margin-top: 5px;
            .input__label{
                margin-right: 8px;
            }
        }

        + .cart-list-item__wrapper{

            @include mq($max-screen: $screen-m - 1){

                position: absolute;
                bottom: -4px;
                left: 94px;

                .cart-list-item__label{
                    display: none;
                }
            }

        }
    }

    &__input {
        width: $cart-list-item__input-width;
        height: $cart-list-item__input-height;
        margin: $cart-list-item__input-margin;
        appearance: textfield;

        @include mq($screen-xl) {
            width: 100%;
        }

        @include mq($max-screen: $screen-m - 1){
            width: 110px;
            height: 40px;
            border-color: #000;
            font-weight: bold;
            color: #000000;
        }

        &::-webkit-inner-spin-button, // sass-lint:disable-line no-vendor-prefixes
        &::-webkit-outer-spin-button { // sass-lint:disable-line no-vendor-prefixes
            appearance: none;
            margin: 0;
        }


    }

    &__total {
        font-size: $cart-list-item__total-font-size;
        line-height: $cart-list-item__total-line-height;
        font-weight: $cart-list-item__total-font-weight;

        @include mq($max-screen: $screen-m - 1){
            color: #292F38;
        }

        .cart-price .price {
            font-size: $cart-list-item__total-font-size;
            font-family: $font-family-base;

            @include mq($max-screen: $screen-m - 1){
                font-size: 16px;
            }
        }

    }

    &__actions {
        display: $cart-list-item__actions-display;
        flex-flow: row wrap;
        position: absolute;
        right: 0;
        top: 0;

        @include mq($screen-m) {
            display: $cart-list-item__actions-display\@medium;
            flex-basis: 100%;
            justify-content: flex-end;
        }

        @include mq($max-screen: $screen-m - 1) {
            top: inherit;
            //transform: translateY(-50%);
            // margin-top: 13px;]
            bottom: 0;
            top: auto;
            min-width: auto;
            display: block;
            min-height: auto;
            height: 33px;
        }

    }

    &__action {
        margin-right: $cart-list-item__action-margin-right;
        background-color: transparent;

        .cart-list-item__action-icon{
            fill: #666666;

            @include mq($max-screen: $screen-m - 1) {
                width: 16px;
                height: 16px;
            }
        }
        &:hover{
            .cart-list-item__action-icon{
                fill: #FFF;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        @include mq($max-screen: $screen-m - 1){
            margin-right: 0;
            min-width: auto;
            padding: 0 8px;
            min-height: auto;
            + div[id*="gift-options-cart-"]{
                display: none;
            }
        }

    }

    &__attributes-list {
        @extend .minicart-product__attributes-list;
    }

    &__gift-dropdown {
        width: 100%;
        @include mq($screen-m) {
            text-align: right;
        }
        .dropdown-list {
            &__content {
                display: none;
                &._active {
                    display: block;
                }
            }

            &__label {
                @include mq($screen-m) {
                    margin: $cart-list-item__gift-dropdown-label-margin\@medium;
                }

                &._active {
                    .dropdown-list__icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}
