.scottish-smoked-salmon{
  @include mq($max-screen: $screen-l - 1){
    .button{
      font-size: 14px;
    }
  }
}

.homepage-signup-form{
  .button{
    padding-top: 3px;
  }
}


.footer__newsletter{
  .newsletter__form{
    .button{
      padding-top: 3px;
    }
  }
}
