@import 'cms-page-variables';

.cms-page {
    &__content {
        max-width: $cms-page__max-width;
        margin: $cms-page__margin;

        @include mq($screen-m) {
            max-width: $cms-page__max-width\@medium;
        }

        @include mq($screen-l) {
            max-width: $cms-page__max-width\@large;
        }

        @include mq($screen-xl) {
            max-width: $cms-page__max-width\@xlarge;
        }

        .cms-contact &,
        .cms-contact-us & {
            max-width: 100%;
        }
    }
}

/*faq*/
.amfaq-title{
    font-weight: 700;
    font-size: 18px;
    margin: 0 0 .5em;
    line-height: 1.6;
    color: #4e5147;
}
.am-title{
    font-weight: bold;
    color: #457d3c;
    cursor: pointer;
}
.am-content p{
    margin: 5px 0;
}
.amfaq-question-footer-wrapper{
    margin-bottom: 20px;
}

@media (min-width: 768px){
    .amfaq-title{
        font-size: 24px;
    }
    .am-back-button + .heading{
        margin-top: 0;
    }
}

#amfaq_search{
    display: flex;
    position: relative;
}
#amfaq_search .am-input{
    width: calc(100% - 64px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 5px;
    padding-left: 10px;
}
.faq-search-index .input-text::-webkit-input-placeholder,
.faq-question-view .input-text::-webkit-input-placeholder,
.ask-question-form textarea::-webkit-input-placeholder {
    font-size: 14px;
}
.faq-search-index .input-text:-ms-input-placeholder,
.faq-question-view .input-text:-ms-input-placeholder,
.ask-question-form textarea:-ms-input-placeholder {
    font-size: 14px;
}
.faq-search-index .input-text::placeholder,
.faq-question-view .input-text::placeholder,
.ask-question-form textarea::placeholder {
    font-size: 14px;
}
.am-button .icon{fill: #FFF;}

#amfaq_search .search-autocomplete{
    top: 100%;
    background: #FFF;
    -webkit-box-shadow: 0 3px 5px rgba(50,50,50,0.25);
    -moz-box-shadow: 0 3px 5px rgba(50,50,50,0.25);
    box-shadow: 0 3px 5px rgba(50,50,50,0.25);
}
.faq-search-index .heading--page,
.faq-question-view .heading--page{
    // display: none;
}

.amfaq_categories .item.title,
.amfaq_tags .item.title,
.faq-category-title,
.am-tags-main-title{
    font-weight: bold;
    margin-top: 20px;
    font-size: 18px;
}

.am-widget-category:first-child .faq-category-title{
    margin-top: 0;
}
.amfaq-social-buttons{
    margin-top: 10px;
}
.amfaq-social-buttons > a{
    display: inline-block;
    width: 34px;
}
.ask-question-form .input__field{
    max-width: 500px;
}
.ask-question-form textarea{
    width: 100%;
    max-width: 500px;
    padding: 5px;
}
.ask-question-form  .button.submit{
    margin-top: 20px;
}
.ask-question-form .button-info{margin-top: 25px;}
.ask-question-form .form-toggle-button{
    margin:10px 0 20px;
}

.ask-form-container.amfaq-hidden{
    display: none;
}

.faq-category-view div:empty{
    display: none !important;
}

.cms-amasty-faq-home-page p:empty{
    display: none;
}

@include mq($screen-m){
    .faq-question-view	.column.main,
    .faq-search-index .column.main,
    .faq-category-view .column.main{
        padding-left: 80px;
    }
    .faq-question-view .columns,
    .faq-search-index .columns{
        padding-top: 30px;
    }
    .faq-category-view .sidebar{
        padding-top: 17px;
    }
}

@include mq($screen-m, $screen-l - 1){
    .page-layout-2columns-left.aq-category-view .sidebar{
        flex: 1 0 40%;
        max-width: 40%;
    }
    .page-layout-2columns-left.aq-category-view .main{
        flex: 1 0 60%;
        max-width: 60%;
    }
}

@include mq($max-screen: $screen-m - 1) {
    .amfaq-search{
        margin-top: 30px;
    }
    .am-search-box-wrapper .am-widget-center{
        width: 100% !important;
    }
}

/*contact-page*/
.cms__contact-us{
    max-width: 775px;
    margin: 0 auto;
}
.cms-contact-us{
    &.page-layout-2columns-left{
        .heading--page{
            @include mq($screen-m) {
                margin-left: 25%;
            }
        }
    }
    .heading--page{
        font-weight: $cms-contact-us-heading--page-font-weight;
        font-size: $cms-contact-us-heading--page-font-size;
        color: #000;
        position: relative;
        margin-top: 15px;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: -25px;
            width: 40px;
            height: 8px;
            margin: 0 auto;
            background-image: $cms-contact-us-heading--background-image;
            background-repeat: no-repeat;
        }
    }
    .cms__contact-us > h2,
    .cms-block-contact-us h2{
        font-family: $cms__contact-us-h2-font-family;
        font-weight: $cms__contact-us-h2-font-weight;
        font-size: $cms__contact-us-h2-font-size;
        line-height: $cms__contact-us-h2-line-height;
        color: #000;
        text-align: center;
    }
    .select2-container--default .select2-results>.select2-results__options{
        max-height: 420px;
    }
    .select2-container .select2-selection--single, .input-text, .input-text::placeholder {
        border-color:#cbcbcb;
        font-family: $font-family-base;
        font-size: 20px;
    }
    .select2-selection--single .select2-selection__arrow b{
        border-color: #292F38 !important;
        border-width: 0 3px 3px 0 !important;
        -webkit-transform: rotate(45deg) !important;
        transform: rotate(45deg) !important;
        margin-top: -7px !important;
    }
    .select2-container{
        .select2-search{
            display: none;
        }
        .select2-selection--single{
            .select2-selection__rendered{
                font-size: 20px;
            }
            .select2-selection__rendered[title*="Subject"]{
                color:#878A8E;
            }
        }
        .select2-results__option{
            font-size: 20px;
            color: #292F38;
            line-height: 42px;
            height: 42px;
            cursor: default;
        }
        .select2-results__option--highlighted[aria-selected],
        .select2-results__option:hover{
            background-color: #F0F0F0;
        }
        &.select2-container--open{
            .select2-selection--single{
                .select2-selection__rendered[title*="Subject"]{
                    color:#292f38;
                }
            }
        }
    }
    .webforms{
        .input__field--textarea, textarea{
            min-height: 130px;
            border-color:#cbcbcb;
            font-family: $font-family-base;
            font-size: 20px;
        }
        .input{
            margin-bottom: 20px;
        }
    }
    .contact-us__button{
        margin: 0;
        width: 100%;
    }
    .contact-us__tips{
        margin-top: 20px;
        color: #878A8E;
        .asterisk{
            color: #878A8E;
        }
    }
    .p-message{
        text-align: center;
        color: #878A8E;
        font-size: $p-message-font-size;
        line-height: $p-message-line-height;
        margin-top: 20px;
        a{
            text-decoration: none;
            font-weight: 500;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    .webforms{
        margin-top: 1.5rem;
        .start-xs{
            max-width: inherit;
            flex-basis: 100%;
        }
        .label{
            display: none;
        }
    }
    .select2-container{
        select2-selection--single{
            border-color:#cbcbcb;
        }
        &.select2-container--open{
            .select2-selection--single{
                border-color: #292F38;
            }
        }
        .select2-dropdown{
            border-color: #CBCBCB;
            margin-top: 2px;
        }
    }
    .select--native{
        //display: flex;
        //flex-wrap: wrap;
        .validation-advice{
            order: 3;
        }
    }
    ::-webkit-input-placeholder {
        color: #878a8e;
    }

    :-ms-input-placeholder {
        color: #878a8e;
    }

    ::placeholder {
        color: #878a8e;
    }
    .webforms-success-text{
        margin-bottom: 50px;
        text-align: center;
        color: #878A8E;
        font-size: 20px;
    }
    .select2{
        width: 100% !important;
    }
}
.am-title{
    color: #292F38;
    font-size: $am-title-font-size;
    font-weight: $am-title-font-weight;
    padding: 10px 0;
    position: relative;
    &[aria-expanded='false']{
        border-bottom: 1px solid #D8D8D8;
    }
    &[aria-expanded='true']{
        padding-bottom: 0;
    }
    .am-plus,
    .am-minus{
        width: 12px;
        height: 12px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        &:before{
            content: "";
            width: 100%;
            height: 2px;
            background: #292F38;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .am-plus:after{
        content: "";
        height: 100%;
        width: 2px;
        background: #292F38;
        position: absolute;
        to: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    .am-minus{
        margin-top: 1px;
    }
}
.am-content{
    border-bottom: 1px solid #D8D8D8;
    padding: 0 !important;
    div[data-element="inner"]{
        padding: 0 !important;
    }
    p{
        font-size: $am-content-p-font-size;
        line-height: $am-content-p-line-height;
        color: #878A8E;
    }
}
.amfaq_questions{
    margin-bottom: 50px;
}

.cms-block-contact-us{
    ul{
        display: flex;
        list-style: none;
        padding: 0;
        margin: 40px -30px 0;
        li{
            width: 30%;
            padding: 0 30px;
            text-align: center;
            &:nth-child(2){
                width: 40%;
            }
            &:nth-child(3){
                img{
                    height: 34px;
                }
            }
            .image{
                display: flex;
                height: 42px;
                align-items: center;
                justify-content: center;
                margin-bottom: 12px;
            }
            img{
                width: auto;
                max-width: 100%;
                height: 42px;
            }
            h3{
                font-size: $cms-block-contact-us-h3-font-size;
                font-weight: $cms-block-contact-us-h3-font-weight;
                font-family: $cms-block-contact-us-h3-font-family;
                color: #000;
                line-height: $cms-block-contact-us-h3-line-height;
                margin-bottom: 6px;
            }
            p{
                font-size: $cms-block-contact-us-p-font-size;
                line-height: $cms-block-contact-us-p-line-height;
                color: #878a8e;
                margin-bottom: 6px;
                a{
                    font-weight: 500;
                    text-decoration: none;
                }
            }
        }
    }
}

@include mq($max-screen: $screen-l - 1) {
    .cms-block-contact-us ul{
        margin-left: -10px;
        margin-right: -10px;
        li{
            padding: 0 10px;
        }
    }
}
@include mq($max-screen: $screen-m - 1) {
    .cms-contact-us{
        .page-wrapper{
            margin-bottom: 0;
        }
        .sidebar.sidebar-main{
            // display: none;
        }
        .heading--page{
            font-size: $cms-contact-us-heading--page-font-size\mobile;
            line-height: $cms-contact-us-heading--page-line-height\mobile;
            margin-top: 25px;
        }
        .cms__contact-us > h2,
        .cms-block-contact-us h2{
            font-size: $cms__contact-us-h2-font-size\mobile;
        }
        .am-content p{
            font-size: $am-content-p-font-size\mobile;
            line-height: $am-content-p-line-height\mobile;
        }
        .cms__contact-us .p-message{
            font-size: $p-message-font-size\mobile;
            line-height: $p-message-line-height\moible;
        }
    }

    .amfaq_questions{
        margin-bottom: 40px;
    }
    .am-title{
        font-size: $am-title-font-size\mobile;
    }

    .cms-block-contact-us{
        ul{
            display: block;
            margin: 30px 0 0;
            li{
                padding: 0;
                width: 100%;
                max-width: 200px;
                margin: 30px auto 0;
                &:first-child{
                    margin-top: 0;
                }
                &:nth-child(2){
                    max-width: 250px;
                    width: 100%;
                }
                h3{
                    font-size: $cms-block-contact-us-h3-font-size\mobile;
                    line-height: $cms-block-contact-us-h3-line-height\mobile;
                }
                p{
                    font-size: $cms-block-contact-us-p-font-size\mobile;
                    line-height: $cms-block-contact-us-p-line-height\mobile;
                }
            }
        }
    }
}


/*giftcardextended-checkbalance-index*/
.giftcardextended-checkbalance-index{

    #giftcard-form{
        display: flex;
        width: 100%;
        max-width: 670px;
        justify-content: space-between;
        .cart-discount__controls{
            display: block;
            padding: 0;
            margin: 0;
            width: calc(100% - 260px);
        }
        .cart-discount__content-input{
            width: 100%;
        }
        .cart-discount__content-field{
            height: 50px;
        }
        .cart-discount__content-button{
            height: 50px;
            font-size: $cart-discount__button-size;
            width: 250px;
            position: static;
            font-weight: bold;
        }

        @include mq($max-screen: $screen-s) {
            display: block;
            width: 100%;
            .cart-discount__controls{
                width: 100%;
            }
            .cart-discount__content-button{
                width: 100%;
                margin-top: 15px;
            }
        }
    }
}
/*end giftcardextended-checkbalance-index*/
