$header__minicart-border\@medium              : none;

// Buttons
$header-button__margin\@extra-large           : 0 0 0 $spacer--medium !default;
$header-button--margin\@extra-large           : $header-button__margin\@extra-large;
$header-button__counter-top                   : 7px;
$header-button__counter-right                 : 7px;

$header__buttons-width                        : 48px;

$header__search-wrapper-width\@large          : 610px !default;
$header__search-wrapper-width-focus\@large    : 325px !default;
$header__search-wrapper-max-width\@large      : calc(45% - 45px);
$header__search-wrapper-max-width\@extra-large: calc(45% - 120px);

$header__search-wrapper-padding               : 0 $spacer;
$header__search-wrapper-padding\@medium       : 0 $spacer;
$header__search-wrapper-padding\@large        : 0 $spacer;
$header__search-wrapper-padding\@extra-large  : 0 $spacer--semi-medium;

$search-form__height                          : 34px !default;
$search-form__width--extra-large              : 70% !default;
$search-form__border                          : 1px solid $gray-light !default;
$search-form__font-size                       : $input__placeholder-font-size !default;
$search-form__placeholder-color               : $input__placeholder-color !default;

$header__search-form-input-border-width       : 0 0 1px;
$header__search-form-input-padding            : 0 0 0 30px;
$header__search-form-input-font-family        : $font-family-base;
$header__search-form-input-font-weight        : normal;
$header__search-form-input-font-size          : 18px;
$header__search-form-input-font-color         : $gray-dark2;

$header__search-form-input-height-tablet      : 42px;
$header__search-form-input-padding-tablet     : 0 0 0 45px;
$header__search-form-input-border-tablet       : 1px 0 1px;
$header__search-form-input-font-size-tablet   : 18px;

$header__search-border-color                  : rgba(41, 47, 56, 0.3);


//redesign
$header__buttons-color                        : $gray-dark2;

$header-minicart-text-font-family             : $font-family-base;
$header-minicart-text-font-size               : 20px !default;
$header-minicart-text-font-tablet             : 18px;
$header-minicart-text-font-weight             : $font-weight-normal;
$header-minicart-text-transform               : none;
$header-minicart-text-font-color              : $link-button-color-gray-dark !default;
$header-minicart-text-font-color-hover        : $link-color-green !default;
$header-minicart-text-margin                  : 0 3px 0 15px;
$header__minicart-padding-left                : 0 !default;
$header__minicart-padding-left\@medium        : 0 !default;

$header-button__summary-count-font-size       : 17px;
$header-button__summary-count-font-weight     : $font-weight-normal;
$header-button__summary-count-font-size-tablet: 15px;
$header-button__summary-count-font-weight-tablet     : $font-weight-medium;


//button counter
$header-button__icon-before-bg--icon-fill     : $button__before-background--icon-light !default;
$header-button__counter-position              : relative;
$header-button__counter-top                   : inherit;
$header-button__counter-right                 : inherit;
$header-button__counter-width                  : 29px;
$header-button__counter-height                : 20px;
$header-button__counter-radius                : 10px;
$header-button__counter-background            :  $green2;
$header-button__counter-margin                : 0 0 0 7px;

$header-button__counter-width-mobile          : 25px;
$header-button__counter-height-mobile         : 18px;

//Header Sticky
$header-sticky-height-desktop: 196px !default;
$header-sticky-height-tablet: 135px !default;
$header-sticky-height-mobile: 112px !default;
