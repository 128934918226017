.mega-menu {
    &--secondary {
        z-index: 49;
        background-color: $mega-menu-secondary__background-color;

        &:before {
            z-index: 51;
            background-color: $mega-menu-secondary__background-color;
        }

        .mega-menu {
            &__link {
                color: $mega-menu-secondary__link-color;
                font-size: $mega-menu-secondary__link-font-size;
                font-family: $mega-menu-secondary__link-font-family;
                font-weight: $mega-menu-secondary__link-font-weight;
                text-transform: $mega-menu-secondary__link-text-transform;
                padding: $mega-menu-secondary__link-padding;
                position: relative;
                white-space: nowrap;

                &:hover,
                &.focus-visible {
                    color: $mega-menu-secondary__link-color--hover;
                    background-color: transparent;
                    text-decoration: $mega-menu-secondary__link-text-decoration--hover;
                    &:before{
                        display: none;
                    }

                }
                &:after{
                    content: "";
                    display: block;
                    height: 13px;
                    width: 1px;
                    background-color: $gray-dark2;
                    opacity: 0.3;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                @include mq($max-screen: $screen-xl - 1) {
                    padding: 0 20px;
                }
            }

            &__list {
                z-index: 51;
                background-color: $mega-menu-secondary__list-background-color;
                max-width: $mega-menu-secondary__max-width;
            }

            &__item {
                margin: 0;
            }
        }
    }

    &__inner-link {
        line-height: $font-line-height-secondary;
        &:before {
            display: none;
        }
    }

    &__item {
        margin: 0;

        &:first-child{
            .mega-menu__link{
                &:after{
                    display: none;
                }
            }
        }

        &:hover{

            .mega-menu__link:before{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 2px;
                background-color: $mega-menu-link-before-background;
            }
        }

        .mega-menu__inner-link {
            &:hover,
            &.focus-visible {
                color: $mega-menu__inner-link-color--hover;
            }
        }
    }

    &__list {
        margin: 0;

        @include mq($screen-xl) {
            margin: 0 auto;
        }
    }

    &__link{
        @include mq($max-screen: $screen-xl - 1) {
          padding: 0 20px;
        }
        @include mq($screen-xl, $screen-xl + 80) {
            padding: 0 2.5vw;
        }
      }

    &__inner-item {
        &:last-child {
            padding: 0;
        }
    }
}
#mega-menu{
  .mega-menu{
    &__item{
      &:nth-last-child(2){
        .mega-menu__inner-list{
          @include mq($screen-l, $screen-xl) {
            left: inherit;
            right: 0;
            max-width: 300px;
          }
        }
      }
    }
  }
}
