$minicart-content__height                      : auto !default;
$minicart-content__font-size                   : $font-size-base !default;
$minicart-content__padding                     : 0 !default;

//minicart-content top
$minicart-content__top-padding                 : 0 0 $spacer--semi-large 0 !default;
$minicart-content__top-margin                  : 0 !default;
$minicart-content__top-border                  : $border-base !default;
$minicart-content__top-border-width            : 0 0 $border-width-base 0 !default;

//minicart-content middle
$minicart-content__middle-padding              : $spacer--semi-medium 0 !default;
$minicart-content__middle-margin               : 0 0 80px !default;
$minicart-content__middle-margin\@medium       : 0 !default;
$minicart-content__middle-border               : 0 !default;
$minicart-content__middle-border-width         : 0 !default;

//minicart-content bottom
$minicart-content__bottom-width                : 100% !default;
$minicart-content__bottom-padding              : $spacer--medium !default;
$minicart-content__bottom-padding\@medium      : $spacer--semi-large 0 !default;
$minicart-content__bottom-margin               : 0 !default;
$minicart-content__bottom-background           : $white !default;
$minicart-content__bottom-border               : $border-base !default;
$minicart-content__bottom-border-width         : $border-width-base 0 0 0 !default;
$minicart-content__bottom-box-shadow           : 0 -2px 6px 0 rgba(0, 0, 0, 0.1) !default;
$minicart-content__bottom-box-shadow\@medium   : none !default;

//minicart-content heading
$minicart-content__heading-font-size           : $font-size-large !default;
$minicart-content__heading-font-size\@medium   : $font-size-extra-large !default;
$minicart-content__heading-font-weight         : $font-weight-base !default;
$minicart-content__heading-margin              : 0 !default;
$minicart-content__heading-height              : 48px !default;
$minicart-content__heading-padding-right       : 70px !default;
$minicart-content__heading-padding-left        : 0 !default;

//minicart-content counter
$minicart-content__counter-font-size           : $font-size-base !default;
$minicart-content__counter-font-weight         : $font-weight-base !default;
$minicart-content__counter-margin              : 0 !default;
$minicart-content__counter-height              : 24px !default;
$minicart-content__counter-padding-right       : 0 !default;
$minicart-content__counter-padding-left        : 0 !default;

//minicart summary
$minicart-content__summary-label-text-transform: uppercase !default;
$minicart-content__summary-label-color         : $color-primary !default;
$minicart-content__summary-label-font-weight   : $font-weight-base !default;
$minicart-content__summary-value-color         : $color-primary !default;
$minicart-content__summary-value-font-weight   : $font-weight-bold !default;

$minicart-content__summary-margin              : 0 0 $spacer--medium !default;
$minicart-content__summary-margin\@medium      : 0 0 $spacer--semi-large !default;
$minicart-content__summary-font-size           : $font-size-medium !default;

//minicart actions
$minicart-content__actions-padding             : 0 !default;
$minicart-content__action-flex-flow            : row wrap !default;
$minicart-content__action-justify              : space-between !default;
$minicart-content__action-button-width         : calc(50% - #{$spacer}) !default;
$minicart-content__action-button-padding       : 0 $spacer--semi-medium !default;

//extra-actions
$minicart-content__extra-actions-flex          : 0 0 100% !default;
$minicart-content__extra-actions-padding       : $spacer--medium 0 0 0 !default;

//minicart products
$minicart-content__products-list-padding       : 0 $spacer !default;
$minicart-content__products-list-margin        : 0 !default;
