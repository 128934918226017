
.checkout-cart-index{
  .order-summary{
    &__title{
      @include mq($screen-m){
        font-size: 18px;
      }
    }

    &__label,
    &__value{
      @include mq($screen-m){
        font-size: 16px;
      }
    }

    &__title-total,
    &__amount{
      @include mq($screen-m){
        font-size: 20px;
      }
    }

      .cart-discount__label {
          color: $color-accent-primary;

          svg path {
              fill: $color-accent-primary;
          }
      }
  }
  section.cart-list-item{
    *{
      @include mq($screen-m){
        font-size: 16px;
      }
    }
  }
}

body{
  .order-summary {
    .button--fluid.checkout,
    .dummy-checkout-upsell{
      @include mq($max-screen: $screen-m - 1){
        &:before{
          background: $button-blue !important;
        }
      }
    }
  }
}
