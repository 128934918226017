$footer-background-image: url('../images/footer-chesapeake/cb-footer-bg.png');

$footer__link-font-size: 16px;
$footer__link-color-hover: $button-blue;

//tablet
$footer-background-image\tablet: url('../images/footer-chesapeake/cb-footer-bg-tablet.png');
$footer-background-size\tablet: 100% 100%;

$footer__link-font-size\tablet: 14px;

$footer__copyright-font-size\tablet: 12px;

//mobile
$footer-background-image\mobile: url('../images/footer-chesapeake/cb-footer-bg-mobile.png');

