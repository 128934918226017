$footer-background-image: url('../images/footer-mackenzie/footer-mackenzie-bg.png');
$footer-background-size: 100% 760px;
$footer-background-position: top center;
$footer-background-repeat: no-repeat;

$footer__border                                            : none;

$footer__bottom-bar-background                             : $color-accent-primary;

$footer__link-color                                        : $color-primary;
$footer__link-font-weight                                  : $font-weight-medium;

$footer__scroll-top-bg                                     : $color-accent-primary;

$footer__handler-padding\@medium                           : 0;
$footer__handler-padding\@large                            : 0;

$footer__newsletter-padding\@large                         : 0;
$footer__newsletter-width\@large                           : 100%;

$footer__newsletter-border-width                           : 0;

$footer__links-padding                            : 50px 0;

$footer__links-dropdown-list-margin                        : 0;
$footer__links-dropdown-list-padding\@medium               : 0;
$footer__links-dropdown-list-padding\@large                : 0;
$footer__links-dropdown-list-padding\@extra-large          : 0;

$footer__links-list-item-min-width\@medium                 : 190px;

$footer__copywrite-padding                                 : $spacer 0 0;
$footer__copywrite-padding\@medium                         : $spacer--medium 0;
$footer__copywrite-font-size                               : $font-size-base;

$footer__links-dropdown-list-item-width-first-child\@medium: 100%;

$footer__social-list__icon-link-margin\@large              : 0;

$footer__bottom-padding\@large                             : 0;
$footer__bottom-padding\@xl                                : 0;

$footer__links-dropdown-background: none !default;

$footer__links-dropdown-background: none;
$footer__links-dropdown-font-family: $font-family-secondary;
$footer__links-dropdown-font-size: 25px;
$footer__links-dropdown-font-weight:600;
$footer__links-dropdown-color: $gray-dark2;
$footer__links-dropdown-line-height: 31px;
$footer__links-dropdown-margin: 0 0 12px;
$footer__links-dropdown-padding: 0;
$footer__links-dropdown-text-transform: none;


$footer__link-font-size: 20px;
$footer__link-font-weight: $font-weight-normal;
$footer__link-color: black;
$footer__link-color-hover: $green2;
$footer__link-line-height: 25px;


$footer__links-social-margin: 0 0 0 10px;

$footer__links-social-font-size: 0;
$footer__links-social-size: 35px;

$footer__links-social-facebook-background: url('../images/footer-mackenzie/icons/fb-ico.svg');
$footer__links-social-instagram-background: url('../images/footer-mackenzie/icons/ins-ico.svg');
$footer__links-social-pinterest-background: url('../images/footer-mackenzie/icons/pint-ico.svg');
$footer__links-social-twitter-background: url('../images/footer-mackenzie/icons/tw-ico.svg');

$footer__links-social-facebook-background-hover: url('../images/footer-mackenzie/icons/fb-ico-hover.svg');
$footer__links-social-instagram-background-hover: url('../images/footer-mackenzie/icons/ins-ico-hover.svg');
$footer__links-social-pinterest-background-hover: url('../images/footer-mackenzie/icons/pint-ico-hover.svg');
$footer__links-social-twitter-background-hover: url('../images/footer-mackenzie/icons/tw-ico-hover.svg');

$footer__bottom-bar-border-top: 1px solid #D8D8D8;

$footer__copyright-font-family: $font-family-base;
$footer__copyright-font-size: 16px;
$footer__copyright-font-weight: $font-weight-normal;
$footer__copyright-color: $gray-dark2;
$footer__copyright-line-height: 30px;
$footer__copyright-padding: 14px 0 12px;

//tablet
$footer-background-image\tablet: url('../images/footer-mackenzie/footer-mackenzie-bg-tablet.png');
$footer-background-size\tablet: 100% 584px;

$footer__links-padding\tablet                            : 25px 0;

$footer__links-dropdown-font-size\tablet: 18px;
$footer__links-dropdown-line-height\tablet: 22px;

$footer__link-font-size\tablet: 16px;

$footer__copyright-font-size\tablet: 14px;

//mobile
$footer-background-image\mobile: url('../images/footer-mackenzie/footer-mackenzie-bg-mobile.png');
$footer-background-size\mobile: 100% 100%;

$footer__links-padding\mobile: 15px 0 20px;

$footer__links-dropdown-margin\mobile: 10px 0;

$footer__copyright-line-height\mobile: 18px;

