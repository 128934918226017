$product-view__padding                          : 0;
$product-view__padding\@xl                      : $spacer--extra-large 0;

$product-view__wrapper-max-width                : unset;

$product-view__form-padding                     : 0 0 $spacer--medium;
$product-view__form-border                      : none;

$product-view__grouped-td-name-font-weight      : $font-weight-bold;
$product-view__grouped-td-name-margin\@medium   : 0;

$product-view__tabs-title-height                : 72px;

$product-view__badges-top\@large                : $spacer--semi-large;

$product-view__tabs-sticky-bg                             : $white !default;
$product-view__tabs-sticky-show-bq                        : $screen-m !default;

$product-view__slider-navigation-top: calc((100% / 3));
$product-view__slider-navigation-product-recommendations: calc((100% / 4));
$product-view__slider-yotpo-icon-star-color: $orange !important;

// details
$product-view__details-width\@medium                      : calc(50% - #{$spacer--medium}) !default;
$product-view__details-margin\@medium                     : 0 0 0 $spacer--medium !default;
$product-view__details-width\@large                       : calc(50% - #{$spacer--extra-large}) !default;
$product-view__details-margin\@large                      : 0 0 0 $spacer--extra-large !default;
$product-view__details-width\@xl                          : calc(43% - 80px) !default;
$product-view__details-margin\@xl                         : 0 !default;

// gallery
$product-view__gallery-width\@medium                      : 50% !default;

//badges
$product-view__badges-z-index                             : $z-index-low;
$product-view__badges-top                                 : $spacer--medium;
$product-view__badges-top\@medium                         : $spacer--semi-medium;
$product-view__badges-top\@large                          : $spacer--semi-medium;
$product-view__badges-left                                : 0;
$product-view__badges-left\@medium                        : 0;
$product-view__badges-left\@large                         : 136px;
$product-view__badges-left\@xl                            : 157px;

// title
$product-view__title-margin                               : 0 0 $spacer--semi-medium !default;
$product-view__title-margin\@medium                       : 0 !default;
$product-view__title-text-transform                       : initial !default;
$product-view__title-font-size                            : $font-size-large !default;
$product-view__title-font-size\@medium                    : $font-size-extra-large !default;
$product-view__title-font-size\@large                     : $font-size-super-extra-large !default;
$product-view__title-font-weight                          : 600 !default;
$product-view__title-line-height                          : 38px !default;

// stock
$product-view__stock-top-bg-hover                         : $spacer--medium !default;
$product-view__stock-bottom-bg-hover                      : $spacer--medium !default;
$product-view__stock-bg-hover                             : $gray !default;

// price
$product-view__price-display                              : none !default;
$product-view__price-display-bq                           : $screen-m !default;

$product-view__buttons-mq-display                         : $screen-m !default;

//tablet
$product-view__tabs-margin\tablet                                : 0 auto 30px !default;

//mobile

$product-view__slider-margin\mobile                             : 0 !default;


//$product-view__short-description-title
$product-view__short-description-title-font-family: $font-family-secondary;
$product-view__short-description-title-font-size: 20px;
$product-view__short-description-title-font-size\xl: 25px;
$product-view__short-description-title-font-weight: 600;
$product-view__short-description-title-font-color: #4E5147;
$product-view__short-description-title-line-height: 24px;
$product-view__short-description-title-line-height\xl: 30px;

//$product-view__short-description-par
$product-view__short-description-par-font-size\mobile: 14px;
$product-view__short-description-par-line-height\mobile: 22px;

//$product-view__qty-label
$product-view__qty-label-font-weight: 500 !default;

// grouped table
$product-view__grouped-table-margin                       : 25px 0 $spacer--medium;
$product-view__grouped-table-border-width                 : 0;
$product-view__grouped-qty-field-max-width                : 100%;