$product-gird-item__primary-margin          : 0;
$product-grid-item__name-height             : 45px;
$product-grid-item__price-min-height        : auto;

$product-grid-item__name-font-size\@medium  : $font-size-large;

$product-grid-item__border-width            : 0;
$product-grid-item__image-bg-hover                          : $black !default;
$product-grid-item__imgae-layout-color-hover                : rgba($black, 0.2) !default;
$product-grid-item__rating-margin-top                    : 15px !default;
$product-grid-item__price-value-font-size--between          : $font-size-medium !default;
$product-grid-item__price-margin                        : 0 14px !default;
$product-grid-item-ee__actions-margin: 0 14px;

@import 'product-grid-item';
@import 'mackenzie-product-grid-item';
