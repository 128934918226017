@import "cart-variables";

.cart {
    &__summary {
        @include mq($screen-l) {
            order: 1;
            margin-top: -115px;
            padding-left: 16px;

            .order-summary {
                height: 100%;
            }
        }
    }


}

.checkout-cart-index{

    .button.checkout {
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        text-transform: none;

        @include mq($max-screen: $screen-l){
            margin-top: 50px;
        }

        .checkout-button-label {
            font-size: 24px;
            font-weight: 500;

            @include mq($max-screen: $screen-l){
                font-size: 18px;
            }
        }
    }

    .page-main {
        .heading--page{
            @include mq($max-screen: $screen-l - 1){
                margin-bottom: 16px;
                text-align: left;
                font-weight: 500;
                font-family: $font-family-base;
            }
        }
    }

    .cart{
        &.row{
            @include mq($max-screen: $screen-m - 1){
                .col-lg-4{
                    order: 2;
                }
                .col-lg-8{
                    order: 1;
                }
            }
            .col-lg-8{
                .cart-discount{
                    @include mq($screen-m){
                        display: none !important;
                    }
                }
            }
            @include mq($max-screen: $screen-m - 1){
                .order-summary__list {
                    padding: 0;
                }
            }
        }

    }

    /*custom base on A/B testing*/
    @include mq($screen-l){
        .page-wrapper{
            margin-bottom: 0;
        }

        h1.heading{
            text-align: left !important;
            margin-top: 20px;
            position: absolute;
            font-family: $font-family-base;
            font-size: 32px;
            font-weight: 500;
            text-transform: none;
        }

        .col-lg-8{
            padding-top: 32px;
            padding-right: 16px;
        }

        .order-summary{
            position: relative;
            background-color: #F2F1EC;
            padding: 40px 24px 24px;
            margin: 0;
        }



        .footer{
            margin-top: 0;
        }
    }

    .order-summary{
        &__title{
            font-size: 24px;
            font-weight: 500;
            color: #121212;
        }

        &__label,
        &__value{
            font-size: 18px;
        }

        &__label {
            color: #121212;
        }

        &__value {
            color: #666666;
        }

        &__title-total,
        &__amount{
            font-size: 18px;
        }

        &__title-total {
            color: #121212;
        }

        &__amount {
            color: #000000;
            font-weight: 500;
        }
    }

    .cart-discount__label {
        color: $color-accent-primary;
        font-size: 16px;
    }

    section.cart-list-item{
        *{
            @include mq($screen-m){
                font-size: 18px;
            }
        }
    }

    .cart-grid__header-label{
        @include mq($screen-m){
            text-transform: uppercase;
        }
    }
    /*end custom base on A/B testing*/

    @include mq($max-screen: $screen-m - 1){
        .page-wrapper{
            margin-bottom: 0;
        }
        .footer{
            margin-top: 0;
        }
    }

    li.order-summary__list-item.route-label-sidebar {
        display: none;
    }
}
