// override inline styles injected by 3rd partys cript
.validator {
    &.validator-email,
    &.validator-phone {
        // sass-lint:disable no-important
        width: 100% !important;

        .input-text,
        .input__field {
            width: calc(100% - 72px) !important;
        }

        .feedback {
            width: 24px !important;
            height: 24px !important;
            top: 9px !important;
            right: 36px !important;
            background-size: 10px !important;
            position: absolute !important;
            border-radius: 50% !important;
            // sass-lint:enable no-important
        }
    }
}
