.heading {
    &--first-level {
        letter-spacing: 1px;
    }

    &--second-level {
        @extend .heading--font-secondary;
        font-weight: $font-weight-bold;
    }

    &--third-level {
        font-weight: $font-weight-bold;
    }
}
