.page-main {
    // sass-lint:disable no-important
    .product-view {
        .yotpoBottomLine {
            order: 3;

        }
        .product-view__rating {
            .QABottomLine,
            .bottomLine,
            .yotpoBottomLine {
                margin: 0;
            }
        }
        span.desktop-clear-btn-text,
        span.write-question-review-button-text.font-color-gray-darker {
            color: $white !important;
            width: auto;
        }
        .yotpo-label-container {
            border: none;
        }
    }
    // sass-lint:enable no-important
}
