$discount-code__margin                    : 0 #{-$spacer} !default;
$discount-code__margin\@medium            : 0 !default;
$discount-code__button-width              : 100% !default;
$discount-code__button-padding            : $spacer--medium 0 !default;
$discount-code__button-border             : $border-base !default;
$discount-code__button-border-width       : 0 0 1px 0 !default;
$discount-code__button-background         : none !default;
$discount-code__button-background--hover  : none !default;
$discount-code__button-text-color         : $color-primary !default;
$discount-code__content                   : $spacer--medium !default;
$discount-code__button-icon-transition    : $transition-base !default;
$discount-code__button-text-margin        : 0 $spacer 0 0 !default;
$discount-code__button-text-font-size     : $font-size-large !default;
$discount-code__button-text-font-weight   : $font-weight-normal !default;
$discount-code__button-text-text-transform: uppercase !default;
$discount-code__input-margin              : 0 0 $spacer--medium 0 !default;
$bg-button-discount                       : #476D8D !default;
$light-gray2                : #A9A9A9;
$color-accent-primary       : #476D8D;
$red                        : #D33A32;
$gray                       : #666;

.discount-code {
  width: 100%;
  order: 3;
  border-top: $light-gray2 solid 0.5px;
  padding-top: $spacer--medium;

  @include mq($max-screen: $screen-l - 1){
    border-top: none;
    padding: 0 $spacer--small $spacer--extra-large;
  }

  .discount-option-title{
    display: block;
    text-align: center;
    color: $color-accent-primary;
    font-size: $font-size-medium;
    cursor: pointer;

    .title-discount {
      &:after {
        font-family: 'new-icon';
        content: "\e900";
        padding: 0 10px 0 4px;
        vertical-align: middle;
      }
    }
    .action-toggle {
      text-decoration: underline;
    }
  }
  &._active{
    .discount-option-title{
      &:after{
        display: none;
      }
    }
  }
  .discount-option-content{
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    .messages{
      order: 1;
      .message{
        margin: 0;
        justify-content: flex-start;
        padding: 0;
        background: none;
        border: 0;
        &:after{
          display: none;
        }
      }
      .message-success{
        color: $color-accent-primary;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
        width: 80%;
        padding-top: 10px;
      }
      .message-error{
        color: $red;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
        width: 70%;
        padding-top: 10px;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: $discount-code__button-width;
    padding: $discount-code__button-padding;
    border: $discount-code__button-border;
    border-width: $discount-code__button-border-width;
    background: $discount-code__button-background;
    cursor: pointer;

    &:hover {
      background: $discount-code__button-background--hover;

      &:before,
      &:after {
        display: none;
      }
    }
  }

  &__content {
    display: none;
    padding: $discount-code__content;
  }

  &__button-icon {
    transition: $discount-code__button-icon-transition;
  }

  &__button-text {
    margin: $discount-code__button-text-margin;
    font-size: $discount-code__button-text-font-size;
    font-weight: $discount-code__button-text-font-weight;
    color: $discount-code__button-text-color;
    text-transform: $discount-code__button-text-text-transform;
  }

  &._active {
    .discount-code__button-icon {
      transform: rotate(180deg);
    }

    .discount-code__content {
      display: block;
    }
  }

  &__input {
    margin: $discount-code__input-margin;
  }
  .form-discount{
    display: -webkit-box;
    display: flex;
    margin: 0 0 8px;
    order: 0;
    width: 100%;
    position: relative;

    .discount-option-inner{
      .field {
        padding: 0;
        .control{
          margin-bottom: 0;
        }
        .input-text + span.icon {
          display: none;
        }
      }
    }
    .actions-toolbar {
      .action-apply {
        min-height: $spacer--large;
        font-size: $font-size-base;
        background: none;
        color: $color-accent-primary;
        border: $color-accent-primary 1px solid;
        position: absolute;
        right: $spacer--small;
        top: 12px;
        width: auto;
        padding: 0 $spacer--medium;
      }
      .action-cancel {
        border: none;
        background: none;
        font-size: 14px;
        font-weight: 500;
        text-decoration: underline;
        color: $gray;
        padding: 12px 0 0 0;
        position: absolute;
        top: 60px;
        right: 0;
        min-width: unset;
        max-height: unset;
        min-height: auto;
        width: auto;
        display: block !important;
      }
    }

    &.invalid {
      .discount-option-inner{
        .field{
          .input-text {
            border: $red solid 1px;
            pointer-events: auto;
            & + span.icon {
              display: block;
              &:before {
                content: "\e904";
                font-family: 'new-icon';
                color: $red;
                font-size: 24px;
              }
            }
          }
        }
      }
      .actions-toolbar {
        .action-apply {
          border: none;
          background: none;
          font-size: 14px;
          font-weight: 500;
          text-decoration: underline;
          color: $gray;
          padding: 12px 0 0 0;
          position: absolute;
          top: 60px;
          right: 50px;
          min-width: unset;
          max-height: unset;
          min-height: auto;
          width: auto;
          display: block !important;
          transition: none;
          span {
            &:after {
              content: '';
              width: 1px;
              height: 10px;
              background: $gray;
              display: inline-block;
              margin-left: 10px;
              vertical-align: middle;
            }
          }
        }
      }
    }
    &.applied {
      .input-text.valid {
        border: $color-accent-primary solid 1px;
        pointer-events: none;
        & + span.icon {
          display: block;
          &:before {
            content: "\e905";
            font-family: 'new-icon';
            color: $color-accent-primary;
            font-size: 24px;
          }
        }
      }
    }
  }
}

.minicart-content__bottom {
  .discount-code {
    padding: 0;
    border: none;
    .discount-option-content {
      display: flex !important;
      margin: 0;
      .messages {
        .message-success {
          color: $color-accent-primary;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 18px;
          width: 100%;
          padding: 0;
        }

        .message-error {
          color: $red;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 18px;
          width: 100%;
          padding: 0;
        }
      }
    }
    .form-discount {
      .actions-toolbar {
        .action {
          position: static;
        }
        .action-close {
          display: none !important;
        }
        .action-cancel {
          padding: 0;
          text-decoration: none;
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
