$scottish-smoked-salmon-box-shadow: 0 0 9px 0 rgba(0,0,0,0.15);
$scottish-smoked-salmon-padding: 22px !important;
$scottish-smoked-salmon-margin: 50px 0 !important;

$scottish-smoked-salmon-content-left-border: 0 dotted #D7CBAF !important;
$scottish-smoked-salmon-content-left-min-height: 515px;
$scottish-smoked-salmon-content-left-padding: 22px !important;

$scottish-smoked-salmon-content-left-before-display: none;


$scottish-smoked-salmon-content-max-width: 480px;
$scottish-smoked-salmon-content-margin: 0 auto;

$scottish-smoked-salmon-small-title-font-family: $font-family-base;
$scottish-smoked-salmon-small-title-font-size: 16px;
$scottish-smoked-salmon-small-title-color: $gray-dark2;
$scottish-smoked-salmon-small-title-line-height: 22px;
$scottish-smoked-salmon-small-title-text-transform: uppercase;
$scottish-smoked-salmon-small-title-margin: 3px 0 0;
$scottish-smoked-salmon-small-title-font-weight: $font-weight-bold;

$scottish-smoked-salmon-small-title-after-max-width: 160px;
$scottish-smoked-salmon-small-title-after-height: 2px;
$scottish-smoked-salmon-small-title-after-border-top: 2px solid #E5E9ED;
$scottish-smoked-salmon-small-title-after-margin: 20px auto 0;
$scottish-smoked-salmon-small-title-after-background: none;


$scottish-smoked-salmon-bigger-title-font-family: $font-family-secondary;
$scottish-smoked-salmon-bigger-title-font-size: 30px;
$scottish-smoked-salmon-bigger-title-font-weight: 600;
$scottish-smoked-salmon-bigger-title-color: $gray-dark2;
$scottish-smoked-salmon-bigger-title-line-height: 36px;
$scottish-smoked-salmon-bigger-title-margin: 20px auto 0;


$scottish-smoked-salmon-bigger-title-span-font-family: $font-family-base;
$scottish-smoked-salmon-bigger-title-span-font-size: 17px;
$scottish-smoked-salmon-bigger-title-span-line-height: 20px;
$scottish-smoked-salmon-bigger-title-span-font-weight: $font-weight-normal;
$scottish-smoked-salmon-bigger-title-span-margin: 10px 0 0;

$scottish-smoked-salmon-bigger-title-after-width: 40px;
$scottish-smoked-salmon-bigger-title-after-height: 13px;
$scottish-smoked-salmon-bigger-title-after-margin: 15px auto 0;
$scottish-smoked-salmon-bigger-title-after-background-image: url('../images/home/icons/cb-swirl.svg');


$scottish-smoked-salmon-paragragh-font-family: $font-family-base;
$scottish-smoked-salmon-paragragh-font-size: 16px;
$scottish-smoked-salmon-paragragh-font-color: $gray-light2;
$scottish-smoked-salmon-paragragh-font-weight: $font-weight-normal;
$scottish-smoked-salmon-paragragh-line-height: 22px;
$scottish-smoked-salmon-paragragh-margin: 20px 0 0;

$scottish-smoked-salmon-button-max-width: 224px;
$scottish-smoked-salmon-button-margin: 25px 0 0;


//tablet

$scottish-smoked-salmon-padding\tablet: 14px !important;
$scottish-smoked-salmon-margin\tablet: 34px 0 !important;

$scottish-smoked-salmon-content-left-min-height\tablet: inherit;
$scottish-smoked-salmon-content-left-padding\tablet: 30px 10px !important;

$scottish-smoked-salmon-content-max-width\tablet: 490px;

$scottish-smoked-salmon-small-title-font-size\tablet: 14px;
$scottish-smoked-salmon-small-title-line-height\tablet: 22px;

$scottish-smoked-salmon-small-title-after-max-width\tablet: 134px;
$scottish-smoked-salmon-small-title-after-margin\tablet: 15px auto 0;

$scottish-smoked-salmon-bigger-title-font-size\tablet: 22px;
$scottish-smoked-salmon-bigger-title-line-height\tablet: 26px;

$scottish-smoked-salmon-paragragh-font-size\tablet: 14px;
$scottish-smoked-salmon-paragragh-line-height\tablet: 20px;

$scottish-smoked-salmon-bigger-title-span-font-size\tablet: 16px;
$scottish-smoked-salmon-bigger-title-span-line-height\tablet: 22px;
