// Layout - breakpoints
$screen-xss                 : 375px !default;


// Colors
//$orange                     : #f38821;
//$green                      : #457d3c;
//$green-light                : #e3ebe1;
//$green-dark                 : #164b24;

$orange                     : #F78533;
$green                      : #4F7D3C;
$green-light                : #e3ebe1;
$green-dark                 : #164b24;

// Grayscale colors
$gray-darkest               : #4e5147;

// match default mage/calendar styles better than
// default gray colors (not covered yet by design)
$calendar-disabled-background: #f9f9f9;
$calendar-disabled-color     : #959595;

// Semantic color scheme
$color-accent-primary       : $green;
$color-accent-primary-dark  : $green-dark;
$color-accent-secondary     : $orange;

// Spacers
$spacer--mega-large         : 64px;

// Typography
$font-family-base           : 'Eureka Sans', sans-serif;
$font-family-secondary      : 'Chaparral Pro', serif;

$font-weight-semibold       : 600;

$font-size-base             : 16px;
$font-size-medium-large     : 20px;
$font-size-largest          : 48px;


$font-line-height-secondary : 1.2;


//color2
$orange2                    : #F78533;
$green2                      : #4F7D3C;
$gray-dark2                 : #292F38;
$gray-light2                : #878A8E;
$yellow-light2              : #F2F1EC;


//Button
$background-button-color-orange: $orange2;

//link
$link-color-green: $green2;
$link-button-color-orange: $orange2;
$link-button-color-gray-dark: $gray-dark2;
$link-button-color-hover: $green2;
$link-color-hover: $green2;

//paragraph
$paragraph-color-gray-linght: $gray-light2;

$gray-darkest2               : #292F38;
$font-size-header-section          : 40px;
