$cart-list-item__padding                           : $spacer--semi-medium 0 !default;
$cart-list-item__border-bottom                     : $border-base !default;
$cart-list-item__right-margin                      : 0 0 0 64px !default;
$cart-list-item__right-padding                     : 0 0 0 $spacer--medium !default;
$cart-list-item__right-margin\@medium              : 0 !default;
$cart-list-item__right-padding\@medium             : 0 !default;
$cart-list-item__image-width                       : 64px !default;
$cart-list-item__image-width\@medium               : 120px !default;
$cart-list-item__image-width\@x-large              : 128px !default;
$cart-list-item__content-padding                   : 0 0 0 $spacer--medium !default;
$cart-list-item__content-padding\@x-large          : 0 0 0 $spacer--large !default;
$cart-list-item__content-flex-basis                : calc(100% - 64px) !default;
$cart-list-item__content-flex-basis\@medium        : calc(100% - 80px) !default;
$cart-list-item__content-flex-basis\@x-large       : calc(100% - 128px) !default;
$cart-list-item__name-font-family                  : $font-family-base !default;
$cart-list-item__name-font-size                    : $font-size-medium !default;
$cart-list-item__name-font-weight                  : $font-weight-base !default;
$cart-list-item__name-line-height                  : 24px !default;
$cart-list-item__name-text-transform               : none !default;
$cart-list-item__attribute-color                   : $color-secondary !default;
$cart-list-item__label-color                       : $font-color-base !default;
$cart-list-item__label-line-height                 : 48px !default;
$cart-list-item__price-font-size\@medium           : $font-size-medium !default;
$cart-list-item__qty-margin                        : 0 !default;
$cart-list-item__qty-flex-direction\@medium        : column !default;
$cart-list-item__qty-padding\@x-large              : 0 $spacer--medium 0 0 !default;
$cart-list-item__input-width                       : 90px !default;
$cart-list-item__input-height                      : 48px !default;
$cart-list-item__input-margin                      : 0 $spacer 0 0 !default;
$cart-list-item__total-font-size                   : $font-size-extra-large !default;
$cart-list-item__total-line-height                 : 100% !default;
$cart-list-item__total-font-weight                 : $font-weight-medium !default;
$cart-list-item__action-margin-right               : 8px !default;
$cart-list-item__actions-display                   : flex !default;
$cart-list-item__actions-display\@medium           : flex !default;
$cart-list-item__gift-dropdown-label-margin\@medium: 0 0 0 auto !default;
