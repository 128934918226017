$color_placeholder                            : #878A8E;
$checkout-body-text                           : #292F38;
.success-page {
    padding: 0 0 48px;

    .col-xs-4 {
        background-color: #F2F1EC;
        padding: 40px 16px;

        .block-checkout-help {
            padding: 0;
        }
    }

    .col-lg-8 {
        @include mq($screen-l - 1) {
            padding-right: 3rem;
        }
    }

    &__order-confirmation {
        padding: 0 0 $spacer--large;
        margin-top: 1rem;
    }

    &__button {
        width: 100%;
        @include mq($screen-s) {
            width: auto;
        }
    }

    h1.heading--page {
        display: none;
    }

    .your-order{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 1rem;
        gap: 8px;
        @include mq($max-screen: $screen-m - 1) {
            border-bottom: 0.5px solid #E6E6E6;
        }

        .heading--page{
            color: #121212;
            font-size: 36px;
            font-weight: 500;
            line-height: 100%;
            text-align: center;
            margin: 0;
            @include mq($max-screen: $screen-m - 1) {
                font-size: 24px;
            }
        }

        .order-number {
            color: $color-accent-primary;
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            @include mq($max-screen: $screen-m - 1) {
                font-size: 24px;
            }
        }
    }
    @include mq($max-screen: $screen-m - 1) {
        padding: 0;
    }

    .success-notice {
        @include mq($screen-m - 1) {
            border-bottom: 0.5px solid #E6E6E6;
            padding-bottom: 2rem;
        }
        p {
            color: #666666;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            text-align: left;
            margin: 0;

            @include mq($max-screen: $screen-m - 1) {
                font-size: 16px;
                margin-top: 1rem;
            }
        }
    }

    .text-gourmet {
        color: #666666;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        margin: 0;
        padding: 1rem 0;
        border-bottom: 0.5px solid #E6E6E6;

        @include mq($max-screen: $screen-m - 1) {
            font-size: 16px;
        }
    }
}
.checkoutsuccess{
    &-shipping-address{
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        margin-top: 1rem;

        .checkoutsuccess-address {
            @include mq($screen-m - 1) {
                flex-basis: calc(50% - 0.5rem);
            }

            > div:not(:first-child) {
                margin-top: 12px;
            }

            .checkoutsuccess-title {
                font-size: 24px;
                font-style: normal;
                line-height: 100%;
                @include mq($max-screen: $screen-m - 1) {
                    font-size: 16px;
                }
            }

            .checkoutsuccess-content {
                color: #666666;
                font-size: 18px;
                font-style: normal;
                line-height: 100%;
                @include mq($max-screen: $screen-m - 1) {
                    font-size: 16px;
                }
            }

            .contact-info, .shipping-details {
                padding-bottom: 12px;
                border-bottom: 0.5px solid #E6E6E6;

                .shipping-method {
                    color: #121212;
                    border: 1px solid #A9A9A9;
                    padding: 10px;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%;
                    max-width: 220px;
                    margin: 12px 0;
                    @include mq($max-screen: $screen-m - 1) {
                        font-size: 16px;
                    }
                }
            }
        }

        .checkoutsuccess-summary {
            @include mq($screen-m - 1) {
                flex-basis: calc(50% - 0.5rem);
            }
        }

        &.loggedin{
            justify-content: center;
            .checkoutsuccess-address{
                border-right: 0;
            }
        }
    }
    &-address{
        text-align: left;
        @include mq($max-screen: $screen-m - 1) {
            width: 100%;
            border-right: 0;
        }
        table{
            tr{
                td{
                    vertical-align: top;
                    padding: 10px;
                    text-align: left;
                    color: #292F38;
                    font-size: 18px;
                    letter-spacing: 0.9px;
                    line-height: 20px;
                    a{
                        line-height: 20px;
                    }
                    &:first-child{
                        color: #292F38;
                        font-size: 18px;
                        font-weight: bold;
                        letter-spacing: 0.9px;
                        line-height: 20px;
                        text-align: right;
                    }
                }
            }
        }
        .billing-details {
            .checkoutsuccess-payment-info {
                display: flex;
                align-items: center;
                gap: 8px;
            }

            .VI-type{
                display: inline-block;
                width: 33px;
                height: 20px;
                background: url("../images/icons/vi.png") no-repeat;
            }
            .MC-type{
                display: inline-block;
                width: 33px;
                height: 20px;
                background: url("../images/icons/mc.png") no-repeat;
            }
            .AE-type{
                display: inline-block;
                width: 33px;
                height: 20px;
                background: url("../images/icons/ae.png") no-repeat;
            }
            .DI-type{
                display: inline-block;
                width: 33px;
                height: 20px;
                background: url("../images/icons/di.png") no-repeat;
            }
        }
    }
    &-create-account{
        text-align: left;
        @include mq($max-screen: $screen-m - 1) {
            padding-top: 20px;
            padding-left: 0;
            width: 100%;
        }
        h3 {
            color: #121212;
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            font-family: $font-family-base;
            @include mq($max-screen: $screen-m - 1) {
                font-size: 24px;
            }
        }
        p {
            color: #666666;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            @include mq($max-screen: $screen-m - 1) {
                font-size: 16px;
            }
        }
        form{
            .control:not(.checkbox){
                position: relative;
                margin-bottom: 20px;
                >.label{
                    pointer-events: none;
                    top: 16px;
                    position: absolute;
                    left: 13px;
                    padding: 0;
                    transition: .2s ease all;
                    -moz-transition: .2s ease all;
                    -webkit-transition: .2s ease all;
                    color: $color_placeholder;
                    -webkit-font-smoothing: antialiased;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 23px;
                }
                select ~ .label,
                .select ~ .label,
                input:focus ~ label,
                textarea:focus ~ label,
                input:valid ~ label,
                .company input ~ label,
                textarea:valid ~ label,
                input.filled-in ~ label,
                textarea.filled-in ~ label,
                input[readonly="readonly"] ~ label,
                textarea[readonly="readonly"] ~ label{
                    top: 5px;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 18px;
                    font-weight: 400;
                }

                select,
                input[readonly="readonly"],
                input[type="text"],
                input[type="password"],
                input[type="url"],
                input[type="tel"],
                input[type="search"],
                input[type="number"],
                input[type="datetime"],
                input[type="email"],
                textarea{
                    padding: 16px 0 10px 12px;
                    border-radius: 4px;
                    color: $checkout-body-text;
                    font-size: 24px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 100%;
                    height: 55px;
                    border: 0.5px solid #E6E6E6;
                    background-color: #FFFFFF;
                }

                textarea,
                textarea[readonly="readonly"] {
                    padding-top: 18px;
                    padding-left: 13px;
                    height: 120px;
                }
                .giftmessage-cnt{
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    color: #878A8E;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 17px;
                    text-align: right;
                }
            }
            .button--icon{
                min-height: inherit;
                height: auto;
                color: #292F38;
                font-size: 17px;
                font-weight: 500;
                letter-spacing: -0.96px;
                line-height: 20px;
                text-transform: none;
                text-decoration: underline;
                top: 17px;
                right: 15px;
                &:hover{
                    background: inherit;

                    .icon {
                        fill: inherit;
                    }
                }
            }
        }
        .primary{
            input[type="submit"]{
                width: 100%;
                background: $color-accent-primary;
                border-radius: 4px;
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
                font-size: 24px;
                color: #FFFFFF;
                letter-spacing: 0;
                text-align: center;
                border: 0;
                height: 55px;
                line-height: 55px;
                cursor: pointer;
                font-family: $font-family-base;
                font-weight: 500;
                @include mq($max-screen: $screen-m - 1) {
                    font-size: 18px;
                }
            }
        }
    }
    &-summary{

        @include mq($max-screen: $screen-m - 1) {
            padding-left: 0;
            padding-right: 0;
            padding-top: 20px;
            padding-bottom: 20px;
            flex-basis: 100%;
            border-top: 0.5px solid #E6E6E6;

        }
    }
    &-order{
        &-summary{
            @include mq($max-screen: $screen-m - 1) {
                width: 100%;
                margin-bottom: 20px;
                table{
                    max-width: 100%;
                }
            }
            table{
                width: 100%;
                border-collapse: collapse;
            }
            tr{
                th{
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 100%;
                    @include mq($max-screen: $screen-m - 1) {
                        font-size: 18px;
                    }
                }
                td {
                    font-size: 18px;
                    font-style: normal;
                    line-height: 100%;
                    @include mq($max-screen: $screen-m - 1) {
                        font-size: 16px;
                    }
                }
                td,
                th{
                    text-align: left;
                    padding: 10px 8px;
                    color: #121212;

                    &.align-right {
                        text-align: right;
                        color: #666666;
                    }
                }
                &.total{
                    td{
                        font-weight: 600;
                        color: #121212;
                    }
                }
            }
        }
        &-message{
            width: 100%;
            padding: 2rem;
            background-color: #F7F6F0;
            text-align: left;
            margin-top: 1rem;

            @include mq($max-screen: $screen-m - 1) {
                padding: 1rem;
            }

            .block-message-title {
                display: flex;
                align-items: center;
                gap: 16px;

                p {
                    margin-bottom: 0;
                    span {
                        color: #000000;
                        font-size: 36px !important;
                        line-height: 100%;
                        font-style: normal;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        gap: 12px;

                        @include mq($max-screen: $screen-m - 1) {
                            font-size: 24px !important;
                        }
                    }
                }

                svg {
                    min-width: 68px;
                    min-height: 79px;
                }

                img {
                    display: none;
                }
            }
            .block-message-content{
                color: #000000;
                font-size: 18px;
                line-height: 120%;
                @include mq($max-screen: $screen-m - 1) {
                    font-size: 12px;
                    line-height: 130%;
                    letter-spacing: -0.24px;
                    color: #242424;
                }
            }
        }
    }
    &-ctas {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;

        a.button {
            flex-basis: calc(50% - 0.5rem);
            margin: 1rem 0;
            border-radius: 4px;
            text-transform: none;
            font-size: 24px;
            font-weight: 500;
            padding: 16px 0;
            @include mq($max-screen: $screen-m - 1) {
                flex-basis: 100%;
                font-size: 16px;
                margin: 0;
            }

            &.buy-again {
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
                gap: 8px;
                align-items: center;
                svg {
                    @include mq($max-screen: $screen-m - 1) {
                        width: 16px;
                    }
                }
            }

            &.continue-shopping {
                color: $color-accent-primary;
                @include mq($screen-m - 1) {
                    background-color: #ffffff;
                    border: 1px solid #4F6C8A;
                }
                @include mq($max-screen: $screen-m - 1) {
                    border: none;
                    text-decoration: underline;
                    background: none;
                    padding: 0;
                }
            }
        }
    }
}
.checkout-continue{
    &-shop-wrap{
        text-align: left;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #D8D8D8;
        padding-left: 113px;
        padding-right: 113px;
        @media (max-width: 1024px){
            padding-left: 0;
            padding-right: 0;
            flex-wrap: wrap;
            .success-page__button{
                min-height: 55px;
            }
        }
        .success-page__button{
            background: none;
            border: 2px solid $color-accent-primary;
            color: $color-accent-primary;
            font-size: 16px;
        }
    }
    &-shop-switch{
        span{
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
            color: #878A8E;
            letter-spacing: 0;
            line-height: 22px;
        }
        .store-switcher{
            display: inline-block;
            vertical-align: middle;
            position: static;
        }
        .store-switcher__item{
            //padding: 0 10px;
            .store-switcher__button{
                margin: 0 5px;
            }
        }
        .store-switcher__button{
            border: 2px solid #E5E9ED;
        }
        @include mq($max-screen: $screen-m - 1) {
            text-align: center;
            span{
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}
.checkout-onepage-success{
    .page-wrapper{
        margin-bottom: 0;
    }
}
.shop-other-brands-wrapper {
    h2 {
        font-size: 24px;
        font-family: "Times New Roman";
        font-style: italic;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include mq($max-screen: $screen-m - 1) {
            color: $color-accent-primary;
        }

        &:before,
        &:after{
            content: "";
            display: block;
            width: $section__heading-background-width;
            height: $section__heading-background-height;
            background-size: 100% 100%;
            background-repeat: no-repeat;
        }

        &:before{
            background-image: $section__heading-background-before-url;
            background-position: right center;
            margin: 0 10px 0 0;
        }

        &:after{
            background-image: $section__heading-background-after-url;
            background-position: left center;
            margin: 0 0 0 10px;
        }
    }
    div[data-content-type="row"] {
        margin-bottom: 20px;

        .pagebuilder-button-secondary {
            color: $color-accent-primary;
            text-transform: none;
            padding: 16px 24px;
            border: 1px solid $color-accent-primary;
            border-radius: 4px;
            background-color: #ffffff;

            &:before, &:after {
                content: none;
            }

            &:hover {
                color: $color-accent-primary;
                border: 1px solid $color-accent-primary;
                background-color: #ffffff;
            }
        }

        .brands-categories {
            figure {
                margin: 0;
                @include mq($max-screen: $screen-m - 1) {
                    a, img {
                        width: 100%;
                        max-width: 350px;
                        margin: auto;
                    }
                }
            }
        }
    }
    .shop-other-brands-heading {
        figure {
            @include mq($max-screen: $screen-m - 1) {
                margin: 20px auto;
            }
        }
    }
    div[data-content-type="buttons"].pagebuilder-mobile-only {
        @include mq($max-screen: $screen-m - 1) {
            margin: 20px 0;
            .pagebuilder-button-secondary {
                width: 350px;
            }
        }
    }
}

.checkout-sidebar-help-info.tablet-only {
    @include mq($max-screen: $screen-l - 1) {
        margin: 0 -10px;

        .help-top {
            border-bottom: none;
        }
    }
}

@media (max-width: 1366px) {
    .checkout-continue{
        &-shop-wrap{
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }
}

.hidden {
    display: none;
}
