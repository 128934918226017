$mega-menu__position                                  : relative !default;
$mega-menu__breakpoint                                : $screen-l !default;
$mega-menu__z-index                                   : $z-index-high !default;
$mega-menu__background-color                          : $color-primary !default;
$mega-menu__margin                                    : 0 !default;
$mega-menu__border                                    : 0 !default;
$mega-menu__border-width                              : 0 !default;

$mega-menu__before-display                            : block !default;

//link
$mega-menu__link-height                               : 56px !default;
$mega-menu__link-padding                              : 0 $spacer--semi-medium !default;
$mega-menu__link-color                                : $gray-light !default;
$mega-menu__link-text-decoration                      : none !default;
$mega-menu__link-font-family                          : $font-family-base !default;
$mega-menu__link-font-size                            : $font-size-base !default;
$mega-menu__link-font-weight                          : $font-weight-base !default;
$mega-menu__link-text-transform                       : uppercase !default;

$mega-menu__link-color--hover                         : $color-primary !default;
$mega-menu__link-text-decoration--hover               : none !default;
$mega-menu__link-background-color                     : transparent !default;
$mega-menu__link-background-color--hover              : $white !default;
$mega-menu__link-background-color--focus-within       : $white !default;

//inner link
$mega-menu__inner-link-min-height                     : 48px !default;
$mega-menu__inner-link-padding                        : 0 $spacer--medium !default;
$mega-menu__inner-link-margin                         : 0 !default;
$mega-menu__inner-link-font-size                      : $font-size-base !default;
$mega-menu__inner-link-font-weight                    : $font-weight-medium !default;
$mega-menu__inner-link-color                          : $color-primary !default;
$mega-menu__inner-link-text-decoration                : none !default;
$mega-menu__inner-link-text-transform                 : capitalize !default;

$mega-menu__inner-link-text-decoration--hover         : none !default;
$mega-menu__inner-link-color--hover                   : $color-primary !default;
$mega-menu__inner-link-font-weight--hover             : $font-weight-bold !default;
$mega-menu__inner-link-before-transform--hover        : scaleX(1) !default;

$mega-menu__inner-link-before-display                 : block !default;
$mega-menu__inner-link-before-content                 : '' !default;
$mega-menu__inner-link-before-width                   : 100% !default;
$mega-menu__inner-link-before-position-left           : 0 !default;
$mega-menu__inner-link-before-position-top            : 0 !default;
$mega-menu__inner-link-before-position-bottom         : 0 !default;
$mega-menu__inner-link-before-bg                      : $gray !default;
$mega-menu__inner-link-before-transform               : scaleX(0) !default;
$mega-menu__inner-link-before-transform-origin        : 0 50% !default;
$mega-menu__inner-link-before-transition              : all 0.5s ease-in-out !default;

//inner item
$mega-menu__inner-item-padding                        : 0 !default;
$mega-menu__inner-item-margin                         : 0 !default;

//inner item nested
$mega-menu__inner-item-padding--children              : 0 0 0 $spacer--medium !default;

//list
$mega-menu__list-position                             : relative !default;
$mega-menu__list-padding                              : 0 !default;
$mega-menu__list-justify-content                      : space-between !default;
$mega-menu__list-background-color                     : $white !default;

//inner list
$mega-menu__inner-list-padding                        : 0 !default;
$mega-menu__inner-list-margin                         : 0 !default;
$mega-menu__inner-list-background-color               : $white !default;

//inner list--level1
$mega-menu__inner-list-top--level1                    : 100% !default;
$mega-menu__inner-list-width--level1                  : 100% !default;
$mega-menu__inner-list-padding--level1                : $spacer--medium 0 !default;
$mega-menu__inner-list-margin--level1                 : 0 !default;
$mega-menu__inner-list-box-shadow--level1             : $shadow !default;

//inner list--secondary (level1)
$mega-menu__inner-list-width--secondary               : 320px !default;
$mega-menu__inner-list-padding--secondary             : $spacer--semi-medium $spacer--medium !default;

//inner list--rows (level1)
$mega-menu__inner-list-padding--rows                  : $spacer--medium 0 $spacer--semi-medium 0 !default;
$mega-menu__inner-item-padding--rows                  : $spacer--medium $spacer--medium !default;
$mega-menu__inner-item-border--rows                   : $border-base !default;

//inner list--inline
$mega-menu__inner-link-font-weight--inline            : $font-weight-bold !default;
$mega-menu__inner-link-before-bg-color--inline        : $color-primary !default;
$mega-menu__inner-link-color-hover-inline             : $white !default;


//inner list--images
$mega-menu__inner-link-font-weight--images            : $font-weight-medium !default;
$mega-menu__inner-link-align-items--images            : flex-start !default;

//inner list--children
$mega-menu__inner-link-padding--children              : 0 $spacer--large !default;
$mega-menu__inner-link-font-weight--children          : $font-weight-base !default;
$mega-menu__inner-link-font-size--children            : $font-size-base !default;
$mega-menu__inner-link-color--children                : $color-secondary !default;
$mega-menu__inner-link-color-hover--children          : $color-primary !default;
$mega-menu__inner-link-text-decoration-hover--children: underline !default;
$mega-menu__inner-link-before-display-hover--children : none !default;
$mega-menu__inner-link-text-transform--children       : $mega-menu__inner-link-text-transform !default;
