@import "title-swirl-variables";

.title{
  &-swirl{
    font-family: $title-swirl-font-family;
    font-size: $title-swirl-font-size;
    color: $title-swirl-font-color;
    font-weight: $title-swirl-font-weight;
    line-height: $title-swirl-line-height;
    text-align: $title-swirl-text-align;
    margin: $title-swirl-margin;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before,
    &:after{
      content: "";
      display: inline-block;
      vertical-align: middle;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      width: $title-swirl-before-after-width;
      height: $title-swirl-before-after-height;
      background-image: $title-swirl-before-after-background-image;
    }

    &:before{
      margin: $title-swirl-before-margin;
    }

    &:after{
      margin: $title-swirl-after-margin;
    }

    @include mq($max-screen: $screen-l - 1){
      font-size: $title-swirl-font-size\tablet;
      line-height: $title-swirl-line-height\tablet;

      &:before{
        margin: $title-swirl-before-margin\tablet;
      }

      &:after{
        margin: $title-swirl-after-margin\tablet;
      }

    }


  }
}

