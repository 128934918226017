@import "home-banner-widget-variables";

.homepage-type-circle-banner-widget {
  margin: $homepage-type-circle-banner-widget-margin;

  @include mq($max-screen: $screen-l - 1){
    margin: $homepage-type-circle-banner-widget-margin\tablet;
  }

  @include mq($max-screen: $screen-m - 1){
    margin: $homepage-type-circle-banner-widget-margin\mobile;
  }


  .image-item{
    padding: $homepage-type-circle-banner-widget-item-padding;
    flex-basis: $homepage-type-circle-banner-widget-item-width;


    @include mq($max-screen: $screen-l - 1){
      padding: $homepage-type-circle-banner-widget-item-padding\tablet;
    }

    @include mq($max-screen: $screen-m - 1){
      flex-basis: 50%;
      padding: $homepage-type-circle-banner-widget-item-padding\mobile;
    }
  }

  .details{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
    display: flex;
    align-items: center;
    z-index: 1;
    left: $homepage-type-circle-banner-widget-details-position-left;
    right: $homepage-type-circle-banner-widget-details-position-right;
    justify-content: center;

    @include mq($max-screen: $screen-m - 1){
      left: $homepage-type-circle-banner-widget-details-position-left\mobile;
      right: $homepage-type-circle-banner-widget-details-position-right\mobile;
    }
  }

  .title {
    display: none;
  }
  .widget-image-grid {
    display: flex;
    justify-content: space-between;
    margin: $homepage-type-circle-banner-widget-image-grid-margin;

    @include mq($max-screen: $screen-l - 1){
      margin: $homepage-type-circle-banner-widget-image-grid-margin\tablet;
    }

    @include mq($max-screen: $screen-m - 1){
      flex-wrap: wrap;
      margin: $homepage-type-circle-banner-widget-image-grid-margin\mobile;
    }

  }

  div.image{
    position: relative;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    border-radius: 50%;

    .image{
      transition: $homepage-banner-widget-image-transition;
    }
  }

  .link-widget{
    width: $homepage-type-circle-banner-widget-link-width;
    height: $homepage-type-circle-banner-widget-link-height;
    background-image: $homepage-type-circle-banner-widget-link-background-image;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq($max-screen: $screen-l - 1){
      width: $homepage-type-circle-banner-widget-link-width\tablet;
      height: $homepage-type-circle-banner-widget-link-height\tablet;
    }

    @include mq($max-screen: $screen-m - 1){
      width: $homepage-type-circle-banner-widget-link-width\mobile;
      height: $homepage-type-circle-banner-widget-link-height\mobile;
    }

    .a-link{
      display: inline-block;
      position: relative;
      font-family: $homepage-type-circle-banner-widget-link-font-family;
      font-size: $homepage-type-circle-banner-widget-link-font-size;
      color: $homepage-type-circle-banner-widget-link-font-color;
      line-height: $homepage-type-circle-banner-widget-link-line-height;
      font-weight: $homepage-type-circle-banner-widget-link-font-weight;
      padding: $homepage-type-circle-banner-widget-link-padding;
      text-align: center;


      &:after{
        content: "";
        display: block;
        width: 100%;
        max-width: $homepage-type-circle-banner-widget-link-after-max-width;
        height: $homepage-type-circle-banner-widget-link-after-height;
        background: $homepage-type-circle-banner-widget-link-after-background;
        margin: $homepage-type-circle-banner-widget-link-after-margin;
      }

      @include mq($max-screen: $screen-l - 1){
        font-size: $homepage-type-circle-banner-widget-link-font-size\tablet;
        line-height: $homepage-type-circle-banner-widget-link-line-height\tablet;

        &:after{
          max-width: $homepage-type-circle-banner-widget-link-after-max-width\tablet;
          height: $homepage-type-circle-banner-widget-link-after-height\tablet;
          margin: $homepage-type-circle-banner-widget-link-after-margin\tablet;
        }

      }

    }
  }

  .image-item-container{
    position: relative;
    &:hover{
      .link-widget{
        .a-link{
          color: $homepage-type-circle-banner-widget-link-font-color-hover;
          text-decoration: none;
        }
      }
      div.image{
        img{
          transform: $homepage-banner-widget-image-transform;
          display: block;
          transition: $homepage-banner-widget-image-transition;
        }
      }

    }
  }


}

.home-type-rectangle-banner-widget {

    margin: $home-type-rectangle-banner-widget-margin;
    overflow: hidden;

    @include mq($max-screen: $screen-m - 1){
      margin: $home-type-rectangle-banner-widget-margin\mobile;
    }

    .images-grid{
      margin: $home-type-rectangle-banner-widget-images-grid-margin;
    }

    .image-item{
      padding: $home-type-rectangle-banner-widget-image-item-padding;


      &.col-lg-6{
        flex-basis: 50%;
        max-width: 50%;
        @include mq($max-screen: $screen-m - 1){
          flex-basis: 100%;
          max-width: 100%;
        }
      }

      &.col-lg-4{
        flex-basis: 33.333%;
        max-width: 33.333%;
        @include mq($max-screen: $screen-m - 1){
          flex-basis: 100%;
          max-width: 100%;
        }
      }

    }
    .widget-image-grid {
      display: flex;
      flex-flow: row wrap;
      overflow: hidden;
    }

    div.image{
      .image{
        transition: $homepage-banner-widget-image-transition;
      }
    }

    .image-item-container{
      position: relative;
      overflow: hidden;
      &:hover{
        .link-widget{
          .a-link{
            border-color: $home-type-rectangle-banner-widget-link-border-color-hover;
            background: $home-type-rectangle-banner-widget-link-background-hover;
          }
        }
        div.image{
          img{
            transform: $homepage-banner-widget-image-transform;
            display: block;
            transition: $homepage-banner-widget-image-transition;
          }
        }
      }
    }

    .details{
      flex-wrap: wrap;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      max-width: 100%;
      display: flex;
      align-items: center;
      z-index: 1;

      &.align-content_left{
        justify-content: $home-type-rectangle-banner-widget-details-align-content-left;
        padding: $home-type-rectangle-banner-widget-details-align-content-left-padding;
        text-align: $home-type-rectangle-banner-widget-details-align-content-left-text-align;
      }

      &.align-content_right{
        justify-content: $home-type-rectangle-banner-widget-details-align-content-right;
        padding: $home-type-rectangle-banner-widget-details-align-content-right-padding;
        text-align: $home-type-rectangle-banner-widget-details-align-content-right-text-align;
      }

      &.align-content_middle{
        justify-content: $home-type-rectangle-banner-widget-details-align-content-middle;
        padding: $home-type-rectangle-banner-widget-details-align-content-middle-padding;
        text-align: $home-type-rectangle-banner-widget-details-align-content-middle-text-align;
      }

      @include mq($max-screen: $screen-m - 1){
        text-align: center!important;
        justify-content: center !important;
        padding: 0 !important;
      }

    }
    .title{
      display: block;
      width: 100%;
      font-family: $home-type-rectangle-banner-widget-title-font-family;
      font-size: $home-type-rectangle-banner-widget-title-font-size;
      color: $home-type-rectangle-banner-widget-title-font-color;
      line-height: $home-type-rectangle-banner-widget-title-line-height;
      font-weight: $home-type-rectangle-banner-widget-title-font-weight;
      text-shadow: $home-type-rectangle-banner-widget-title-text-shadow;

      @include mq($max-screen: $screen-l - 1){
        font-size: $home-type-rectangle-banner-widget-title-font-size\tablet;
        line-height: $home-type-rectangle-banner-widget-title-line-height\tablet;
      }

    }
    .link-widget{
      .a-link{
       font-family: $home-type-rectangle-banner-widget-link-font-family;
        font-size: $home-type-rectangle-banner-widget-link-font-size;
        color: $home-type-rectangle-banner-widget-link-font-color;
        line-height: $home-type-rectangle-banner-widget-link-line-height;
        font-weight: $home-type-rectangle-banner-widget-link-font-weight;
        text-transform: $home-type-rectangle-banner-widget-link-text-transform;
        text-shadow: $home-type-rectangle-banner-widget-link-text-shadow;
        border: $home-type-rectangle-banner-widget-link-border;
        padding: $home-type-rectangle-banner-widget-link-padding;
        margin: $home-type-rectangle-banner-widget-link-margin;
        display: inline-block;
        &:hover{
          text-decoration: none;
        }
        @include mq($max-screen: $screen-l - 1){
          font-size: $home-type-rectangle-banner-widget-link-font-size\tablet;
          line-height: $home-type-rectangle-banner-widget-link-line-height\tablet;
          padding: $home-type-rectangle-banner-widget-link-padding\tablet;
          margin: $home-type-rectangle-banner-widget-link-margin\tablet;
        }
      }
    }
}