$mega-menu__background-color                : $color-accent-primary;

$mega-menu__link-color                      : $gray-lighter;
$mega-menu__link-font-size                  : $font-size-medium-large;
$mega-menu__link-font-weight                : $font-weight-semibold;
$mega-menu__link-text-transform             : none;
$mega-menu__link-height                     : 45px;
$mega-menu__link-font-family                : $font-family-secondary;
$mega-menu__link-padding                    : 0 35px !default;
$mega-menu__link-color--hover               : $link-button-color-gray-dark !default;


//inner link
$mega-menu__inner-link-min-height           : 37px !default;
$mega-menu__inner-link-font-size            : 22px;
$mega-menu__inner-link-color--hover         : $link-button-color-hover;
$mega-menu__inner-link-font-weight--hover   : $font-weight-normal;
$mega-menu__inner-link-font-weight          : $font-weight-normal !default;
$mega-menu__inner-link-padding              : 0 25px 0 35px;

$mega-menu__list-justify-content            : center;

$mega-menu-secondary__background-color      : $yellow-light2;
$mega-menu-secondary__max-width             : 1160px;
$mega-menu-secondary__link-color            : $link-button-color-gray-dark;
$mega-menu-secondary__link-color--hover     : $link-color-hover;
$mega-menu-secondary__link-font-size        : $font-size-medium;
$mega-menu-secondary__link-font-family      : $font-family-secondary;
$mega-menu-secondary__link-font-weight      : $font-weight-semibold;
$mega-menu-secondary__link-text-transform   : none;
$mega-menu-secondary__link-text-decoration--hover: none;
$mega-menu-secondary__link-padding          : 0 35px !default;

$mega-menu-secondary__list-background-color : $yellow-light2;

$mega-menu-link-before-background: $green2;

//inner list--secondary (level1)
$mega-menu__inner-list-width--secondary      : 348px;
$mega-menu__inner-list-padding--secondary    : 10px 0;

$mega-menu__inner-list-box-shadow--level1     : 0 1px 8px 0 rgba(0,0,0,0.1);




