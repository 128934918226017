$cms-page__max-width        : 88% !default;
$cms-page__max-width\@medium: 85% !default;
$cms-page__max-width\@large : 78% !default;
$cms-page__max-width\@xlarge: 68% !default;
$cms-page__margin           : 0 auto !default;


//contact-page

//Desktop
$cms-contact-us-heading--page-font-size: 40px;
$cms-contact-us-heading--page-font-weight: 600;
$cms-contact-us-heading--page-font-size\mobile: 25px;
$cms-contact-us-heading--page-line-height\mobile: 25px;
$cms-contact-us-heading--background-image: url("../images/icons/swirl.svg");

$cms__contact-us-h2-font-family: $font-family-secondary !default;
$cms__contact-us-h2-font-weight: 600;
$cms__contact-us-h2-font-size: 25px;
$cms__contact-us-h2-line-height: 25px;
$cms__contact-us-h2-font-size\mobile: 20px;
$cms__contact-us-h2-line-height\mobile: 25px;

$am-title-font-size: 20px;
$am-title-font-weight: 500;
$am-title-font-size\mobile: 18px;

$am-content-p-font-size: 20px;
$am-content-p-line-height: 25px;
$am-content-p-font-size\mobile: 18px;
$am-content-p-line-height\mobile: 23px;

$cms-block-contact-us-h3-font-family:  $font-family-secondary !default;
$cms-block-contact-us-h3-font-weight: 600;
$cms-block-contact-us-h3-font-size: 18px;
$cms-block-contact-us-h3-line-height: 25px;
$cms-block-contact-us-h3-font-size\mobile: 16px;
$cms-block-contact-us-h3-line-height\mobile: 23px;

$cms-block-contact-us-p-font-size: 18px;
$cms-block-contact-us-p-line-height: 25px;
$cms-block-contact-us-p-font-size\mobile: 16px;
$cms-block-contact-us-p-line-height\mobile: 23px;

$p-message-font-size: 20px;
$p-message-line-height: 25px;
$p-message-font-size\mobile: 18px;
$p-message-line-height\moible: 23px;


$cart-discount__button-size: 18px;

