.contact-bar {
    position: relative;
    @include mq($screen-m){
        display: -webkit-box;
        display: flex;
        order: 3;
    }
    &__container{
        margin: 0;
        @include mq($max-screen: $screen-l - 1) {
            align-items: center;
        }
    }

    &__link{
        @include mq($max-screen: $screen-l - 1) {
            font-size: $contact-bar__link-font-size-tablet;
            padding: 0 12px;
        }
    }

    &:after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: calc(100% - #{$contact-bar__store-switcher-width});
        height: 1px;
        background: $gray;
    }
}
