@font-face {
    font-family: 'Gift Card Page';
    src:
        url('../fonts/icongiftcardpage/icongiftcardpage.woff2') format('woff2'),
        url('../fonts/icongiftcardpage/icongiftcardpage.woff') format('woff'),
        url('../fonts/icongiftcardpage/icongiftcardpage.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src:
        url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff'),
        url('../fonts/Roboto-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

.content-block {
    &--secondary {
        border: 4px solid $color-accent-primary;
        border-width: 4px 0;
    }

    &__heading {
        color: $color-accent-primary;
        font-family: $font-family-secondary;
        font-weight: $font-weight-base;
        text-transform: uppercase;

        &--secondary {
            display: flex;
            align-items: center;
            height: 100%;
            margin: 0;
            line-height: $font-line-height-secondary;

            @include mq($screen-l) {
                font-size: $font-size-largest;
                font-weight: $font-weight-medium;
            }
        }
    }
}

.trackorder-index-index{
    .page-main{
        max-width: 600px;
        margin:  0 auto;
        padding:  0 15px;
    }
    .heading{
        text-align: center;
        font-weight: 400;
        font-size: 32px;
        color:  $gray-dark2;
        @include mq($max-screen: $screen-m - 1){
            font-size: 25px;
        }
    }
    .trackorder-wrap{
        div.input{
            margin-bottom: 20px;
        }
        label{
            font-size: 16px;
            color:  #6F7577;
            margin-bottom: 10px;
            font-weight: 500;
            @include mq($max-screen: $screen-m - 1){
                margin-bottom: 5px;
            }
        }
        input#submit{
            background-color: $green2;
            border-color:  $green2;
            color:  #FFF;
            font-size: 20px;
            font-family: $font-family-base;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: -1px;
            margin-top: 10px;
            line-height: 50px;
            height: 50px;
            &:hover{
                background: #FFF;
                color: $green2;
                border-color: $green2;
            }
        }
    }
    .ms-note{
        font-size: 12px;
        color:  #6F7577;
        display: block;
        margin-top:  5px;
        font-weight: 500;
    }
    .line{
        text-align: center;
        height: 18px;
        position: relative;
        margin-top: 60px;
        @include mq($max-screen: $screen-m - 1){
            margin-top: 30px;
        }
        &:before{
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #000;
            top:  50%;
            transform: translateY(-50%);
            left:  0;
            z-index: -1;
        }
        span{
            display: inline-block;
            background-color: #FFF;
            font-size: 20px;
            line-height: 18px;
            padding:  0 12px;
            font-family: $font-family-base;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: -1px;
            color:  #000;
            vertical-align: top;
        }
    }
    .trackoder-login{
        text-align: center;
        margin-top: 30px;
        .returning-customer{
            font-family: $font-family-secondary;
            font-size: 32px;
            color: $gray-dark2;
            margin-bottom: 25px;
            @include mq($max-screen: $screen-m - 1){
                font-size: 24px;
                margin-bottom: 20px;
            }
        }
        > p{
            font-size: 20px;
            letter-spacing: -1px;
            color: $gray-dark2;
            line-height: 18px;
            @include mq($max-screen: $screen-m - 1){
            }
        }
        > a{
            display: block;
            height: 50px;
            text-align: center;
            border: 2px solid $green2;
            font-family: $font-family-base;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: -1px;
            text-transform: uppercase;
            line-height: 46px;
            margin-top: 25px;
            line-height: 50px;
            &:hover{
                background-color: $green2;
                color:  #FFF;
                text-decoration: none;
            }
        }
    }
}

.cms-catalog-preview {
    .breadcrumbs, .heading--page {
        display: none;
    }
    #maincontent, .cms-page__content {
        max-width: 100%;
        margin: 0;
    }
    .catalog-title {
        background: #8fa771;
        display: flex;
        flex-flow: column;
        justify-content: center;
        h1 {
            color: #f4f4f4;
            font-size: 40px;
            line-height: 40px;
            padding: 25px 0 0 0;
            margin: 0;
            @include mq($max-screen: $screen-m - 1){
                font-size: 25px;
                line-height: 25px;
                padding: 18px 10px 2px;
            }
        }
        h2 {
            color: $green-dark;
            padding: 0 0 10px 0;
            margin: 0;
            font-family: Chaparral Pro,serif;
            font-weight: 500;
            font-style: italic;
            font-size: 24px;
            @include mq($max-screen: $screen-m - 1){
                font-size: 16px;
                padding: 0 10px 5px 10px;
            }
        }
    }
    .catalog-subtitle {
        max-width: 905px;
        width: 100%;
        padding: 40px 20px;
        margin: 0 auto;
        box-sizing: border-box;
        font-size: 30px;
        line-height: 34px;
        .button-catalog {
            display: none;
        }
        @include mq($max-screen: $screen-m - 1) {
            padding: 12px 35px;
            font-size: 13px;
            line-height: 16px;
            .button-catalog {
                display: block;
                padding: 3px 12px;
                width: max-content;
                max-width: initial;
                font-size: 21px;
            }
        }
    }
    .button-catalog {
        background: $green-dark;
        text-transform: capitalize;
        padding: 10px 25px 8px;
        font-family: "Chaparral Pro", serif;
        font-size: 30px;
        line-height: 30px;
        min-height: auto;
        font-style: italic;
        font-weight: 400;
        width: auto;
        color: #FFF;
        text-decoration: none;
        margin-top: 10px;
        &:hover {
            background: $orange;
            text-decoration: none;
        }
        @include mq($max-screen: $screen-m - 1) {
            font-size: 28px;
            margin: 8px auto 0;
        }
    }
    .pagebuilder-column-group {
        max-width: 905px;
        width: 100%;
        padding: 0 20px;
        margin: 0 auto;
        box-sizing: border-box;
        display: flex;
        flex-flow: row wrap;
        .pagebuilder-column {
            padding: 0 !important;
            figure {
                margin: 0 !important;
            }
            &:nth-child(2) {
                width: 50% !important;
            }
            &:nth-child(3) {
                width: 100% !important;
                margin-top: 35px;
            }
        }
        .cms__content {
            padding: 0px 42px;
            text-align: center;
            h2 {
                font-family: "Chaparral Pro", serif;
                font-weight: 500;
                font-size: 35px;
                width: 100%;
                display: flex;
                flex-flow: column;
                align-items: center;
                color: $green-dark;
                letter-spacing: -0.01em;
                line-height: 45px;
                margin-bottom: 10px;
                span {
                    font-style: italic;
                    font-size: 25px;
                    line-height: 25px;
                    padding: 5px 15px 0;
                    background: $green;
                    color: #FFF;
                    margin-bottom: 4px;
                }
            }
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                text-align: left;
                li {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: flex-start;
                    border-bottom: 1px solid $green-dark;
                    margin-bottom: 15px;
                    .num {
                        font-size: 50px;
                        color: $green;
                        padding: 0 22px 0 0;
                    }
                    h3 {
                        font-family: "Chaparral Pro", serif;
                        color: $green-dark;
                        font-weight: 600;
                        font-size: 25px;
                        margin: 0;
                        line-height: 30px;
                    }
                    p {
                        font-size: 21px;
                        font-family: "Chaparral Pro", serif;
                        line-height: 26px;
                        margin: 0;
                        padding: 10px 0 15px;
                        strong {
                            font-weight: 600;
                        }
                    }
                    &:last-child {
                        margin: 0;
                        border: none;
                    }
                }
            }
        }
        @include mq($max-screen: $screen-m - 1) {
            padding: 0 14px;
            flex-flow: column;
            .pagebuilder-column {
                width: 100% !important;
                &:nth-child(1) {
                    order: 1;
                }
                &:nth-child(2) {
                    order: 3;
                    width: 100% !important;
                }
                &:nth-child(3) {
                    order: 2;
                    margin: 5px 0 25px;
                }
            }
            .cms__content {
                padding: 0;
                ul {
                    li {
                        .num {
                            padding: 0 20px 0 0;
                        }
                    }
                }
            }
        }
    }
    .pagebuilder-column-line {
        flex-flow: row wrap;
    }
}

.cms-social-offer {
    .cms-page__content {
        max-width: 100%;
    }
    .heading--page, .breadcrumbs {
        display: none;
    }
}

.gfcard-page-index{
    .gifcard-heading{
        margin: 20px 0;
        h3 {
            font-weight: 600;
            font-size: 40px;
            line-height: 48px;
            font-family: $font-family-secondary;
            color: #292F38;
            text-transform: capitalize;
            margin-bottom: 10px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            &:before, &:after {
                content: '';
                width: $banner__heading-background-image-width;
                height: $banner__heading-background-image-height;
                background-image: $banner__heading-background-image;
                background-repeat: no-repeat;
                display: inline-block;
                margin: 0 20px;
                flex: 0 0 auto;
            }

            @include mq($max-screen: $screen-m - 1) {
                font-size: 25px;
                line-height: 30px;
                margin: 0 auto 10px;
            }
        }
        .description{
            color: #878A8E;
            font-weight: 400;
            font-size: 25px;
            line-height: 30px;
            text-align: center;
            margin-bottom: 10px;
            font-family: $font-family-base;

            @include mq($max-screen: $screen-m - 1) {
                margin: 0 auto 10px;
                font-size: 20px;
                line-height: 24px;
            }
        }
        img{
            margin: 0 auto;
            max-width: 430px;
            display: block;
        }
        @include mq($max-screen: $screen-m - 1) {
            img {
                max-width: 100%;
                width: 100%;
            }
        }
    }
    .giftcard-page-area{
        max-width: 1120px;
        width: 100%;
        margin: 0 auto;
    }
    .giftcard-page-tab-wrapper{
        display: flex;
        max-width: 560px;
        margin: 0 auto;

        .giftcard-page-tab{
            flex: 0 0 50%;
            max-width: 50%;
            font-weight: 600;
            font-size: 19px;
            text-align: center;
            cursor: pointer;
            line-height: 72px;
            color: #6E7577;
            background: transparent;
            font-family: $font-family-secondary;

            @include mq($max-screen: $screen-m - 1) {
                font-size: 15px;
                line-height: 45px;
            }

            &.active{
                color: #4E5147;
                background: #EAEAEA;
            }
        }
    }
    .purchase-gift-card-content-wrapper{
        padding: 45px 0;
        border-top: 1px solid #D8D8D8;
        font-family: $font-family-base;

        @include mq($max-screen: $screen-m - 1) {
            padding: 15px 0;
        }
    }

    .purchase-content-row {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        .label {
            font-weight: 400;
            font-size: $font-size-giftcard-title;
            line-height: 36px;
            color: #292F38;
            margin: 0;

            @include mq($max-screen: $screen-m - 1) {
                font-size: $font-size-giftcard-title/1.2;
                line-height: 30px;
            }
        }

        .mail-buttons {
            .email-giftcard {
                width: 230px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: 2px solid $color-accent-primary;
                color: $color-accent-primary;
                font-family: $font-family-base;
                margin-left: 22px;
                font-weight: 700;
                font-size: $font-size-giftcard-button;
                height: 50px;
                text-transform: uppercase;
                &:hover,
                &.active {
                    background: $color-accent-primary;
                    color: #fff;
                    text-decoration: none;
                }

                .icon-gc-email {
                    &:before {
                        content: '\e901';
                        font-family: 'Gift Card Page';
                        margin-right: 8px;
                        font-size: 22px;
                        font-weight: 400;

                        @include mq($max-screen: $screen-m - 1) {
                            font-size: 20px;
                        }
                    }
                }

                .icon-gc-mail {
                    &:before {
                        content: '\e900';
                        font-family: 'Gift Card Page';
                        margin-right: 8px;
                        font-size: 22px;
                        font-weight: 400;

                        @include mq($max-screen: $screen-m - 1) {
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        @include mq($max-screen: $screen-m - 1) {
            flex-flow: column;
            .mail-buttons {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                flex-flow: row nowrap;
                margin-top: 15px;
                .email-giftcard {
                    width: 48%;
                    margin: 0;
                    height: 45px;
                }
            }
        }
    }

    .physical-card {
        display: none;

        &.active {
            display: block;
        }
    }

    .virtual-card {
        display: none;

        &.active {
            display: block;
        }
    }

    .form-gift-card {
        max-width: 690px;
        margin: 60px auto 0;

        label {
            color: #6E7577;
            font-weight: 500;
            font-size: $font-size-medium-large;
            line-height: 24px;
            margin-bottom: 10px;
        }
        div.required label:not(.checkbox__label):after {
            color: #6E7577;
        }

        input.input__field {
            height: 50px;
            border-color: #CBCBCB;
            font-size: $font-size-medium-large;
        }

        textarea.input__field {
            border-color: #CBCBCB;
            min-height: 115px;
        }

        .field {
            margin-bottom: 35px;
            float: none;
            clear: both;
            position: relative;
            div.mage-error {
                position: absolute;
                left: 0;
                top: 100%;
            }
        }

        .block-to {
            @include mq($min-screen: $screen-m) {
                width: 50%;
                padding-right: 10px;
                float: left;
            }
        }

        .block-from {
            @include mq($max-screen: $screen-m - 1px) {
                margin-top: 24px;
            }
            @include mq($min-screen: $screen-m) {
                width: 50%;
                padding-left: 10px;
                float: right;
            }
        }

        .giftcard-qty {
            display: none;
        }

        .giftcard-amount {
            > .amount.options-list {
                margin: 0;
            }
        }

        .button-action {
            text-align: center;
            margin-top: 45px;

            @include mq($max-screen: $screen-m - 1px) {
                margin-top: 25px;
            }

            .button {
                background: $color-accent-secondary;
                width: 100%;
                text-align: center;
                color: #fff;
                font-weight: 700;
                font-size: $font-size-giftcard-button;
                line-height: 24px;
                padding: 10px;
                text-transform: uppercase;
                min-height: 50px;
                font-family: $font-family-base;

                &.ready-to-go {
                    background: $color-accent-primary;
                    &:hover {
                        background: $color-accent-secondary;
                        text-decoration: none;
                    }
                }
            }

            .add-cart-note {
                display: block;
                font-weight: 500;
                font-size: $font-size-medium-large;
                line-height: 24px;
                margin-top: 15px;
                color: #6E7577;
                font-family: $font-family-base;
                text-align: left;
            }
        }

        .gift-form-content {
            display: flex;
            flex-flow: row wrap;
            .field-select {
                margin-right: 15px;
                margin-bottom: 15px;

                @include mq($max-screen: $screen-m - 1px) {
                    margin: 5px;
                    width: calc(25% - 10px);
                }

                input[type="radio"],
                input[type="checkbox"] {
                    position: absolute;
                }
            }

            .open-amount {
                > label {
                    display: none;
                }

                input {
                    background: #fff;
                    border: 1px solid #CBCBCB;
                    color: #292F38;
                    font-weight: 500;
                    font-size: $font-size-medium-large;
                    line-height: 24px;
                    text-align: center;
                    padding: 12px 5px;
                    margin: 0;
                    width: 185px;
                    font-family: $font-family-base;

                    &:focus,
                    &:active {
                        outline: none;
                        box-shadow: none;
                    }

                    &::placeholder {
                        color: #292F38;
                    }
                }

                @include mq($max-screen: $screen-m - 1px) {
                    margin: 5px;
                    width: calc(50% - 10px);
                    input {
                        width: 100%;
                    }
                }

                .note {
                    margin-bottom: 0;

                    @include mq($max-screen: $screen-m - 1px) {
                        font-size: 12px;
                        text-align: center;
                    }

                    > span {
                        &:after {
                            content: '-';
                            margin: 0 5px;
                        }

                        &:last-child {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
            }

            input[type="radio"],
            input[type="checkbox"] {
                visibility: hidden;

                & + label {
                    background: #fff;
                    border: 1px solid #CBCBCB;
                    color: #292F38;
                    font-weight: 500;
                    font-size: $font-size-medium-large;
                    line-height: 24px;
                    text-align: center;
                    padding: 12px 5px;
                    margin: 0;
                    min-width: 85px;
                    cursor: pointer;

                    @include mq($max-screen: $screen-m - 1px) {
                        min-width: 100%;
                    }

                    &:hover {
                        background: $color-accent-primary;
                        color: #FFF;
                    }

                    &:after {
                        display: none;
                    }
                }

                &:checked {
                    & + label {
                        background: $color-accent-primary;
                        color: #FFF;
                    }
                }
            }

            @include mq($max-screen: $screen-m - 1px) {
                margin: 0 -5px;
            }
        }

    }

    .check-balance-content {
        .cart-discount {
            margin: 0 auto;
            max-width: 655px;
        }
        .cart-discount__content {
            #giftcard-form {
                width: 100%;
                position: relative;
                display: flex;
                flex-flow: row wrap;
                align-items: flex-start;
                justify-content: space-between;
            }

            #giftcard-balance-lookup {
                order: 3;
                width: 100%;
                @include mq($max-screen: $screen-m - 1px) {
                    text-align: center;
                    margin-top: 10px;
                }
            }

            .cart-discount__controls {
                padding: 0;
                width: 415px;
                order: 1;

                @include mq($max-screen: $screen-m - 1px) {
                    width: 100%;
                    float: none;
                }

                .cart-discount__content-input {
                    width: 100%;
                }
                input {
                    width: 100%;
                    height: 50px;
                    background: #FFFFFF;
                    border: 1px solid #CBCBCB;
                    font-weight: 500;
                    font-size: $font-size-medium-large;
                    line-height: 24px;
                    color: #87818E;

                    &:focus,
                    &:active {
                        outline: none;
                        box-shadow: none;
                    }

                    &::placeholder {
                        color: #87818E;
                        text-transform: capitalize;
                    }
                }
            }


            .cart-discount__content-button.action.check {
                width: 230px;
                font-weight: 700;
                font-size: $font-size-giftcard-button;
                text-align: center;
                text-transform: uppercase;
                color: #FFFFFF;
                padding: 14px;
                background: $color-accent-primary;
                position: relative;
                order: 2;
                min-height: 50px;

                @include mq($max-screen: $screen-m - 1px) {
                    width: 100%;
                    float: none;
                }

                &:hover {
                    background-color: $color-accent-secondary;
                    text-decoration: none;
                }
            }
        }
    }

}

.cms-social-offer {
    .cms-page__content {
        max-width: 100%;
    }
    .heading--page, .breadcrumbs {
        display: none;
    }
}

.page-layout-landing-cms-full-width {
    .breadcrumbs {
        display: none;
    }
    .landing-page-full-width {
        .section__heading {
            h2 {
                color: #326442;
                @include mq($max-screen: $screen-s) {
                    line-height: 25px;
                    &:before, &:after {
                        margin: 0;
                        flex: 0 0 auto;
                    }
                }
            }
        }
        figure {
            margin: 0;
        }
        .cms-page__content {
            max-width: 1358px;
            padding: 0 15px;
        }
        .top-banner {
            margin: 30px 0 60px;
            img {
                width: 100%;
                display: block;
            }
            @include mq($max-screen: $screen-m - 1) {
                margin: 10px 0 45px;
            }
        }
        .holiday-dinner {
            margin-bottom: 60px;
            @include mq($max-screen: $screen-m - 1) {
                margin-bottom: 45px;
            }
            .pagebuilder-column-line {
                .pagebuilder-column:nth-child(1) {
                    width: 34.2% !important;
                    img {
                        width: 100%;
                        display: block;
                    }
                }
                .pagebuilder-column:nth-child(2) {
                    width: 39.4% !important;
                    padding: 0 40px;
                }
                .pagebuilder-column:nth-child(3) {
                    width: 26.4% !important;
                    img {
                        width: 100%;
                        display: block;
                    }
                }
                @include mq($max-screen: $screen-l) {
                    flex-wrap: wrap;
                    justify-content: space-between;
                    .pagebuilder-column:nth-child(1) {
                        width: 48% !important;
                    }
                    .pagebuilder-column:nth-child(2) {
                        width: 100% !important;
                        order: 3;
                        padding: 15px 0;
                    }
                    .pagebuilder-column:nth-child(3) {
                        width: 48% !important;
                    }
                }
                @include mq($max-screen: $screen-m - 1) {
                    .pagebuilder-column:nth-child(1), .pagebuilder-column:nth-child(2), .pagebuilder-column:nth-child(3) {
                        margin-top: 15px;
                        width: 100% !important;
                        order: initial;
                    }
                }
            }
            .section__heading {
                margin-bottom: 45px;
                h2 {
                    margin: 0;
                }
                @include mq($max-screen: $screen-m - 1) {
                    margin-bottom: 20px;
                }
            }
            &-title-desktop {
                color: $green;
                font-size: 32px;
                line-height: 38px;
                margin-bottom: 5px;
                font-weight: 600;
                @include mq($max-screen: $screen-m - 1) {
                    display: none;
                }
            }
            &-title-mobile {
                color: $green;
                font-size: 22px;
                line-height: 26px;
                margin-bottom: 5px;
                font-weight: 600;
                @include mq($screen-m) {
                    display: none;
                }
            }
            &-tag-desktop {
                @include mq($max-screen: $screen-m - 1) {
                    display: none;
                }
            }
            &-tag-mobile {
                @include mq($screen-m) {
                    display: none;
                }
            }
            &-sku-desktop {
                p {
                    font-size: 18px;
                    line-height: 18px;
                    margin: 0 0 15px 0;
                }
                @include mq($max-screen: $screen-m - 1) {
                    display: none;
                }
            }
            &-sku-mobile {
                p {
                    font-size: 18px;
                    line-height: 18px;
                    margin: 0 0 10px 0;
                }
                @include mq($screen-m) {
                    display: none;
                }
            }
            &-content {
                margin-top: 15px;
                font-size: 18px;
                line-height: 25px;
                color: $gray-darkest;
                p {
                    font-size: 18px;
                    line-height: 25px;
                }
                @include mq($max-screen: $screen-m - 1) {
                    margin: 0;
                }
            }
            .product-view__form {
                padding: 0;
                .product-view__qty {
                    margin: 0;
                }
                .product-view__main-actions {
                    display: flex;
                    flex-flow: row nowrap;
                    .quantity-update {
                        max-width: 155px;
                        .quantity-update__button {
                            width: 42px;
                            min-width: auto;
                        }
                        .quantity-update__input {
                            @include mq($max-screen: $screen-m - 1) {
                                width: 56px;
                            }
                        }
                    }
                    .product-view__buttons-wrapper {
                        width: calc(100% - 170px);
                        margin-left: 15px;
                        max-width: 280px;
                        .product-view__add-to-cart {
                            font-size: 22px;
                            display: block;
                            .button__text {
                                display: block;
                            }
                        }
                        @include mq($max-screen: $screen-m - 1) {
                            width: calc(100% - 155px);
                            max-width: 100%;
                        }
                    }
                }
                &.active {
                    @include mq($max-screen: $screen-m - 1) {
                        background: #FFF;
                        height: 85px;
                        box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.10);
                        border: none;
                        position: fixed;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        z-index: 100000;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .product-view__main-actions {
                            padding: 0 15px;
                            width: 100%;
                        }
                    }
                }
            }
        }
        .holiday-slider {
            .section__heading {
                margin-bottom: 45px;
                @include mq($max-screen: $screen-m - 1) {
                    fmargin-bottom: 38px;
                }
            }
            .slider__item {
                padding: 0 17px;
                @include mq($max-screen: $screen-m - 1) {
                    padding: 0 7px;
                }
            }
            .slider__navigation {
                top: 42%;
                @include mq($max-screen: $screen-m - 1) {
                    display: block;
                    top: 38%;
                }
            }
            .slider__dots {
                display: none !important;
            }
            .slick-prev {
                @media screen and (min-width: 1350px) {
                    left: -20px;
                }
            }
            .slick-next {
                @media screen and (min-width: 1350px) {
                    right: -20px;
                }
            }
            .product-grid-item {
                &:hover, &:focus-within {
                    .product-grid-item__image-wrapper {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
            .product-grid-item__details {
                padding: 0;
                margin: 15px 0 0 0;
            }
            .product-grid-item__name {
                font-size: 20px;
                line-height: 25px;
                font-weight: 600;
                color: $gray-darkest;
                margin: 0 0 10px 0;
                min-height: auto;
                @include mq($max-screen: $screen-m - 1) {
                    font-size: 16px;
                    line-height: 20px;
                    margin: 0 0 5px 0;
                }
            }
            .product-grid-item__details-bottom {
                text-align: center;
                font-size: 16px;
                line-height: 20px;
                color: $gray-darkest;
                @include mq($max-screen: $screen-m - 1) {
                    font-size: 14px;
                }
            }
            &-text {
                text-align: center;
                margin-top: 60px;
                p {
                    color: $gray-darkest;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 10px;
                    @include mq($max-screen: $screen-m - 1) {
                        font-size: 14px;
                    }
                }
                @include mq($max-screen: $screen-m - 1) {
                    margin-top: 20px;
                }
            }
        }
    }
}