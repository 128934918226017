.account {
    .breadcrumbs {
        display: block;
    }
    .modal-container {
        display: none;
    }
    &-section {
        .account-section__title-label {
            display: none;
        }
    }
}

#dashboard-content {
    label.accept.addon:not(.checkbox__label) {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 60px;
            top: 13px;
            pointer-events: none;

            @include mq($max-screen: $screen-m - 1) {
                width: 14px;
                height: 14px;
                right: 4px;
                background-image: url('../images/icons/keyboard_arrow_down_mobile.svg');
            }

            @include mq($screen-m - 1) {
                width: 18px;
                height: 18px;
                background-image: url('../images/icons/keyboard_arrow_down.svg');
            }
        }
    }

    .amccpa-actions-toolbar {
        margin-top: 20px;
        margin-bottom: 35px;
    }
}
