.section {
    &__heading {
        background-color: none;

        .heading {
            color: $section__heading-color;
            font-family: $section__heading-font-family;
            font-weight: $section__heading-font-weight;
            font-size: $section__heading-font-size;
            text-transform: $section__heading-text-transform;
            line-height: $section__heading-line-height;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            @include mq($max-screen: $screen-m - 1){
                font-size: $section__heading-font-size\mobile;
                line-height: $section__heading-line-height\mobile;
            }

            &:before,
            &:after{
                content: "";
                display: block;
                width: $section__heading-background-width;
                height: $section__heading-background-height;
                background-size: 100% 100%;
                background-repeat: no-repeat;
            }

            &:before{
                background-image: $section__heading-background-before-url;
                background-position: right center;
                margin: 0 10px 0 0;
            }

            &:after{
                background-image: $section__heading-background-after-url;
                background-position: left center;
                margin: 0 0 0 10px;
            }

        }
    }
}
