$footer__social-list__icon-link-size\@large: 40px;

.footer {
    background-size: $footer-background-size;
    background-position: $footer-background-position;
    background-repeat: $footer-background-repeat;
    &.lazyloaded{
        background-image: $footer-background-image;
    }

    @include mq($max-screen: $screen-l - 1){
        background-size: $footer-background-size\tablet;
        &.lazyloaded{
            background-image: $footer-background-image\tablet;
        }
    }

    @include mq($max-screen: $screen-m - 1){
        background-size: $footer-background-size\mobile;
        &.lazyloaded{
            background-image: $footer-background-image\mobile;
        }
    }


    &__scroll-top {
        display: none;
        &:hover,
        &.focus-visible {
            background-color: $color-accent-secondary;
        }
    }

    &__handler {
        padding: 0;
    }

    &__newsletter {
        @include mq($screen-xl) {
            padding: 0;
            margin: 0;
        }
    }

    &__links {
        padding: $footer__links-padding;
        position: relative;

        @include mq($max-screen: $screen-l - 1){
            padding: $footer__links-padding\tablet;
        }

        @include mq($max-screen: $screen-m - 1){
            padding: $footer__links-padding\mobile;
            &:before{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                height: 50%;
                left: -15px;
                right: -15px;
                background-color: #f6f6f4;
            }
        }

        .dropdown-list {
            background: none;

            &__label {
                background: $footer__links-dropdown-background;
                font-family: $footer__links-dropdown-font-family;
                font-size: $footer__links-dropdown-font-size;
                font-weight: $footer__links-dropdown-font-weight;
                color: $footer__links-dropdown-color;
                line-height: $footer__links-dropdown-line-height;
                margin: $footer__links-dropdown-margin;
                padding: $footer__links-dropdown-padding;
                text-transform: $footer__links-dropdown-text-transform;

                @include mq($max-screen: $screen-l - 1){
                    font-size: $footer__links-dropdown-font-size\tablet;
                    line-height: $footer__links-dropdown-line-height\tablet;
                }

                @include mq($max-screen: $screen-m - 1){
                    margin: $footer__links-dropdown-margin\mobile;
                }

                .dropdown-list__icon{
                    width: 14px;
                    height: 14px;
                    fill: $gray-dark2;
                }

                .dropdown-list__icon-minus{
                    display: none;
                }
            }

            &__label[aria-expanded="true"]{

                .dropdown-list__icon{
                    display: none;
                }

                .dropdown-list__icon-minus{
                    display: block;
                }

            }

            &__list {
                border: none;
            }

            .list{
                &__item{
                    padding: 5px 0 0;
                    &:last-of-type{
                        padding-bottom: 0;
                    }
                    &:first-child{
                        padding: 0;
                    }
                }
            }
        }

        .list__item  {
            @include mq($screen-m) {
                padding-bottom: $spacer--semi-medium;
            }

            &:last-of-type {
                @include mq($screen-m) {
                    padding-bottom: $spacer;
                }
            }
        }
    }

    &__link{
       line-height:  $footer__link-line-height;
        &:hover{
            color: $footer__link-color-hover;
            text-decoration: none;
        }

        @include mq($max-screen: $screen-l - 1){
            font-size: $footer__link-font-size\tablet;
        }
    }

    &__links-list {
        @include mq($screen-m) {
            padding: 0;
        }
    }

    &__social-list {
        justify-content: flex-start;

        @include mq($screen-m) {
            order: 2;
        }

        .list__icon-link {
            min-width: $footer__social-list__icon-link-size;
            min-height: $footer__social-list__icon-link-size;
            background: none;
            border: 0;

            @include mq($screen-l) {
                min-width: $footer__social-list__icon-link-size\@large;
                min-height: $footer__social-list__icon-link-size\@large;
                width: $footer__social-list__icon-link-size\@large;
                height: $footer__social-list__icon-link-size\@large;
            }

            .icon {
                fill: $white;
            }
        }
    }

    &__bottom-bar-handler {
        padding: 0;
        min-height: inherit;
        align-items: flex-start;

        @include mq($screen-m) {
            padding: 0;
            display: flex;
            align-items: center;
            min-height: auto;
        }
    }

    &__copyright {
        text-align: center;

        @include mq($screen-l) {
            display: flex;
            align-items: center;
            padding: $spacer 0;
        }



        small {
            font-size: $font-size-medium;

            @include mq($screen-m) {
                font-size: $font-size-base;
            }
        }
    }

    &__top-bar {
        border: none;
        border-width: 0 0 $spacer--extra-small 0;
        width: 100%;
        margin-bottom: 0;
        padding-bottom: 0;

        @include mq($screen-xl) {
            display: flex;
            width: 100%;
        }
    }

    &__banner {
        display: none;

        @include mq($screen-m) {
            display: block;
            width: 100%;
        }

        @include mq($screen-xl) {
            flex-shrink: 0;
            width: 544px;
        }
    }

    &__banner-wrapper {
        position: relative;
    }

    &__banner-inner {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        width: 30%;
        height: 100%;
        color: $white;
        background: $color-accent-primary;

        @include mq($screen-xl) {
            width: 50%;
            justify-content: flex-start;
        }
    }

    &__banner-title {
        font-size: $font-size-extra-large;
        font-family: $font-family-secondary;
        margin: $spacer--medium 0;
        text-align: center;
    }

    &__banner-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__banner-button {
        width: 100%;
        max-width: 180px;
        margin: $spacer 0;
        padding: 0 $spacer--semi-medium;
        font-weight: $font-weight-bold;
        color: $white;
        background-color: transparent;
        border: 1px solid $white;

        &:hover,
        &:focus {
            text-decoration: none;
            color: $white;
        }
    }

    &-list-socials{

        @include mq($max-screen: $screen-m - 1){
            .dropdown-list__icon{
                display: none;
            }
            .dropdown-list__content[aria-hidden="true"]{
                display: block;
            }
        }

        .list{
            display: flex;
            align-items: center;
            @include mq($max-screen: $screen-m - 1){
                padding: 0;
                display: block;
            }
            .list__item{
                margin:$footer__links-social-margin;
                display: inline-block;
                font-size: 0;
                padding: 0;
                min-width: inherit;
                &:first-child{
                    margin: 0;
                }
            }
        }
        .footer__link{
            display: block;
            font-size: $footer__links-social-font-size;
            width: $footer__links-social-size;
            height: $footer__links-social-size;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &.lazyloaded{
            .facebook{
                .footer__link{
                    background-image: $footer__links-social-facebook-background;
                    &:hover{
                        background-image: $footer__links-social-facebook-background-hover;
                    }
                }
            }
            .instagram{
                .footer__link{
                    background-image: $footer__links-social-instagram-background;
                    &:hover{
                        background-image: $footer__links-social-instagram-background-hover;
                    }
                }
            }
            .pinterest{
                .footer__link{
                    background-image: $footer__links-social-pinterest-background;
                    &:hover{
                        background-image: $footer__links-social-pinterest-background-hover;
                    }
                }
            }
            .twitter{
                .footer__link{
                    background-image: $footer__links-social-twitter-background;
                    &:hover{
                        background-image: $footer__links-social-twitter-background-hover;
                    }
                }
            }
        }

    }

    &__bottom-bar{
        background: none;
        padding: 0;
        border-top: $footer__bottom-bar-border-top;
    }

    &__copyright{
        width: 100%;
        justify-content: center;
        font-family: $footer__copyright-font-family;
        font-size: $footer__copyright-font-size;
        font-weight: $footer__copyright-font-weight;
        color: $footer__copyright-color;
        line-height: $footer__copyright-line-height;
        padding: $footer__copyright-padding;

        @include mq($max-screen: $screen-l - 1){
            padding: 11px 0;
            small{
                font-size: $footer__copyright-font-size\tablet;
            }
        }

        @include mq($max-screen: $screen-m - 1){
            line-height: $footer__copyright-line-height\mobile;
        }
    }


}
