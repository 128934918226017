@import "recent-raves-variables";

.recent-raves-widget{
  padding: $recent-raves-widget-padding;
  margin: $recent-raves-widget-margin;
  &.margin-bottom-xs{
    margin-bottom: 0;
  }

  @include mq($max-screen: $screen-m - 1){
    margin-left: -15px;
    margin-right: -15px;
  }

}

.yopto-reviews-container{
  .reviews{
    &-grid{
      display: flex;
      justify-content: center;
      margin: 40px -8px 0;
      @include mq($max-screen: $screen-m - 1){
        margin: 20px -8px 0;
      }

      &.slick-initialized .slick-slide{
        display: block;

        &:not(.slick-current){
          opacity: 0.3;
        }
      }
    }
  }

  .review{
    &-item{
      text-align: center;
      position: relative;
      &:before{
        content: "";
        display: none;
        position: absolute;
        top: -7px;
        left: 0;
        right: 0;
        bottom: -7px;
        z-index: -1;
        box-shadow: $review-item-box-shadow;
      }

      @include mq($screen-l){
        &:hover{
          &:before{
            display: block;
          }
        }
      }

      @include mq($max-screen: $screen-m - 1){
        flex-basis: inherit;
        max-width: inherit;
      }
    }

    &-block-content{
      padding: $review-block-content-padding;
      @include mq($max-screen: $screen-m - 1){
        padding: 0;
      }
      .ruk_rating_snippet {
        i {
          font-size: 18px;
        }
      }
      .ruk-rating-snippet-count {
        display: none;
      }
    }

    &-image-link{
      display: block;
    }

    &-title{
      font-family: $review-title-font-family;
      font-size: $review-title-font-size;
      line-height: $review-title-line-height;
      color: $review-title-color;
      margin: $review-title-margin;

      @include mq($max-screen: $screen-l - 1){
        font-size: $review-title-font-size\tablet;
        font-weight: $review-title-font-weight\tablet;
      }

    }

    &-paragragh,
    &-user-info{
      font-family: $review-paragragh-font-family;
      font-size: $review-paragragh-font-size;
      line-height: $review-paragragh-line-height;
      color: $review-paragragh-color;
      margin: $review-paragragh-margin;

      @include mq($max-screen: $screen-l - 1){
        font-size: $review-paragragh-font-size\tablet;
        line-height: $review-paragragh-line-height\tablet;
        margin: $review-paragragh-margin\tablet;
        font-weight: $review-paragragh-font-weight\tablet;
        strong{
          font-weight: 600;
        }
      }

    }

      &-paragragh{
          /*
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
           */
      }

    &-product-name{
      font-family: $review-product-name-font-family;
      font-size: $review-product-name-font-size;
      font-weight: $review-product-name-font-weight;
      line-height: $review-product-name-line-height;
      color: $review-product-name-color;
      height: $review-product-name-height;
      margin: $review-product-name-margin;
      overflow: hidden;

      @include mq($max-screen: $screen-l - 1){
        font-size: $review-product-name-font-size\tablet;
        line-height: $review-product-name-line-height\tablet;
        height: $review-product-name-height\tablet;
        margin: $review-product-name-margin\tablet;
      }

    }


  }

  .standalone-bottomline{
    display: flex;
    justify-content: center;
  }
}


.yotpo.bottomLine .yotpo-bottomline .yotpo-icon-star,
.yotpo.bottomLine .yotpo-bottomline .yotpo-icon-half-star,
.yotpo.bottomLine .yotpo-bottomline .yotpo-icon-empty-star,
.yotpo.bottomline .yotpo-bottomline .yotpo-icon-star,
.yotpo.bottomline .yotpo-bottomline .yotpo-icon-half-star,
.yotpo.bottomline .yotpo-bottomline .yotpo-icon-empty-star,
.yotpo .yotpo-modal-bottom-line .yotpo-bottomline .yotpo-icon-star,
.yotpo .yotpo-modal-bottom-line .yotpo-bottomline .yotpo-icon-half-star,
.yotpo .yotpo-modal-bottom-line .yotpo-bottomline .yotpo-icon-empty-star{
  font-size: 19.5px !important;
  margin: 0 1.5px;
  color: $review-yotpo-color;
}
