$our-customers-come-first-padding: 100px 0 65px !important;
$our-customers-come-first-margin: 50px 0 0 !important;

$our-customers-come-background-image:  url('../images/home/cb-bg-our-customer-come-first.png');

$our-customers-list-margin: 30px 0 0;

$our-customer-paragragh-font-family: $font-family-base;
$our-customer-paragragh-font-size: 16px;
$our-customer-paragragh-font-color: $gray-light2;
$our-customer-paragragh-font-weight: $font-weight-normal;
$our-customer-paragragh-line-height: 22px;
$our-customer-title-text-align: center;
$our-customer-title-max-width: 665px;
$our-customer-title-margin: 0 auto;


//tablet

$our-customers-come-first-padding\tabelt: 25px 0 25px !important;
$our-customers-come-first-margin\tabelt: 35px 0 0 !important;

$our-customers-list-margin\tablet: 18px 0 0;

$our-customer-paragragh-font-size\tablet: 16px;
$our-customer-paragragh-line-height\tablet: 20px;
$our-customer-title-max-width\tablet: 430px;
$our-customer-paragragh-font-weight\tablet: 300;

//mobile
$our-customer-title-max-width\mobile: calc(100% - 100px);