@font-face {
    font-family: 'Avenir';
    src:
    url('../fonts/avenir-roman.woff2') format('woff2'),
    url('../fonts/avenir-roman.woff') format('woff'),
    url('../fonts/avenir-roman.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src:
    url('../fonts/avenir-medium.woff2') format('woff2'),
    url('../fonts/avenir-medium.woff') format('woff'),
    url('../fonts/avenir-medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir';
    src:
    url('../fonts/avenir-black.woff2') format('woff2'),
    url('../fonts/avenir-black.woff') format('woff'),
    url('../fonts/avenir-black.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    src:
    url('../fonts/adobe-garamond-pro-regular.woff2') format('woff2'),
    url('../fonts/adobe-garamond-pro-regular.woff') format('woff'),
    url('../fonts/adobe-garamond-pro-regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    src:
    url('../fonts/adobe-garamond-pro-regular-italic.woff2') format('woff2'),
    url('../fonts/adobe-garamond-pro-regular-italic.woff') format('woff'),
    url('../fonts/adobe-garamond-pro-regular-italic.ttf') format('ttf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    src:
    url('../fonts/adobe-garamond-pro-semibold.woff2') format('woff2'),
    url('../fonts/adobe-garamond-pro-semibold.woff') format('woff'),
    url('../fonts/adobe-garamond-pro-semibold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    src:
    url('../fonts/adobe-garamond-pro-semibold-italic.woff2') format('woff2'),
    url('../fonts/adobe-garamond-pro-semibold-italic.woff') format('woff'),
    url('../fonts/adobe-garamond-pro-semibold-italic.ttf') format('ttf');
    font-weight: 600;
    font-style: italic;
}
