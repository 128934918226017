$newsletter__title-font-size: 30px;

$newsletter__subtitle-font-size: 16px;

$newsletter__checkbox-label-font-size: 16px;

$newsletter__catalog-link-font-size: 16px;
$newsletter__catalog-link-color: $button-blue;
$newsletter__catalog-link-color-hover: $button-blue;


//tablet
$newsletter__subtitle-font-size\tablet: 16px;

$newsletter__checkbox-label-font-size\tablet: 16px;

$newsletter-button-font-size\tablet: 14px;

$newsletter__catalog-link-font-size\tablet: 13px;
$newsletter__catalog-link-line-height\tablet: 15px;

$newsletter__catalog-link-before-margin\tablet: 0;