.search-form {
    &__input {
        font-family: $header__search-form-input-font-family;
        font-size: $header__search-form-input-font-size;
        font-weight: $header__search-form-input-font-weight;
        color: $header__search-form-input-font-color;
        border-width: $header__search-form-input-border-width;
        padding: $header__search-form-input-padding;
        border-color: $header__search-border-color;

        @include mq($max-screen: $screen-l - 1) {
            height: $header__search-form-input-height-tablet;
            padding: $header__search-form-input-padding-tablet;
         //   border-width: $header__search-form-input-border-tablet;
            font-size: $header__search-form-input-font-size-tablet;
        }

        &::-webkit-input-placeholder {
            font-size: $header__search-form-input-font-size;
            color: $header__search-form-input-font-color;
        }
        &:-ms-input-placeholder {
            font-size: $header__search-form-input-font-size;
            color: $header__search-form-input-font-color;
        }
        &::placeholder {
            font-size: $header__search-form-input-font-size;
            color: $header__search-form-input-font-color;
        }

        &.focus-visible {
            outline: none;
          //  border-color: $color-primary;
        }
    }

    &__button {
        border: none;
        padding: 0;
        background: transparent;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        min-width: inherit;

        &:hover{
            background: none;
            .icon{
                fill: $gray-dark2;
            }
        }
        @include mq($max-screen: $screen-l - 1) {
            min-height: 40px;
            height: 40px;
            left: inherit;
        }
        .icon{
            fill: $gray-dark2;

            @include mq($max-screen: $screen-l - 1) {
                width: 20px;
                height: 20px;
            }
        }
    }
}
