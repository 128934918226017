$datepicker__today-background-color        : $white;
$datepicker__today-background-color--active: $black;
$datepicker__today-color                   : $black;
$datepicker__today-color--active           : $white;
$datepicker__disabled-background           : $calendar-disabled-background;
$datepicker__disabled-color                : $calendar-disabled-color;


$input__field-height: 50px;
$input__field-border-color: #CBCBCB;
$input__field-focus-visible-border-color: #292F38;

$input__field-font-family: $font-family-base;
$input__field-font-size: 20px;
$input__field-font-color: $gray-dark2;
$input__field-font-weight: $font-weight-normal;
$input__field-font-placeholder-color: $gray-light2;

//tablet
$input__field-height\tablet: 40px;
$input__field-font-size\tablet: 18px;