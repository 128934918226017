
$newsletter__padding: 55px 0;

$newsletter__controls-margin: 8px 0 5px !default;

$newsletter__form-container-padding: 0 50px 0 0 !default;

$newsletter__checkbox-label-color: $gray-light2;
$newsletter__checkbox-label-padding: 10px 0 0 32px;

$newsletter__checkbox-label-font-size         : 20px;
$newsletter__checkbox-label-link-color        : $gray-light2;
$newsletter__checkbox-label-link-color--hover : $color-accent-primary-dark;
$newsletter__checkbox-label-line-height       : 1.6;

$newsletter__form-content-width: 100%;
$newsletter__form-content-max-width: 535px;

$newsletter__input-width: calc(100% - 160px);

$newsletter__title-font-family: $font-family-secondary;
$newsletter__title-font-size: 30px;
$newsletter__title-font-weight: 600;
$newsletter__title-color: $gray-dark2;
$newsletter__title-line-height: 36px;
$newsletter__title-margin: 0;

$newsletter__subtitle-font-family: $font-family-base;
$newsletter__subtitle-font-size: 20px;
$newsletter__subtitle-font-weight: $font-weight-normal;
$newsletter__subtitle-color: $gray-light2;
$newsletter__subtitle-line-height: 22px;
$newsletter__subtitle-margin: 5px auto 0;

$newsletter-button-width: 155px;

$newsletter__checkbox-before-background: white !default;

$newsletter__checkbox-icon-fill : $gray-dark2;

$newsletter__catalog-content-width: 100%;
$newsletter__catalog-content-min-height: 206px;
$newsletter__catalog-content-padding: 22px;
$newsletter__catalog-content-text-align: center;
$newsletter__catalog-content-background-image: url('../images/footer-mackenzie/shop-the-catalog-bg.png');
$newsletter__catalog-content-background-size: 100% 100%;
$newsletter__catalog-content-background-repeat: no-repeat;

$newsletter__catalog-title-width: 100%;
$newsletter__catalog-title-font-family: $font-family-secondary;
$newsletter__catalog-title-font-size: 30px;
$newsletter__catalog-title-weight: 600;
$newsletter__catalog-title-color: $gray-dark2;
$newsletter__catalog-title-line-height: 36px;
$newsletter__catalog-title-margin: 0 0 20px;

$newsletter__catalog-link-font-family: $font-family-base;
$newsletter__catalog-link-font-size: 20px;
$newsletter__catalog-link-font-weight: bold;
$newsletter__catalog-link-color: $green2;
$newsletter__catalog-link-line-height: 22px;
$newsletter__catalog-link-text-transform: uppercase;
$newsletter__catalog-link-color-hover: $green2;


$newsletter__catalog-link-before-width: 14px;
$newsletter__catalog-link-before-height: 41px;
$newsletter__catalog-link-before-background-image: url('../images/footer-mackenzie/shop-the-catalog-flower.svg');
$newsletter__catalog-link-before-background-size: 100% 100%;
$newsletter__catalog-link-before-background-repeat: no-repeat;
$newsletter__catalog-link-before-margin: 0 18px;

//tablet
$newsletter__padding\tablet: 24px 0;

$newsletter__catalog-content-min-height\tablet: 145px;

$newsletter__title-font-size\tablet: 20px;
$newsletter__title-line-height\tablet: 24px;

$newsletter__subtitle-font-size\tablet: 18px;
$newsletter__subtitle-line-height\tablet: 20px;
$newsletter__subtitle-margin\tablet: 5px 0 0;

$newsletter__checkbox-label-font-size\tablet         : 18px;

$newsletter__catalog-title-font-size\tablet: 20px;
$newsletter__catalog-title-line-height\tablet: 24px;

$newsletter__catalog-link-font-size\tablet: 16px;
$newsletter__catalog-link-line-height\tablet: 17px;

$newsletter__catalog-link-before-margin\tablet: 0 10px;

$newsletter-button-width\tablet: 114px;
$newsletter-button-font-size\tablet: 18px;

$newsletter__input-width\tablet: calc(100% - 120px);

//mobile
$newsletter__form-content-max-width\mobile: inherit;


