@import "about-variables";

.about-cms-block{
  padding: 0 !important;


  @include mq($max-screen: $screen-l - 1){
    padding-top: 40px !important;
  }

  @include mq($max-screen: $screen-m - 1){
    padding-top: 10px !important;
  }

  .col-image-left{
    align-self: center !important;
    padding: 0 !important;
  }
  .col-content-right{
    align-self: center !important;

    @include mq($max-screen: $screen-m - 1){
      padding: 0 !important;
    }

  }

  .about-content-right{
    @include mq($screen-l){
      max-width: $about-content-right-max-width;
    }
  }


  .about-title{
    font-family: $about-title-font-family;
    font-size: $about-title-font-size;
    font-weight: $about-title-font-weight;
    color: $about-title-font-color;
    line-height: $about-title-line-line-height;

    @include mq($max-screen: $screen-l - 1){
      font-size: $about-title-font-size\tablet;
      line-height: $about-title-line-line-height\tablet;
      margin-bottom: 0;
    }

    @include mq($max-screen: $screen-m - 1){
      margin-top: 20px;
    }

  }

  .about-paragraph-medium{
    font-family: $about-paragraph-medium-font-family;
    font-size: $about-paragraph-medium-font-size;
    font-weight: $about-paragraph-medium-font-weight;
    color: $about-paragraph-medium-font-color;
    line-height: $about-paragraph-medium-line-height;
    margin: $about-paragraph-medium-margin;

    @include mq($max-screen: $screen-l - 1){
      font-size: $about-paragraph-medium-font-size\tablet;
      line-height: $about-paragraph-medium-line-height\tablet;
      margin: $about-paragraph-medium-margin\tablet;
    }

  }

  .about-paragraph-normal{
    font-family: $about-paragraph-normal-font-family;
    font-size: $about-paragraph-normal-font-size;
    font-weight: $about-paragraph-normal-font-weight;
    color: $about-paragraph-normal-font-color;
    line-height: $about-paragraph-normal-line-height;
    margin: $about-paragraph-normal-margin;

    @include mq($max-screen: $screen-l - 1){
      font-size: $about-paragraph-normal-font-size\tablet;
      line-height: $about-paragraph-normal-line-height\tablet;
      margin: $about-paragraph-normal-margin\tablet;
    }

  }

  .about-paragraph-bottom{
    font-family: $about-paragraph-bottom-font-family;
    font-size: $about-paragraph-bottom-font-size;
    font-weight: $about-paragraph-bottom-font-weight;
    color: $about-paragraph-bottom-font-color;
    font-style: $about-paragraph-bottom-font-style;
    line-height: $about-paragraph-bottom-line-height;
    margin: $about-paragraph-bottom-margin;

    @include mq($max-screen: $screen-l - 1){
      font-size: $about-paragraph-bottom-font-size\tablet;
      line-height: $about-paragraph-bottom-line-height\tablet;
      margin: $about-paragraph-bottom-margin\tablet;
    }

  }

  .about-image{
    margin: $about-image-margin;
    @include mq($max-screen: $screen-l - 1){
      margin: $about-image-margin\tablet;
    }
    img{
      width: auto;
      max-width: 340px;
    }
  }

}