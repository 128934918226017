@import 'minicart-product-variables';

.minicart-product {
    display: flex;
    width: 100%;
    flex-flow: column wrap;
    padding: $minicart-product__padding;

    &:last-child {
        border-bottom: none;
    }

    &__item {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .minicart-product-summary {
            align-self: end;
        }
    }

    &__image {

        @include mq($max-screen: $screen-m - 1) {
            flex-basis: 80px;
        }

        flex-shrink: 0;

        img {
            width: $minicart-product__image-width;
            max-width: $minicart-product__image-max-width;
            height: auto;

            @include mq($max-screen: $screen-m - 1){
                width: 80px;
            }
        }
    }

    &__image-link {
        display: flex;
        align-items: center;
    }

    &__data {
        display: flex;
        flex-flow: column wrap;
        flex-grow: 1;
        padding: $minicart-product__data-padding;
    }

    &__info {
        margin: $minicart-product__info-margin;

        @include mq($screen-m) {
            flex-grow: 1;
        }
    }

    &__name {
        margin-bottom: $minicart-product__spacer;
        line-height: $minicart-product__name-line-height;
        text-transform: $minicart-product__name-text-transform;

        .product-sku {
            color: #666;
            font-weight: 500;
            font-family: $font-family-base;

            @include mq($max-screen: $screen-m - 1) {
                font-size: 14px;
                font-family: 'Avenir';
            }

            @include mq($screen-m - 1) {
                font-size: 18px;
            }
        }
    }

    &__link {
        text-decoration: $minicart-product__link-text-decoration;
        font-weight: $minicart-product__link-font-weight;
        color: $minicart-product__link-color;
        line-height: 100%;

        @include mq($max-screen: $screen-m - 1) {
            font-size: 16px;
        }

        @include mq($screen-m - 1) {
            font-size: 24px;
        }
    }

    &__attributes {
        margin-bottom: $minicart-product__spacer;

        display: none; //hidden due to lack of design TO DO
    }

    &__attributes-button {
        display: flex;
        align-items: center;
        margin: $minicart-product__attributes-button-margin;
        padding: $minicart-product__attributes-button-padding;
        font-size: $minicart-product__attributes-button-font-size;

        .dropdown-list__icon {
            position: static;
            margin: $minicart-product__attributes-list-icon-margin;
            fill: $minicart-product__attributes-button-color;
        }

        &.dropdown-list__label {
            color: $minicart-product__attributes-button-color;
            background-color: $minicart-product__attributes-button-bg-color;

            &.focus-visible,
            &:hover {
                background-color: $minicart-product__attributes-button-bg-color;
            }
        }
    }

    &__attributes-list {
        padding: $minicart-product__attributes-list-padding;

        .list__label,
        .list__value {
            color: $minicart-product__attributes-color;
            font-size: $minicart-product__attributes-font-size;
            line-height: $minicart-product__attributes-line-height;
        }

        .list__label {
            padding: $minicart-product__attributes-label-padding;
            font-weight: $minicart-product__attributes-label-font-weight;
        }

        .list__value {
            padding: $minicart-product__attributes-value-padding;
        }
    }

    &__price {
        display: flex;
        align-items: $minicart-product__price-align;
        font-weight: $minicart-product__price-font-weight;

        @include mq($screen-m) {
            justify-content: flex-end;
            font-size: $minicart-product__price-font-size;
        }

        @include mq($max-screen: $screen-m - 1) {
            font-size: 16px;
        }

        .price__value {
            display: block;
            font-size: $minicart-product__price-font-size;

            @include mq($screen-m) {
                text-align: right;
            }

            &--old {
                font-size: $minicart-product__price-font-size--old;

                @include mq($screen-m) {
                    margin: 0;
                }
            }
        }
    }

    &__bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

    &__actions {
        display: flex;
    }

    &__qty {
        display: flex;

        .select-qty-wrapper {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                right: 9px;
                top: 9px;
                pointer-events: none;

                @include mq($max-screen: $screen-m - 1) {
                    width: 14px;
                    height: 14px;
                    right: 4px;
                    background-image: url('../images/icons/keyboard_arrow_down_mobile.svg');
                }

                @include mq($screen-m - 1) {
                    width: 18px;
                    height: 18px;
                    background-image: url('../images/icons/keyboard_arrow_down.svg');
                }
            }

            .cart-item-qty {
                -moz-appearance:none;
                -webkit-appearance:none;
                appearance:none;
                padding: 0 12px;
                text-align: left;
                border-radius: 4px;
                border: 0.5px solid #A9A9A9;
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.16);
                font-weight: 500;

                @include mq($max-screen: $screen-m - 1) {
                    font-size: 14px;
                    width: 75px;
                    height: 35px;
                }

                @include mq($screen-m - 1) {
                    font-size: 18px;
                    width: 90px;
                    height: 40px;
                }
            }
        }
    }

    &__name-wrapper {
        display: flex;
        flex-direction: column;

        @include mq($screen-m) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__update {
        max-height: $minicart-product__update-btn-max-height;
        padding: $minicart-product__update-btn-padding;

        @include mq($screen-m) {
            padding: $minicart-product__update-btn-padding\@medium;
        }
    }
}
