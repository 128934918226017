@import 'nutrition-variables';

.nutrition {
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: row;
    justify-content: center;
    gap: 15px;
    align-items: flex-start;

    &__image {
        width: 100%;
        height: auto;
        background-color: $gray-light;
        max-width: $nutrition-max-width;
    }

    @include mq($max-screen: $screen-m) {
        flex-flow: column;
    }
}
