$mega-menu-secondary__background-color      : #F2F7F9;
$mega-menu-secondary__link-color--hover     : $button-blue;
$mega-menu-secondary__list-background-color         : #F2F7F9;

$mega-menu__inner-link-color--hover: $button-blue;

$mega-menu__link-font-family                : $font-family-base;
$mega-menu__link-font-size                  : $font-size-medium;
$mega-menu__link-font-weight                : $font-weight-medium;
$mega-menu__link-text-transform             : none;

$mega-menu__link-font-family: $font-family-secondary;
$mega-menu__link-font-size: 20px;

$mega-menu-link-before-background: $button-blue;

//inner link
$mega-menu__inner-link-font-size            : $font-size-medium;
