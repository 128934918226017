@import 'store-switcher-variables';

.switch-store-links-block{
    background-color: $yellow-light2;
    box-shadow: 0 -2px 5px 0 rgba(0,0,0,0.3);
    .container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include mq($max-screen: $screen-l - 1) {
            justify-content: center;
        }
    }
}
.store-switcher {
    &__list {

        display: -webkit-box;
        display: flex;
        margin: 0;
        padding: 0;

    }

    &__item {
        display: flex;

        .icon {
            width: 179px;
            height: auto;

            @include mq($max-screen: $screen-xl - 1) {
                height: 50px;
            }

            @include mq($max-screen: $screen-m - 1) {
                height: 30px;
            }
        }
    }

    &__button--mackenzie {
        .icon {
            @include mq($screen-m) {
                height: 45px;
            }
        }
    }


    &__button {
        display: flex;
        align-items: center;
        min-width: 160px;
        height: 46px;
        justify-content: center;
        background-color: inherit;
        font-size: $font-size-medium;
        font-weight: $font-weight-medium;
        text-transform: uppercase;
        padding: 0;
        min-height: auto;
        border: $border-base;
        border-width: 0;
        border-top-color: transparent;

        @include mq($max-screen: $screen-l - 1) {
             width: 210px;
             min-width: inherit;
        }

        @include mq($max-screen: $screen-m - 1) {
            width: 120px;
            height: 30px;
        }

        @include mq($max-screen: $screen-xs) {
            width: 100px;
        }

        &:hover,
        &:focus {
            text-decoration: none;

            .icon {
                fill: $white;
            }
        }

        &--active {
            background-color: $white;
            border-bottom-color: transparent;
            position: relative;

            &:after{
                content: "";
                display: block;
                width: 100%;
                height: 5px;
                position: absolute;
                top: 100%;
                opacity: 1;
                left: 0;
                background-color: $white;
            }

            &.store-switcher__button {
                &--mackenzie {
                    border-top-color: $store-switcher__color;
                }

                &--chesapeake {
                    background-color: #f2f7f9;
                    border-top-color: $store-switcher__color--chesapeake;
                    &:after{
                        background-color: #f2f7f9;
                    }
                }
            }
        }

        &--mackenzie {
            &:hover,
            &:focus {
                background-color: $store-switcher__color;
            }

            .icon {
                fill: $store-switcher__color;
            }
        }

        &--chesapeake {
            &:hover,
            &:focus {
                background-color: $store-switcher__color--chesapeake;
            }

            .icon {
                fill: $store-switcher__color--chesapeake;
                @include mq($screen-l) {
                    height: 54px;
                }
            }
        }
    }
}
