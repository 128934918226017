$minicart-content__action-button-bg-color: $button-green;

$minicart-background-color: #FFF;

$input-discount-border-color: $button-blue;

$button-discount-bg-color: $button-blue;
$button-discount-bg-color-hover: $button-green;

$button-add-all-color: $button-blue;
$button-add-all-border-color: $button-blue;

$button-color-added: $button-blue;

$success-message-color: #3E6282;
