.cms-contact-us {
    .heading--page{
        &:after{
            height: 14px;
        }
        @include mq($max-screen: $screen-m - 1){
            &:after{
                bottom: -32px;
            }
        }
    }

    .select2-container{
        .select2-results__option{
            font-size: 16px;
        }
        .select2-selection--single .select2-selection__rendered{
            font-size: 16px;
        }
    }

    .contact-us__tips{
        font-size: 12px;
    }
    .p-message{
        @include mq($screen-l) {
            padding: 0 30px;
        }
        a{
            font-weight: 600;
        }
    }
    .webforms-success-text{
        font-size: 16px;
    }
}
.cms-block-contact-us ul li p a{
    font-weight: 600;
}
