.product-view {
    padding: $product-view__padding;

    @include mq($screen-xl) {
        padding: $product-view__padding\@xl;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        margin: $product-view__wrapper-margin;

        @include mq($screen-m) {
            display: block;
            position: relative;
        }

        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }

    &__details {
        position: relative;
        @include mq($screen-m) {
            float: right;
            width: $product-view__details-width\@medium;
            margin: $product-view__details-margin\@medium;
        }

        @include mq($screen-l) {
            width: $product-view__details-width\@large;
            margin: $product-view__details-margin\@large;
        }

        @include mq($screen-xl) {
            width: $product-view__details-width\@xl;
            margin: $product-view__details-margin\@xl;
            z-index: 47;
        }

        .product-view__short-description-wrapper{
            @include mq($screen-m) {
                display: none;
            }
        }
    }

    &__description {
        width: 100%;
    }

    &__short-description{
        line-height: 24px;

        @include mq($max-screen: $screen-m - 1) {
            font-size: $product-view__short-description-par-font-size\mobile;
            line-height: $product-view__short-description-par-line-height\mobile;
        }
        div[data-element="main"]{
            > div{
                margin: 0 !important;
                padding: 0 !important;
            }
        }
    }

    &__gallery {
        position: relative;
        margin: $product-view__gallery-margin;
        order: 1;

        @include mq($screen-m) {
            float: left;
            width: $product-view__gallery-width\@medium;
        }

        @include mq($screen-xl) {
            width: 57%;
        }

        .product-view__short-description-wrapper{

            @include mq($screen-m){
                margin-top: 30px;
            }

            @include mq($screen-xl){
                padding-left: 157px;
            }

            @include mq($max-screen: $screen-m - 1) {
                display: none;
            }
            .product-view__short-description-title {
                display: none;
            }
        }

    }

    &__badges {
        z-index: $product-view__badges-z-index;
        top: $product-view__badges-top;
        left: $product-view__badges-left;

        @include mq($screen-m) {
            top: $product-view__badges-top\@medium;
            left: $product-view__badges-left\@medium;
        }

        @include mq($screen-l) {
            top: $product-view__badges-top\@large;
            left: $product-view__badges-left\@large;
        }

        @include mq($screen-xl) {
            left: $product-view__badges-left\@xl;
        }
    }

    &__title {
        &.heading--page {
            color: $color-accent-primary;
            margin: 0 0 $spacer;
            text-transform: $product-view__title-text-transform;
            font-size: $product-view__title-font-size;
            font-weight: $product-view__title-font-weight;
            line-height: $product-view__title-line-height;

            @include mq($screen-m) {
                font-size: $product-view__title-font-size\@medium;
            }

            @include mq($screen-l) {
                font-size: $product-view__title-font-size\@large;
            }

            @include mq($max-screen: $screen-m - 1) {
                line-height: 28px;
            }
        }
    }

    &__price {
        .price__value,
        .price__value--special {
            font-size: $font-size-large;
        }

        .price__value--old {
            font-size: $font-size-base;
        }

        @include mq($product-view__price-display-mq) {
            .price__value,
            .price__value--special {
                font-size: $font-size-extra-large;
            }

            .price__value--old {
                font-size: $font-size-large;
            }
        }
    }

    &__grouped-table{

        ~ .product-view__main-actions{
            margin-top: 20px;
            .callout-icons-box{
                margin-bottom: 30px;
                ~ #product-addtocart-button{
                    @include mq($screen-m) {
                        margin-top: 0;
                    }
                }
            }

            #product-addtocart-button{
                @include mq($screen-m) {
                    margin-top: 15px;
                }
            }

        }
    }

    &__grouped-qty {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
    }

    &__grouped-product {
        border: $border-base;
        border-width: 0 0 $border-width-base;
        margin: 0 0 $spacer--semi-medium;
        &:last-child{
            margin-bottom: 0;
        }
    }

    &__grouped-product-info {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin: 0 0 $spacer;

        @include mq($screen-m) {
            flex-direction: row;
        }

        .price-box.price-final_price {
            @include mq($screen-m) {
                flex: 0 0 auto;
            }
        }

        .price__value {
            font-size: $font-size-large;

            &.price__value--old {
                @include mq($screen-m) {
                    font-size: $font-size-medium-large;
                }
            }

            @include mq($screen-m) {
                font-size: $font-size-extra-large;
            }
        }
    }

    &__grouped-product-name {
        font-size: $font-size-large;
        margin-right: $spacer--medium;
    }

    &__grouped-product-sku {
        display: inline-flex;
        font-size: $font-size-base;
        color: $gray-dark;
        margin: 0;
    }

    &__stock {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        color: $color-accent-primary;
    }

    &__sku {
        font-weight: $product-view__sku-font-weight;
        color: $product-view__sku-color;
        margin: 0 0 $spacer;
        display: block;
    }

    &__rating {
        flex-direction: row-reverse;
        align-items: center;
        > .yotpo-small{
            display: none;
        }
    }

    &__main-actions{
        position: relative;
    }

    // styles for related / upsells
    .slider--products {
        margin: $product-view__slider-margin;

        @include mq($max-screen: $screen-m - 1){
            margin: $product-view__slider-margin\mobile;
        }

        .slider__navigation{
            top:$product-view__slider-navigation-top;
        }
        .slider__prev{
            left: -18px;
        }
        .slider__next{
            right: -18px;
        }
    }

    .add-to-cart__wrapper {
        padding: $product-view__add-to-cart-ee-wrapper-padding;

        @include mq($screen-m) {
            padding: $product-view__add-to-cart-ee-wrapper-padding\@medium;
        }

        @include mq($screen-l) {
            padding: $product-view__add-to-cart-ee-wrapper-padding\@large;
        }
    }

    .add-to-cart__action  {
        position: absolute;
        bottom: $product-view__add-to-cart-ee-action-bottom;
        right: $product-view__add-to-cart-ee-action-right;

        @include mq($screen-m) {
            position: static;
        }
        @include mq($max-screen: $screen-m - 1) {
            .add-to-cart__button-more {
                display: none;
            }
        }
    }

    .price-box {
        display: flex;
        flex-direction: column;
        padding: $product-view__add-to-cart-ee-price-box-padding;

        @include mq($screen-m) {
            flex-direction: row;
            align-items: center;
        }

        @include mq($screen-l) {
            padding: $product-view__add-to-cart-ee-price-box-padding\@large;
        }
    }

}

.callout-icons-box{
     display: flex;
     flex-wrap: wrap;
    margin-bottom: 25px;
    width: 100%;

    @include mq($screen-xl) {
        margin-bottom: 40px;
    }

    @include mq($max-screen: $screen-m - 1) {
        margin-bottom: 15px;
        margin-top: 10px;
    }

     .callout-icons{
         flex: 1 60%;
         font-family: $font-family-secondary;
         font-size: 16px;
         color: $green2;
         position: relative;
         padding-left: 35px;
         line-height: 16px;
         margin-top: 15px;
         display: flex;
         align-items: center;
         min-height: 30px;
         @include mq($max-screen: $screen-m - 1) {
             flex: 1 50%;
             margin-top: 15px;
         }
         &:nth-child(odd) {
             flex: 1 40%;
             padding-right: 20px;
             @include mq($max-screen: $screen-m) {
                 flex: 1 50%;
             }
         }
     }
     .callout-icon{
         position: absolute;
         left: 0;
         top: 0;
         width: 30px;
         height: 30px;
         display: flex;
         justify-content: center;
         align-items: center;
         .icon{
             fill: $green2;
             width: 27px;
             height: 27px;
         }
     }
    .callout-text{
        display: flex;
        align-items: center;
    }
 }


.catalog-topper-wrapper {
    position: relative;
    z-index: -1;
    .catalog-topper {
        position: absolute;
        bottom: -90px;
        z-index: -1;
        overflow: hidden;
    }
}

.catalog-product-view {
    img[src*="sideqik"] {
        height: 1px;
    }
    .page-wrapper {
        margin-bottom: 0;
    }
    .footer {
        margin-top: 0;
    }
    .page-main .product-view .yotpo .yotpo-bottomline{
        .yotpo-icon-star,
        .yotpo-icon-empty-star,
        .yotpo-icon-half-star{
            color: $product-view__slider-yotpo-icon-star-color
        }
    }

    &:not(.page-product-grouped){
        .product-view__main-actions .product-view__buttons-wrapper{
            @include mq($screen-m){
                position: absolute;
                right: 0;
                bottom: 0;
                width: calc(100% - 210px);
            }
        }
    }
    @include mq($screen-xl) {
        .product-view {
            padding-bottom: 0;
        }
    }
    .ship-by-datemessage {
        color: #ff0000;
        font-weight: normal;
    }

    .listrak-recommendation-pdp {
        .product-grid-item {
            padding: 0 8px;
            &__name {
                margin-top: 10px;
            }
        }
        .slick-dots {
            display: flex;
            justify-content: center;
            position: relative;
            padding: 15px;
            list-style: none;
            li {
                display: inline-block;
                position: relative;
                margin: 0;
                padding: 0;
                button {
                    width: 11px;
                    height: 11px;
                    margin: 0 4px;
                    border: 2px solid #b6b8ba;
                    display: block;
                    border-radius: 50%;
                    font-size: 0;
                    padding: 0;
                }
                &.slick-active {
                    button {
                        background: #292f38;
                        border-color: #292f38;
                    }
                }
            }
        }

        @include mq($max-screen: $screen-m - 1) {
            .product-grid-item {
                &__price, &__actions {
                    width: 50%;
                    margin: 0;
                }
                .button--add-to {
                    min-width: 100%;
                    font-size: inherit;
                }
            }
        }
    }

    .page-main .product-view .ruk_rating_snippet {
        line-height: 15px;
        i {
            font-size: 18px;
            line-height: 18px;
            letter-spacing: 5px;
        }
        .ruk-rating-snippet-count {
            display: none;
        }
    }

    .ElementsWidget__list {
        .R-RatingStars .R-RatingStars__stars .stars__icon--100,
        .R-RatingStars .R-RatingStars__stars .stars__icon--75,
        .R-RatingStars .R-RatingStars__stars .stars__icon--50,
        .R-RatingStars .R-RatingStars__stars .stars__icon--25 {
            background-image: linear-gradient(to right,#4e5147 99.9%,#4e5147 100%);
        }
    }
}


.catalog-category-view {
    .catalog-list__sidebar {
        @include mq($screen-l) {
            min-width: 234px;
        }
        @include mq($screen-xl) {
            min-width: 294px;
        }
    }
}
