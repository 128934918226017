//
//  Default appearance styles
//  _____________________________________________

@include mq($max-screen: $screen-m - 1) {
    .pagebuilder-column-group {
        flex-wrap: wrap;
    }
    .pagebuilder-column-line {
        flex-wrap: wrap;
    }
}
