$tab__title-font-family           : $font-family-secondary;
$tab__title-text-transform        : none;
$tab__title-font-weight--active   : $font-weight-semibold;

$tab__content-font-size           : $font-size-medium;
$tab__content-padding             : $spacer--medium 0 $spacer--large;
$tab__content-padding\@medium     : $spacer--medium 104px $spacer--large;
$tab__content-padding\@large      : $spacer--medium 80px $spacer--large;
$tab__content-padding\@extra-large: $spacer--medium 80px $spacer--large;
$tab__content-line-height         : $font-line-height;
