$header__background                     : #f2f7f9 url('../images/header/background.jpg') center bottom;
$header__search-form-input-font-size : 16px;
$header__search-form-input-font-weight  : $font-weight-normal;

$search-form__button-right              : 1px;

$header-minicart-text-font-size : 16px;

$header-minicart-text-font-color-hover: $button-blue;

$header-button__counter-background: $button-blue;

$header-button__summary-count-font-size: 14px;

//tablet
$header__background\tablet                     : #FFF url('../images/header/cb-header-background-tablet.png') center top no-repeat;
$header__background-size\tablet: 100% 95px;
$header__background-repeat\tablet: no-repeat;

$header-minicart-text-font-tablet: 16px;

$header__search-form-input-font-size-tablet: 16px;

$header-button__summary-count-font-size-tablet: 13px;
$header-button__summary-count-font-weight-tablet:  $font-weight-normal;


//mobile
$header__background\moibile                     : #FFF url('../images/header/cb-header-background-mobile.png') center top no-repeat;
$header__background-size\mobile: 100% 76px;

//Header Sticky
$header-sticky-height-tablet: 162px;
$header-sticky-height-mobile: 135px;
