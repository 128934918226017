body{
    #popup-upsell-cart, #popup-upsell-cart-inside {
        background: $banner__bg-color;
        border-radius: 8px;
        padding: 0 16px;
        margin-bottom: 20px;

        .section__content {
            &--products {
                display: flex;
                flex-direction: column;
                gap: 16px;
                margin: 0;

                .upsell-popup-product-click {
                    .lazyload-wrapper {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}

.banner {
    &__button {
        &:hover,
        &.focus-visible {
            background-color: transparent;
        }
    }

    &__content {
        &:after {
            display: none;
        }
    }

    &__text {
        font-family: $banner__text-font-family;
    }

    &__icon {
        .icon {
            fill: $banner__icon-fill;
        }
    }

    &--category {
        &:not(.banner--category-image) {
            .banner__content--category {
                display: flex;
                font-family: $font-family-secondary;
                font-size: $font-size-large;
                max-width: 656px;
                margin: 0 auto;
                padding: $spacer--medium 0 $spacer--semi-medium;
                text-align: center;

                @include mq($max-screen: $screen-m - 1){
                    padding-bottom: 0;
                    [data-content-type=row][data-appearance=contained] [data-element=inner]{
                        margin: 0 !important;
                        padding: 0 !important;
                    }
                }

                .link {
                    font-family: $font-family-secondary;
                }
            }

            .banner__button-collapse,
            .banner__actions-wrapper {
                display: none;
            }

            .banner__heading {
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -#{$banner__heading-background-image-height};
                    width: $banner__heading-background-image-width;
                    height: $banner__heading-background-image-height;
                    margin: 0 auto;
                    background-image: $banner__heading-background-image;
                    background-repeat: no-repeat;
                }
            }
        }

        .heading {
            color: $color-accent-primary;
            font-weight: $font-weight-normal;
        }
    }


    .details{
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        &.align-content_left{
            text-align: left;
        }
        &.align-content_right{
            text-align: right;
        }
        &.align-content_middle{
            text-align: center;
        }

        .container{
            padding: $banner-detail-container-padding;

            @include mq($max-screen: $screen-l - 1){
                padding: $banner-detail-container-padding\tablet;
            }

            @include mq($max-screen: $screen-m - 1){
                padding: $banner-detail-container-padding\mobile;
            }
        }

        @include mq($max-screen: $screen-m - 1){
            text-align: center !important;
        }

    }

    &-slider-title{
        font-family: $banner-slider-title-font-family;
        font-size: $banner-slider-title-font-size;
        font-weight: $banner-slider-title-font-weight;
        color: $banner-slider-title-color;
        line-height: $banner-slider-title-line-height;
        text-shadow: $banner-slider-title-text-shadow;

        @include mq($max-screen: $screen-l - 1){
            font-size: $banner-slider-title-font-size\tablet;
            line-height: $banner-slider-title-line-height\tablet;
        }
    }

    &-slider-description{
        p{
            font-family: $banner-slider-description-font-family;
            font-size: $banner-slider-description-font-size;
            font-weight: $banner-slider-description-font-weight;
            color: $banner-slider-description-color;
            line-height: $banner-slider-description-line-height;
            text-shadow: $banner-slider-description-text-shadow;
            margin: $banner-slider-description-margin;

            @include mq($max-screen: $screen-l - 1){
                font-size: $banner-slider-description-font-size\tablet;
                line-height: $banner-slider-description-line-height\tablet;
                margin: $banner-slider-description-margin\tablet;
            }
        }

    }

    &-slider-link{
        .a-link{
            display: inline-block;
            font-family: $banner-slider-link-font-family;
            font-size: $banner-slider-link-font-size;
            font-weight: $banner-slider-link-font-weight;
            color: $banner-slider-link-color;
            line-height: $banner-slider-link-line-height;
            text-transform: $banner-slider-link-text;
            text-shadow: $banner-slider-link-text-shadow;
            margin: $banner-slider-link-margin;
            padding: $banner-slider-link-padding;
            border: $banner-slider-link-border;
            &:hover{
                background-color: $banner-slider-link-background-hover;
                border-color: $banner-slider-link-border-color;
                text-decoration: none;
            }

            @include mq($max-screen: $screen-l - 1){
                font-size: $banner-slider-link-font-size\tablet;
                line-height: $banner-slider-link-line-height\tablet;
                padding: $banner-slider-link-padding\tablet;
                margin: $banner-slider-link-margin\tablet;
            }

            @include mq($max-screen: $screen-m - 1){
                padding: $banner-slider-link-padding\mobile;
            }

        }
    }


}
