@import "slick-buttons-dots-variables";

.slick-arrow.slick-hidden {
  display: none;
}



.slick-prev,
.slick-next {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0;
  line-height: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 101;
  border-radius: 50%;
  border: none;
  width: $button-slick-width;
  height: $button-slick-height;
  background: $button-slick-background;

  &.slick-disabled {
   // opacity: 0.2;
  }

  &:hover {
    background: $button-slick-background-hover;

    &:before {
      background: $button-slick-before-background-hover;
    }

    &:after{
      border-color: $button-slick-arrow-color-hover;
    }
  }

  &:before{
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    width: $button-slick-before-width;
    height: $button-slick-before-height;
    background: $button-slick-before-background;
    opacity: $button-slick-before-opacity;
  }


  &:after {
    content: "";
    border: solid $button-slick-arrow-color;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 7px;
    position: relative;
  }

  &:active,
  &:focus,
  &:not(.primary) {

    &:active {

    }
  }

  @media screen and (max-width: 1023px) {
    width: $button-slick-width\tablet;
    height: $button-slick-height\tablet;

    &:before{
      width: $button-slick-before-width\tablet;
      height: $button-slick-before-height\tablet;
    }

    &:after{
      padding: 4px;
    }
  }

}

.slick-prev {
  left: 0;

  &:after{
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    left: 3px;
  }
}

.slick-next {
  right: 0;

  &:after{
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    right: 3px;
  }

}