$container__max-width     : $max-content-width !default;
$container__margin        : $spacer !default;
$container__margin\@medium: $spacer !default;
$container__margin\@large : $spacer--semi-large !default;

.container {

    @include mq($max-screen: $screen-l - 1) {
        margin: 0 15px;
    }

}
