@import 'order-summary-variables';

.order-summary {

    &__title {
        margin: $order-summary__title-margin;
        font-family: $order-summary__title-font-family;
        text-transform: $order-summary__title-text-transform;

        @include mq($max-screen: $screen-l){
            font-size: $order-summary__title-font-size-mobile;
            font-weight: $order-summary__title-font-weight-mobile;
        }

        @include mq($screen-l){
            font-size: $order-summary__title-font-size;
            font-weight: $order-summary__title-font-weight;
        }
    }

    &__title-total {
        margin: $order-summary__title-total-margin;
        font-family: $order-summary__title-total-font-family;
        font-size: $order-summary__title-total-font-size;
        font-weight: $order-summary__title-total-font-weight;
        text-transform: $order-summary__title-total-text-transform;
    }

    &__amount {
        margin: $order-summary__amount-margin;
        font-family: $order-summary__amount-font-family;
        font-size: $order-summary__amount-font-size;
        font-weight: $order-summary__amount-font-weight;
        text-transform: $order-summary__amount-text-transform;

        @include mq($max-screen: $screen-m){
            color: #666;
            font-weight: 500;
        }
    }

    &__subtitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: $order-summary__subtitle-margin;
        font-family: $order-summary__subtitle-font-family;
        font-size: $order-summary__subtitle-font-size;
        font-weight: $order-summary__subtitle-font-weight;
        text-transform: $order-summary__subtitle-text-transform;
        cursor: pointer;
    }


    &__list {
        margin: $order-summary__list-margin;
        padding: $order-summary__list-padding;
        border-width: $order-summary__list-border-width;
        list-style: $order-summary__list-style;

        @include mq($screen-l){
            border-top: $order-summary__list-border;
        }
    }

    &__list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;

        &--secondary {
            justify-content: flex-start;
        }

        @include mq($max-screen: $screen-m - 1){
            margin-top: 10px;
        }

        &.item_count_in_cart{
            display: none;
        }
    }

    &__label,
    &__value {
        font-size: $order-summary__label-font-size;
        @include mq($max-screen: $screen-m - 1){
            font-size: 18px;
            line-height: 24px;
        }
    }

    &__value {
        &.price{
            color: $color-accent-primary;
            font-weight: bold;
        }
        @include mq($max-screen: $screen-m - 1){
            font-weight: 500;
            color: #666;
            &.price{
                color: $black;
            }
        }
    }

    &__link {
        padding: $order-summary__link-padding;
        font-weight: $order-summary__link-font-weight;
    }

    &__divider {
        display: none;
    }

    &__list-column {
        display: flex;
        flex-direction: column;
    }

    .active {
        .shipping-estimation__title-icon {
            transform: rotate(180deg);
        }
    }

    &__tab {
        &.active {
            .order-summary__tab-icon {
                transform: rotate(180deg);
            }
        }
    }

    &__tab-title {
        display: block;
        padding-top: $spacer--medium;
        border-top: 0.5px solid $boder-color;
        cursor: pointer;
        margin-top: $spacer;
        @include mq($max-screen: $screen-l - 1) {
            border-top: none;
            margin-top: 0;
        }
    }

    &__tab-text {
        @include mq($max-screen: $screen-l - 1) {
            color: $black;
            font-size: $font-size-large;
        }
    }

    &__tab-value {
        @include mq($max-screen: $screen-m - 1) {
            color: $black;
            font-size: $font-size-large;
        }
    }

    &__tab-icon {
        width: $order-summary__tab-icon-width;
        height: $order-summary__tab-icon-height;
        transition: $order-summary__tab-icon-transition;
        position: absolute;
        top: 0;
        right: 0;
    }

    &__products {
        display: $order-summary__products-display;
        @include mq($max-screen: $screen-l - 1) {
            display: block !important;
            border-top: 1px solid $boder-color-mobile;
            padding: 0 $spacer--small;
            max-height: 34vh;
            overflow-y: auto;
        }
    }

    &__product-item {
        display: flex;
        padding: $order-summary__product-item-padding;
        @include mq($max-screen: $screen-l - 1) {
            border-bottom: 1px solid $boder-color-mobile;
            padding: $spacer--medium 0;
        }
        .product-image-container {
            margin-right: $order-summary__product-image-margin-right;
        }
    }

    &__product-image {
        height: $order-summary__product-image-height;
        width: $order-summary__product-image-width;
        max-width: $order-summary__product-image-max-width;
        border-radius: 2px;
    }

    &__product-info {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: $order-summary__product-info-width;
        height: 80px;

        .subtotal {
            display: flex;
            align-items: flex-end;
            font-size: $font-size-medium;
            > span {
                line-height: 1;
                font-weight: 600;
                color: $black;
            }
        }
    }

    &__product-wrapper {
        width: $order-summary__product-wrapper-width;
    }

    &__product-details {
        padding-right: $order-summary__product-details-padding-right;
        display: flex;
        flex-flow: row wrap;
    }

    &__product-name {
        color: $black;
        font-family: $font-family-base;
        font-size: $font-size-medium;
        font-weight: 600;
        letter-spacing: 0;
        line-height: $font-size-medium;
        width: 100%;
    }

    &__product-qty {
        color: $order-summary__product-qty-color;
        font-size: $font-size-large;
        letter-spacing: 0;
        line-height: $font-size-large;
        display: flex;
        align-items: flex-end;
        span {
            font-weight: 600;
        }
    }
    &__product-sku {
        font-family: 'Avenir';
        color: $checkout-body-text;
        font-size: 14px;
        line-height: 14px;
        width: 100%;
        margin-top: -10px;
    }
    &__product-edit {
        display: flex;
        align-items: flex-end;
        padding-left: 5px;
        .action {
            line-height: $font-size-large;
            font-size: $font-size-large;
            color: $black;
            text-decoration: underline;
        }
    }

    &__product-price {
        font-size: $order-summary__product-price-font-size;
    }
    .preorder_note{
        display: none;
    }

    #li-block-giftcard{
        @include mq($screen-m){
            padding-bottom: 25px;
            margin-bottom: 20px;
            border-bottom: 1px solid #d8d8d8;
            display: none;
        }
    }

    #li-block-discount{
        @include mq($screen-m){

            .cart-discount__controls{
                padding-bottom: 10px;
            }

        }
    }
}


