@import 'side-menu-variables';

.side-menu {
    @include mq($side-menu__bq-visibility-max) {
        display: none;
    }

    &__trigger {
        background-color: $side-menu__trigger-bg-color;

        @include mq($screen-m) {
            border: $side-menu__trigger-border\@medium;
            border-width: $side-menu__trigger-border-width\@medium;
            padding: $side-menu__trigger-padding\@medium;
            margin: $side-menu__trigger-margin\@medium;
        }
    }

    &__modal {
        background: $side-menu__bg-color;
    }


    &__container {
        margin-top: $side-menu__modal-container-top;
        max-width: $side-menu__modal-container-max-width;
        box-shadow: $side-menu__modal-container-box-shadow;
        padding: $side-menu__modal-container-padding;
        max-height: $side-menu__modal-container-max-height;

        @include mq($screen-m) {
            max-height: $side-menu__modal-container-max-height\@medium;
            margin-top: $side-menu__modal-container-top\@medium;
        }
    }

    &__content {
        max-width: $side-menu__modal-content-max-width;
        margin: $side-menu__modal-content-margin;
        overflow-y: auto;
        @include hide-scrollbar();
    }

    &__content-middle {
        padding: $side-menu__modal-middle-padding;
        overflow: visible;
    }

    &__content-bottom {
        padding: $side-menu__modal-bottom-padding;
        border: $side-menu__modal-bottom-border;
    }

    &__bottom-actions {
        max-width: $side-menu__modal-bottom-actions-max-width;
        margin: $side-menu__modal-bottom-actions-margin;
    }

    &__bottom-action {
        width: $side-menu__modal-bottom-action-width;
        flex-basis: $side-menu__modal-bottom-action-width;
    }

    &__close-button {
        top: $side-menu__modal-close-button-top;
        right: $side-menu__modal-close-button-right;
        left: $side-menu__modal-close-button-left;
        background: $side-menu__modal-close-button-bg;

        @include mq($screen-m) {
            top: $side-menu__modal-close-button-top\@medium;
            left: $side-menu__modal-close-button-left\@medium;
        }
    }

    &__close-button-text {
        z-index: $side-menu__modal-close-button-text-z-index;
        margin: $side-menu__modal-close-button-text-margin;
        color: $side-menu__modal-close-button-text-color;
        font-family: $side-menu__modal-close-button-text-font-family;
        font-size: $side-menu__modal-close-button-text-font-size;
        font-weight: $side-menu__modal-close-button-text-font-weight;
    }
}
