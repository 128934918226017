.header {
    background-repeat: no-repeat;

    @include mq($max-screen: $screen-l - 1) {
        background: $header__background\tablet;
        background-size: $header__background-size\tablet;
    }


    @include mq($max-screen: $screen-m - 1) {
        background: $header__background\moibile;
        background-size: $header__background-size\mobile;
    }

    &__wrapper {
        @include mq($max-screen: $screen-l - 1) {
            padding-bottom: 84px;
            .header__search-wrapper{
                bottom: 12px;
            }
        }
        @include mq($max-screen: $screen-m - 1) {
            padding-bottom: 60px;
            .header__search-wrapper{
                bottom: 8px;
            }
        }
    }

    &__logo {
        @include mq($screen-l){
           max-width: 18vw;
        }
        @include mq($screen-xl + 140){
           max-width: 352px;
        }
    }

    .search-form__input{

        @include mq($screen-l, $screen-xl ) {
            &::-webkit-input-placeholder {
                font-size: 14px;
            }
            &:-ms-input-placeholder {
                font-size: 14px;
            }
            &::placeholder {
                font-size: 14px;
            }
        }
        @include mq($max-screen: $screen-m - 1) {
            &::-webkit-input-placeholder {
                font-size: 14px;
            }
            &:-ms-input-placeholder {
                font-size: 14px;
            }
            &::placeholder {
                font-size: 14px;
            }
        }
    }

    &--checkout {
        background: #ffffff;
        .header__logo {
            @include mq($screen-l) {
                .logo__image{
                    height: auto;
                    width: 185px;
                }
            }
            @include mq($max-screen: $screen-m - 1) {
                .logo__image{
                    height: 45px;
                }
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    body#html-body iframe[title="iAdvize chat notification frame"][style*="position: fixed"]{
        bottom: 105px !important;
    }

    body#html-body iframe#chatboxreduced-iframe {
        bottom: 105px !important;
    }

    body#html-body .uwy  .userway_buttons_wrapper {
        bottom: 150px !important;
        top: unset !important;
        right: 0  !important;

    }
}
