$button__background                  : $color-accent-primary;
$button__background-hover            : $color-accent-secondary;

$button__text-color                  : $gray-lighter;

$button__before-display              : none;
$button__before-display-hover        : none;

$button__after-display               : none;
$button__after-display-hover         : none;

// Secondary
$button__color--secondary            : $color-accent-primary;
$button__background-hover--secondary : $color-accent-primary;

// Button icon
$button__background-hover--icon      : $color-accent-primary;

// Button rotate-icon
$button__icon-fill-hover--rotate-icon: $white;

// Button Link hover
$button__color-hover--link           : $color-accent-primary;


$button__min-size                               : 50px !default;

$button__font-size : 20px;

//tablet
$button__min-size\tablet                        : 40px !default;

$button__background-hover--add-to               : $color-accent-secondary;
$button__after-display--add-to                  : none;
$button__after-display--add-to\@large           : none;
$button__before-display--add-to                 : none;
$button__before-display--add-to\@large          : none;
$button__before-display-hover--add-to           : none;
$button__before-display-hover--add-to\@large    : none;
$button__after-display-hover--add-to            : none;
$button__after-display-hover--add-to\@large     : none;