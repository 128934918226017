.quicksearch {
    &__close-button {
        &:hover,
        &.focus-visible {
            .quicksearch__close-icon {
                fill: $color-primary;
            }
        }
    }

    &__more-link {
        &:hover,
        &.focus-visible {
            color: $gray-lighter;
        }
    }
}
