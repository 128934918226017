.header {
  border: none;
    &__minicart {
        align-items: center;
        display: flex;

        .header-button {
           padding: 0;
            margin: 0;
            min-width: inherit;
          background: transparent;

            &__icon {
                fill: $header__buttons-color;
                width: 32px;
                height: 30px;
              @include mq($max-screen: $screen-m - 1) {
                width: 25px;
                height: 23px;
              }
            }

            &:hover,
            &.focus-visible {

                .header-minicart-text{
                  color: $header-minicart-text-font-color-hover;
                }

                .header-button__icon {
                  fill: $header-minicart-text-font-color-hover;
                }
            }
        }
    }

    &__wrapper {
        justify-content: flex-end;

        @include mq($screen-l) {
            min-height: 106px;
            align-items: center;
        }

        @include mq($max-screen: $screen-l - 1) {
          flex-wrap: wrap;
          position: relative;
          padding-bottom: 70px;
          align-items: center;
        }

        @include mq($max-screen: $screen-m - 1) {
          padding-bottom: 52px;
          justify-content: center;
          align-items: center;
        }
    }

    &__logo {
        @include mq($screen-l) {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0;
            justify-content: center;
        }

        @include mq($screen-l){
           max-width: 18vw;
        }
        @include mq($screen-xl + 80){
           max-width: 260px;
        }

        @include mq($max-screen: $screen-l - 1) {
          max-width: inherit;
          justify-content: left;
          margin-left: 45px;
        }

        @include mq($max-screen: $screen-m - 1) {
          flex: inherit;
          order: 1;
          margin:0;
          padding: 14px 0;
        }
    }

    &__buttons {
        flex: 0 1 auto;
        order: 4;
      @include mq($screen-l) {
        max-width: 122px;
      }
      @include mq($max-screen: $screen-m - 1) {
        position: absolute;
        right: 0;
      }
    }

    &__newsletter {
        position: absolute;
        left: 0;
        display: none;
        padding: 0 $spacer;
        overflow: hidden;

        @include mq($screen-l) {
            display: flex;
        }
    }

    &--checkout {
        background: #ffffff;
        .header__newsletter {
            display: none;
        }

        .header__wrapper {
          @include mq($screen-m) {
            padding: 24px 0;
          }
          @include mq($max-screen: $screen-m - 1) {
            padding: 8px 0;
          }
        }

        .header__logo {
            position: absolute;
            left: 0;
            right: auto;
            margin: 0 auto;
            @include mq($screen-l) {
                .logo__image{
                    height:auto;
                    width: 257px;
                }
            }
            @include mq($max-screen: $screen-m - 1) {
              .logo__image{
                height: 28px;
              }
            }
        }
    }

    &-minicart-text{
      font-family: $header-minicart-text-font-family;
      font-weight: $header-minicart-text-font-weight;
      text-transform: $header-minicart-text-transform;
      font-size: $header-minicart-text-font-size;
      color: $header-minicart-text-font-color;
      margin: $header-minicart-text-margin;


      @include mq($max-screen: $screen-l - 1) {
        font-size: $header-minicart-text-font-tablet;
      }

      @include mq($max-screen: $screen-m - 1) {
        display: none;
      }

    }

    &-button__counter-dot{
        display: none;
    }

    &-button__counter{
      position: $header-button__counter-position;
      top: $header-button__counter-top;
      right: $header-button__counter-right;
      width: $header-button__counter-width;
      height: $header-button__counter-height;
      border-radius: $header-button__counter-radius;
      background: $header-button__counter-background;
      margin: $header-button__counter-margin;

      @include mq($max-screen: $screen-m - 1) {
        width: $header-button__counter-width-mobile;
        height: $header-button__counter-height-mobile;
      }
    }

    &-button__summary-count{
      font-size: $header-button__summary-count-font-size;
      font-weight: $header-button__summary-count-font-weight;
      position: relative;
      width: auto;
      height: auto;
      margin: 0;
      padding: 0;
      overflow: visible;

      @include mq($max-screen: $screen-l - 1) {
        font-size: $header-button__summary-count-font-size-tablet;
        font-weight: $header-button__summary-count-font-weight-tablet;
      }

    }

    &__search-wrapper{
      display: flex;
      flex-flow: column nowrap;
      flex: 1 50%;
      order: $header__search-wrapper-order;
      justify-content: center;
      padding: $header__search-wrapper-padding;
      z-index: $header__search-wrapper-z-index;

      @include mq($screen-m) {
        flex-direction: column;
        justify-content: center;
        padding: $header__search-wrapper-padding\@medium;
      }

      @include mq($screen-l) {
        position: relative;
        left: 0;
        transition: all 0.2s linear;
        width: $header__search-wrapper-width\@large;
        max-width: $header__search-wrapper-max-width\@large;
        order: 1;
        &.focus{
          width: $header__search-wrapper-width-focus\@large;
        }
      }

      @include mq($screen-l, $screen-xl) {
        max-width: $header__search-wrapper-max-width\@extra-large;
      }

      .search-form__input{
          border-width: 2px;
          font-size: 18px;
          padding: 0 44px 0 15px;
          border-color: #7E7E7F;
          background: #F9F9F9;
          border-radius: 0!important;
          -webkit-appearance: none;
      }

      @include mq($screen-l) {
        .search-form__input{
          height: 55px;

          &:focus{
          /*  border-color: #d8d8d8;*/
          }
      }
      .search-form__button{
        height: 38px;
        min-height: 38px;
      }
    }

      @include mq($screen-xl){
          .search-form__input{
              padding: 0 44px 0 28px;
          }
      }

      @include mq($max-screen: $screen-l - 1) {
        flex: 1 100%;
        order: 5;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 12px;
        padding: 0;
      /*  border-top: 1px solid #EDEBE5;
        border-bottom: 1px solid #EDEBE5;*/
    }

      @include mq($max-screen: $screen-m - 1) {
        bottom: 8px;
      }

      #klevuSearchingArea, div.klevu-fluid .klevu-pt-rs-hover {
        width: 100% !important;
        @include mq($screen-l) {
          top: 55px !important;
          left: 8px !important;
        }
        @include mq($max-screen: $screen-l - 1) {
          top: 42px !important;
          left: 0px !important;
        }
      }

    }
}

.page-print.account {
  .header__logo {
    position: relative;
    padding: 20px 0;
  }
}
@media only screen and (max-width: 768px) {
    body#html-body iframe[title="iAdvize chat notification frame"][style*="position: fixed"]{
        bottom: 105px !important;
    }

    body#html-body iframe#chatboxreduced-iframe {
        bottom: 105px !important;
    }

    body#html-body .uwy  .userway_buttons_wrapper {
        bottom: 150px !important;
        top: unset !important;
        right: 0  !important;

    }
}
