.product-grid-item {
    // styles for safari
    &:not(:root:root) {
        //  display: -webkit-box; // sass-lint:disable-line no-vendor-prefixes
    }
    &__primary-action {
        .button--add-to {
            @include mq($screen-m) {
                min-width: 120px;
            }

            @include mq($screen-l) {
                padding: 0 $spacer--medium;
            }

            @include mq($screen-xl) {
                min-width: 128px;
            }
        }
    }

    &--no-slider {
        .product-grid-item__image-wrapper {
            max-height: 384px;
            overflow: hidden;

            img {
                width: 100%;
            }
        }
    }

    &--slider-item {
        .product-grid-item__link {
            flex: 1 0 50%;
        }
    }

    .minimal-price {
        margin: 0;
        display: flex;
        flex-direction: column;

        .price {
            @extend .price__value;
        }
    }
    &__actions {
        justify-content: center;
        width: auto;
    }

    &__price {
        width: auto;
        margin-bottom: 0;
        text-align: left;
        color: #121212;

        .price__value--old {
            margin: 0;
            line-height: initial;
        }
        .price-label {
            line-height: initial;
        }
    }

    &__link {
        overflow: hidden;

        &:hover,
        &:focus {
            .image {
                transform: scale(1.1);
            }

            .product-grid-item__shop-now {
                transform: translateY(0);
            }
        }
    }

    &__name {
        min-height: $product-grid-item__name-height;
        margin: $product-grid-item__name-margin;
        font-weight: $product-grid-item__name-font-weight;
        font-size: $product-grid-item__name-font-size;
        text-transform: $product-grid-item__name-text-decoration;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mq($screen-s) {
            min-height: $product-grid-item__name-height\@small;
        }
        @include mq($screen-m) {
            font-size: $product-grid-item__name-font-size\@medium;
        }
        @include mq($screen-l) {
            width: 100%;
        }
    }

    &__name-link {
        font-family: $font-family-secondary;
        font-weight: $product-grid-item__name-font-weight;
    }

    &__rating {
        margin-bottom: $product-grid-item__rating-margin-bottom;
        margin-top: $product-grid-item__rating-margin-top;
        height: $product-grid-item__rating-height;
        text-align: center;
        .bottomLine{
            display: flex;
            justify-content: center;
        }
        .ruk_rating_snippet {
            i {
                font-size: 18px;
            }
        }
        .ruk-rating-snippet-count {
            display: none;
        }
    }

    &__price {
        margin: $product-grid-item__price-margin;
    }

    &__shop-now {
        position: absolute;
        display: block;
        bottom: 0;
        height: 60px;
        width: 100%;
        line-height: 60px;
        font-weight: $font-weight-bold;
        font-size: $font-size-small;
        text-transform: uppercase;
        color: $gray-dark;
        background-color: rgba($white, 0.8);
        transform: translateY(100px);
        transition: $transition-base;
        text-align: center;
    }

    &__sku {
        text-align: center;
        margin: 10px 0 0 0;
        font-size: inherit;
    }

    &__actions {
        margin: $product-grid-item-ee__actions-margin;
    }

    &__details-bottom {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }

}

.related, .product-recommendations {
    .product-grid-item {
        &__actions {
            .stock.unavailable {
                min-height: 50px;
                display: flex;
                align-items: center;
            }
        }
    }
}

@include mq($max-screen: $screen-m - 1){
    .related, .product-recommendations {
        .product-grid-item {
            &__price {
                width: 50%;
                margin: 0;
            }
            &__actions {
                width: 50%;
                margin: 0;
            }
            &__primary-action {
                .button--add-to {
                    min-width: 100%;
                    font-size: inherit;
                }
            }
            &__price {
                .price-label {
                    font-size: $font-size-base;
                }
            }
        }
    }
}

#products-list {
    .product-grid-item__rating {
        .ruk_rating_snippet {
            color: #4e5147!important;
        }
    }
}
