$contact-bar__height                :    48px;

$contact-bar__link-min-height       : $contact-bar__height;

$contact-bar__link-font-size        : 20px;
$contact-bar__link-font-size-tablet : 18px;
$contact-bar__link-font-weight      : normal;
$contact-bar__link-line-height      : 23px;
$contact-bar__link-color            : $link-button-color-gray-dark;
$contact-bar__link-color--hover     : $link-color-hover;

$contact-bar__item-margin           : 0 !default;
$contact-bar__link-text-transform   : none !default;

$contact-bar__store-switcher-width  : 540px;

$contact-bar__background            : transparent;
$contact-bar__link-bg               : transparent;
$contact-bar__link-padding          : 0 15px !default;

$contact-bar__link-hover-background-hover        : transparent;

$contact-bar__container-width                    : inherit !default;
