.content-block {
    &__heading {
        color: $color-primary;
        font-weight: $font-weight-bold;
    }

    img {
        width: auto;
    }
}

.trackorder-index-index{
    .trackorder-wrap{
        input#submit{
            background-color: $button-blue;
            border-color:  $button-blue;
            &:hover{
                color: $button-blue;
                border-color: $button-blue;
            }
        }
    }

    .trackoder-login{
        > p{
            font-size: 16px;
        }
        > a{
            border-color: $button-blue;
            &:hover{
                background-color: $button-blue;
            }
        }
    }
}

.cms-catalog-preview {
    .breadcrumbs, .heading--page {
        display: none;
    }
    #maincontent, .cms-page__content {
        max-width: 100%;
        margin: 0;
    }
    .catalog-title {
        background: $button-green;
        display: flex;
        flex-flow: column;
        justify-content: center;
        h1 {
            color: #FFF;
            font-size: 50px;
            line-height: 50px;
            padding: 25px 0 0 0;
            margin: 0;
            @include mq($max-screen: $screen-m - 1){
                font-size: 26px;
                line-height: 26px;
                padding: 18px 10px 2px;
            }
        }
        h2 {
            color: #FFF;
            padding: 3px 0 8px;
            margin: 0;
            font-family: $font-family-sans-serif;
            font-weight: 400;
            font-size: 25px;
            font-style: normal;
            @include mq($max-screen: $screen-m - 1){
                font-size: 16px;
                padding: 5px 10px;
            }
        }
    }
    .catalog-subtitle {
        max-width: 905px;
        width: 100%;
        padding: 40px 20px;
        margin: 0 auto;
        box-sizing: border-box;
        font-size: 21px;
        line-height: 31px;
        .button-catalog {
            display: none;
        }
        @include mq($max-screen: $screen-m - 1) {
            padding: 12px 35px;
            font-size: 14px;
            line-height: 20px;
            .button-catalog {
                display: block;
                padding: 5px 12px 3px;
                width: max-content;
                max-width: initial;
                font-size: 21px;
            }
        }
    }
    .button-catalog {
        background: $blue-dark;
        padding: 12px 25px 10px;
        font-size: 25px;
        line-height: 25px;
        font-weight: 400;
        color: #FFF;
        margin-top: 10px;
        text-decoration: none;
        font-style: normal;
        font-family: $font-family-sans-serif;
        &:hover {
            background: $button-green;
            text-decoration: none;
        }
        @include mq($max-screen: $screen-m - 1) {
            font-size: 25px;
            margin: 8px auto 0;
        }
    }
    .pagebuilder-column-group {
        max-width: 905px;
        width: 100%;
        padding: 0 20px;
        margin: 0 auto;
        box-sizing: border-box;
        display: flex;
        flex-flow: row wrap;
        .pagebuilder-column {
            padding: 0 !important;
            figure {
                margin: 0 !important;
            }
            &:nth-child(2) {
                width: 50% !important;
            }
            &:nth-child(3) {
                width: 100% !important;
                margin-top: 35px;
            }
        }
        .cms__content {
            padding: 0px 42px;
            text-align: center;
            h2 {
                font-weight: 500;
                font-size: 35px;
                width: 100%;
                display: flex;
                flex-flow: column;
                align-items: center;
                color: $blue-dark;
                letter-spacing: -0.01em;
                line-height: 45px;
                margin-bottom: 10px;
                span {
                    font-style: italic;
                    font-size: 25px;
                    line-height: 25px;
                    padding: 2px 15px 3px;
                    background: $button-green;
                    color: #FFF;
                    margin-bottom: 4px;
                }
            }
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                text-align: left;
                li {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: flex-start;
                    border-bottom: 1px solid $blue-dark;
                    margin-bottom: 15px;
                    .num {
                        font-size: 50px;
                        color: #64A8AB;
                        padding: 0 22px 0 0;
                        font-family: $font-family-serif;
                    }
                    h3 {
                        font-family: $font-family-sans-serif;
                        color: #64A8AB;
                        font-weight: 500;
                        font-size: 22px;
                        margin: 0;
                        line-height: 30px;
                    }
                    p {
                        font-family: $font-family-sans-serif;
                        font-size: 19px;
                        line-height: 26px;
                        margin: 0;
                        padding: 5px 0 15px;
                        strong {
                            font-weight: 600;
                        }
                    }
                    &:last-child {
                        margin: 0;
                        border: none;
                    }
                }
            }
        }
        @include mq($max-screen: $screen-m - 1) {
            padding: 0 14px;
            flex-flow: column;
            .pagebuilder-column {
                width: 100% !important;
                &:nth-child(1) {
                    order: 1;
                }
                &:nth-child(2) {
                    order: 3;
                    width: 100% !important;
                }
                &:nth-child(3) {
                    order: 2;
                    margin: 5px 0 25px;
                }
            }
            .cms__content {
                padding: 0;
                ul {
                    li {
                        .num {
                            padding: 0 20px 0 0;
                        }
                    }
                }
            }
        }
    }
}