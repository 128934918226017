$section__margin-bottom                 : $spacer--extra-large;

$section__margin--fix-spacer-extra-large: 0;

$section__heading-margin                : 0 0 $spacer 0;
$section__heading-color                 : $gray-darkest2;
$section__heading-background-color      : none;
$section__heading-font-family           : $font-family-secondary;
$section__heading-font-weight           : $font-weight-semibold;
$section__heading-font-size             : $font-size-header-section;
$section__heading-text-transform        : inherit;
$section__heading-line-height        : 48px;


$section__heading-background-width: 54px;
$section__heading-background-height: 8px;
$section__heading-background-size: cover;
$section__heading-background-before-url: url('../images/section__heading-bg-left.svg');
$section__heading-background-after-url: url('../images/section__heading-bg-right.svg');

//mobile
$section__heading-font-size\mobile             : 25px;
$section__heading-line-height\mobile        : 30px;