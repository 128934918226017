.link {
    &--ribbon {
        position: relative;
        background-image: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="256" height="48"><path fill="#fff" stroke="#eaeaea" stroke-width="4" d="M253.226 2H2.766l7.342 22.238L2.783 46h250.442l-7.333-21.986L253.226 2z"/></svg>');
        background-repeat: no-repeat;
        width: 256px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-accent-primary;
        font-family: $font-family-secondary;
        font-weight: $font-weight-bold;
        text-transform: uppercase;

        &:hover,
        &.focus-visible {
            text-decoration: none;
            color: $color-accent-primary;
        }
    }
}
