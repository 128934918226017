.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today {
    &.ui-state-disabled {
        background-color: $datepicker__disabled-background;
        span {
            color: $datepicker__disabled-color;
        }
    }
}

.input{
    &__field[type=text],
    &__field[type=email],
    &__field[type=password]{
        /* Remove First */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    &__field {
        height: $input__field-height;
        border-color: $input__field-border-color;
        font-family: $input__field-font-family;
        font-size: $input__field-font-size;
        color: $input__field-font-color;
        font-weight: $input__field-font-weight;



        @include mq($max-screen: $screen-l - 1){
            height: $input__field-height\tablet;
            font-size: $input__field-font-size\tablet;
        }

        &::placeholder {
            font-size: $input__field-font-size;
            color: $input__field-font-placeholder-color;
            font-weight: $input__field-font-weight;
        }
        &.focus-visible{
            border-color: $input__field-focus-visible-border-color;
        }
    }
}