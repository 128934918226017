.badge {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        z-index: $z-index-lowest;
        right: -16px;
        height: 32px;
        border: 16px solid $badge__background;
        border-right-color: transparent;
    }

    &--new {
        &:after {
            border-color: $badge__background--new;
            border-right-color: transparent;
        }
    }
}
