$checkbox__line-height                       : $font-line-height !default;
$checkbox__top                               : 15px !default;
$checkbox__left                              : 2px !default;
$checkbox__label-margin                      : 0 !default;
$checkbox__label-padding                     : $spacer--small 0 $spacer--small $spacer--large !default;
$checkbox__label-color                       : #878A8E !default;
$checkbox__label-color--active               : #878A8E !default;
$checkbox__label-font-size                   : 20px !default;
$checkbox__label-line-height                 : 22px !default;
$checkbox__icon-transition                   : $transition-base !default;
//$checkbox__icon-size                         : 24px !default;
//$checkbox__icon-fill                         : $white !default;
$checkbox__icon-size                         : 19px !default;
$checkbox__icon-fill                         : #292F38 !default;
$checkbox__label-text-decoration-active--link: none !default;
$checkbox__color--link                       : $color-secondary !default;
$checkbox__text-width                        : calc(100% - #{$spacer--large}) !default;
// active
$checkbox__transform                         : perspective(1px) translateZ(0) !default;
$checkbox__transition                        : all 0.5s ease-in-out !default;

// before used in active state
$checkbox__before-display                    : block !default;
$checkbox__before-content                    : '' !default;
$checkbox__before-size                       : 22px !default;
$checkbox__before-position-left              : 0 !default;
$checkbox__before-position-top               : 14px !default;
$checkbox__before-border-radius              : 0 !default;
$checkbox__before-background                 : none!default;
$checkbox__before-transform                  : scaleX(1) !default;
$checkbox__before-transform-origin           : 0 50% !default;
$checkbox__before-transform-hover            : scaleX(1) !default;
$checkbox__before-border            : 1px solid #CBCBCB;
$checkbox__before-border-color-hover: #292F38;
