.cart-discount{
  &__content-button{
    color: $button-blue;
    &.action.check{
      color: $button-blue;
    }
  }
}
.checkout-cart-index{
  .cart-discount{
    &__content-button{
      &.action.check{
        @include mq($max-screen: $screen-m - 1){
          color: #292F38;
        }
      }
    }
  }
}
