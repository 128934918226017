// Secondary
$button__color--secondary            : $color-accent-primary;
$button__border--secondary           : 2px solid $color-accent-primary;

// Button icon
$button__text-color-hover--icon      : $color-primary;
$button__background-hover--icon      : $color-accent-primary;

// Button icon
$button__bg--icon                    : $bg-color-secondary;

// Button icon border
$button__border--icon-border         : $border-width-base $border-style-base $color-accent-primary;


$button__font-size : 16px;

$button__background-hover: $button-green;

//tablet
$button__min-size\tablet                               : 40px !default;