$button-slick-width: 48px;
$button-slick-height: 48px;
$button-slick-background: white;
$button-slick-background-hover: $button-green;

$button-slick-before-width: 56px;
$button-slick-before-height: 56px;
$button-slick-before-background: white;
$button-slick-before-opacity: 0.45;
$button-slick-before-background-hover: $button-green;

$button-slick-arrow-color: $gray-light2;
$button-slick-arrow-color-hover: white;

//tablet
$button-slick-width\tablet: 30px;
$button-slick-height\tablet: 30px;

$button-slick-before-width\tablet: 35px;
$button-slick-before-height\tablet: 35px;