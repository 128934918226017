.cookie-message {

    @include mq($max-screen: $screen-l - 1){
        position: absolute;
    }

    &__button {
        &:hover,
        &.focus-visible {
            .cookie-message__button-icon {
                fill: $cookie-message__button-icon-fill;
            }
        }
    }
}

#notice-cookie-block {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9999;
    padding: 12px 20px 12px 25px;
    display: block;
    font-size: 1.3rem;
    background-color: #F2F1EC;
    box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
    border-color: #d6ca8e;
    color: #000000;
    margin: 0;
    @media(min-width: 768px) {
        padding: 50px 170px 50px 170px;
    }

    p {
        margin-bottom: 20px;

        &.privacy-policy-line {
            margin-top: 10px;
        }
    }

    .actions {
        margin-top: 10px;
    }
}
