@import 'input-variables';

.input {
    margin-bottom: $input__margin-bottom;

    &:focus-within {
        .input__label {
            color: $input__label-focus-color;
        }

        .input__field {
            &::placeholder {
                color: $input__label-focus-color;
            }
        }
    }

    &--inline {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .input__label {
            @extend .label--inline !optional;

            margin: $input__label-margin;
            white-space: $input__white-space--inline;
        }
    }

    &--password {
        position: relative;

        &:focus-within {
            .input__field {
                @include focus-input();
            }
        }
    }

    &__field {
        width: 100%;
        height: $input__field-spacing;
        padding: $input__field-padding;
        border: $input__field-border;
        border-radius: $input__field-border-radius;
        line-height: normal;
        transition: $input__transition;

        &::placeholder {
            color: $input__placeholder-color;
            font-size: $input__placeholder-font-size;
        }

        &.focus-visible {
            @include focus-input();
        }

        &:disabled {
            background-color: $input__background-disabled;
            cursor: not-allowed;
        }

        &--textarea {
            display: block;
            min-width: 100%;
            max-width: 100%;
            min-height: $input__min-height--textarea;
            padding: $input__field-padding--textarea;
            border-radius: $input__field-border-radius--textarea;
            line-height: $input__field-line-height--textarea;
        }
    }

    &__label {
        transition: $input__transition;
    }

    &__button-pass {
        position: absolute;
        top: $input__button-pass-top;
        right: $input__button-pass-right;
        height:  $input__button-pass-height;
        background: $input__button-pass-bg;

        & > .icon {
            fill: $input__button-pass-icon-fill;
        }

        .input__button-pass-icon-hide {
            display: none;
        }

        &--active {
            .input__button-pass-icon-hide {
                display: block;
            }
            .input__button-pass-icon-view {
                display: none;
            }
        }
    }

    &.date {
        position: relative;

        .datetime-picker {
            @extend .input__field;
        }

        .ui-datepicker-trigger {
            position: absolute;
            height: $input__field-spacing;
            left: $input__date-trigger-left;
            bottom: $input__date-trigger-bottom;
            width: $input__date-trigger-width;
            background: $input__date-trigger-background;
            box-shadow: $input__date-trigger-box-shadow;
            border: $input__date-trigger-border;

            span {
                display: none;
            }
        }
    }

    &--datepicker {
        .ui-datepicker-trigger {
            @include visually-hidden();
        }

        input {
            @extend .input__field;
        }
    }
}
