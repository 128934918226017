.logo {
    &__image {
        width: auto;

        @include mq($screen-l) {
            height: $logo__image-height\@large;
        }

        @include mq($screen-xl) {
            height: $logo__image-height\@xl;
        }

        @include mq($max-screen: $screen-m - 1) {
            height: $logo__image-height;
        }
    }
}
