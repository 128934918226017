$banner__content-padding                : $spacer--medium $spacer;
$banner__content-padding\@medium        : $spacer--medium $spacer--semi-medium;
$banner__content-border                 : none;

$banner__bg-color                       : #F0EFE9;

$banner__icon-fill                      : $color-primary;

$banner__text-font-weight               : $font-weight-semibold;
$banner__text-font-family               : $font-family-secondary;

$banner__heading-background-image       : svg-uri('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="8"><path fill="#9cb559" d="M34.795 2.505c-3.082-1.502-7.953-1.201-12.924-.3L5.767 4.906H5.27c3.181 1.502 7.953 1.201 12.923.3l16.104-2.702c.2.1.398 0 .498 0m3.18 4.904c-.596-.3-.596-1 .299-1.3.696-.301 1.292-.601 1.193-1.402-.2-1.301-1.889-1.301-4.871-.8-1.193.1-13.42 2.201-16.104 2.702-6.064 1-11.532 1-14.614-1.502-2.286 0-3.38-.8-3.578-2.302-.398-1.802.894-3.203 1.889-2.703.596.3.596 1.001-.299 1.302-.696.3-1.292.6-1.193 1.401.2 1.201 1.989 1.301 4.871.8l16.105-2.7c6.064-1 11.531-1 14.613 1.502 2.286-.1 3.38.7 3.578 2.202.398 1.902-.894 3.203-1.888 2.802"/></svg>');
$banner__heading-background-image-width : 40px;
$banner__heading-background-image-height: 8px;

$banner-detail-container-padding: 0 110px;


$banner-slider-title-font-family: $font-family-secondary;
$banner-slider-title-font-size: 45px;
$banner-slider-title-font-weight: $font-weight-semibold;
$banner-slider-title-color: white;
$banner-slider-title-line-height: 54px;
$banner-slider-title-text-shadow: 0 1px 4px rgba(0,0,0,0.15);


$banner-slider-description-font-family: $font-family-base;
$banner-slider-description-font-size: 24px;
$banner-slider-description-font-weight: $font-weight-normal;
$banner-slider-description-color: white;
$banner-slider-description-line-height: 30px;
$banner-slider-description-text-shadow: 0 2px 3px  rgba(0,0,0,0.1);
$banner-slider-description-margin: 10px 0 0;

$banner-slider-link-font-family: $font-family-base;
$banner-slider-link-font-size: 20px;
$banner-slider-link-font-weight: $font-weight-bold;
$banner-slider-link-color: white;
$banner-slider-link-line-height: 23px;
$banner-slider-link-text: uppercase;
$banner-slider-link-text-shadow: 0 2px 4px rgba(0,0,0,0.3);
$banner-slider-link-margin: 25px 0 0;
$banner-slider-link-padding: 14px 40px;
$banner-slider-link-border: 2px solid #FFF;
$banner-slider-link-background-hover: $orange2;
$banner-slider-link-border-color: $orange2;


//tablet
$banner-detail-container-padding\tablet: 0 50px;

$banner-slider-title-font-size\tablet: 27px;
$banner-slider-title-line-height\tablet: 32px;

$banner-slider-description-font-size\tablet: 20px;
$banner-slider-description-line-height\tablet: 22px;
$banner-slider-description-margin\tablet: 6px 0 0;

$banner-slider-link-font-size\tablet: 16px;
$banner-slider-link-line-height\tablet: 18px;
$banner-slider-link-padding\tablet: 11px 16px;
$banner-slider-link-margin\tablet: 16px 0 0;

//mobile
$banner-detail-container-padding\mobile: 0;

$banner-slider-link-padding\mobile: 11px 10px;
