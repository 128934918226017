$banner__icon-fill                      : $color-accent-primary;
$banner__heading-background-image       : svg-uri('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="35" height="12"><defs><path id="a" d="M0 2.348h34.97v8.057H0z"/></defs><g fill="none" fill-rule="evenodd"><path fill="#65A9AC" d="M7.16 1.404c4.536-.414 8.045 1.094 12.106 2.84 1.14.49 2.32.998 3.581 1.487a.97.97 0 00.324.055c.115 0 .235-.02.343-.075a.58.58 0 00.301-.353c.132-.404-.01-.698-.459-.89-1.056-.416-2.106-.862-3.121-1.293-4.41-1.87-8.572-3.638-13.23-3.065-.427.052-.694.344-.649.71.052.42.351.637.804.584m20.456 9.284c-5.109-.09-9.218-2.121-13.19-4.087l-.89-.44-.032-.012c-.2-.08-.668-.265-.943.22-.118.225-.22.654.413.983l.112.058c4.464 2.304 8.68 4.48 14.508 4.582h.014a.675.675 0 00.477-.19.742.742 0 00.225-.502c.006-.324-.312-.605-.694-.612"/><mask id="b" fill="#fff"><use xlink:href="#a"/></mask><path fill="#65A9AC" d="M34.964 2.911c-.05-.262-.293-.626-.75-.554-.427.066-.558.437-.545.692-.264 2.04-1.737 4.355-5.086 4.766-3.57.437-6.62-.962-9.848-2.445-3.439-1.58-6.997-3.214-11.412-2.671-4 .49-7.011 3.39-7.321 7.05a.623.623 0 00.705.651c.201-.024.545-.148.591-.702.229-2.755 3.003-5.314 6.184-5.705 3.927-.481 7.08.98 10.418 2.526 3.29 1.525 6.694 3.1 10.842 2.59 4.086-.5 6.071-3.442 6.226-6.134l.002-.032-.006-.032z" mask="url(#b)"/></g></svg>');
$banner__heading-background-image-width : 35px;
$banner__heading-background-image-height: 12px;

$banner__bg-color                       : #F0F6F8;

$banner-slider-description-font-size: 20px;

$banner-slider-link-font-size: 16px;

$banner-slider-link-background-hover: $button-green;
$banner-slider-link-border-color: $button-green;

$banner-slider-link-line-height: 1;

$banner-slider-link-padding: 17px 40px 13px;

//tablet
$banner-slider-description-font-size\tablet: 16px;

$banner-slider-link-font-size\tablet: 13px;

$banner-slider-link-padding\tablet: 11px 16px 7px;

//mobile
$banner-slider-link-padding\mobile: 11px 10px 7px;
