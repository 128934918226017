.footer {

  @include mq($max-screen: $screen-m - 1){
    background-size: cover;
    background-position: center center;
  }

    &__links{
      &:before{
        content: "";
        display: block;
        height: 1px;
        width: 100vw;
        position: absolute;
        top: 1px;
        left: 50%;
        transform: translateX(-50%);
        background-color: white;
      }
    }

  &__bottom-bar{
    @include mq($screen-l){
      border-top: 0;
    }
  }
}
