.home {
    &__banner {
        margin: 0 0 $spacer;
    }
}

.cms-home{
    .page-wrapper{
        margin-bottom: 0;
        > section{
            @include mq($max-screen: $screen-m - 1) {
                margin: 10px 0 0;
            }
            .banner{
                max-height: 550px;
                .image{
                    a{
                        width: 100%;
                    }
                }
            }
        }
    }
    .footer{
        margin-top: 0;
    }
}
img {
    height: initial;
}
